import React, { Fragment } from 'react';
import StickyCovid from '../components/common/StickyCovid';
import Footer from '../components/footer_n';
import Header from '../components/header_n';
import Layout from './BaseLayout';

class HomeLayout extends React.Component {
  render() {
    // const { t, router } = this.props;
    return (
      <Fragment>
        <StickyCovid />
        <Layout {...this.props}>
          <Header
            {...this.props}
            dark
            search={!this.props.search}
            id="header"
          />

          {this.props.children}
          <Footer {...this.props} relevantEmailContact="redaksi@hukumonline.com" />
        </Layout>
      </Fragment>
    );
  }
}

export default HomeLayout;
