import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import parse, { domToReact } from 'html-react-parser';

export const TextSanitizer = (props) => {
  const {
    value, className, style, title = 'Hukumonline.com', sanitize,
  } = props;
  const sanitizer = data => DOMPurify.sanitize(data, { USE_PROFILES: { html: true } });
  const options = {
    replace: ({ attribs, children, name }) => {
      if (!attribs) {
        return;
      }
      if (name == 'img' && attribs.src !== '') {
        return <img src={attribs.src} alt={attribs.alt || title} title={attribs.title || title} />;
      }
    },
  };
  const html = parse(sanitizer(value), options);
  if (sanitize) {
    return html;
  }
  return (
    <div className={className} style={{ ...style }}>
      {html}
    </div>
  );
};

TextSanitizer.propTypes = {
  value: PropTypes.string,
  style: PropTypes.string,
};

TextSanitizer.defaultProps = {
  value: '',
  style: '',
};

export default TextSanitizer;
