import React from 'react';
import { Badge } from 'reactstrap';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, PRO_URL } = publicRuntimeConfig;

const DesktopSolusi = ({ t }) => (
  <>
    <div className="navbar-list-wrapper">
      <div className="navbar-list">
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - unisol', 'beranda', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'unisol',
            });
          }}
          href={`${PRO_URL}/solusi-universitas-hukum/?utm_source=website&utm_medium=navbar`}
        >
          <div className="d-flex flex-column">
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/main/unisol.svg`}
                alt="Icon University Solution"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                University Solutions
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-unisol')}
              </small>
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - rcs_home', 'beranda', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'rcs_home',
            });
          }}
          href="https://rcs.hukumonline.com/?utm_source=website&utm_medium=header"
        >
          <div className="d-flex flex-column">
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/main/cms.svg`}
                alt="Icon RCS"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                Regulatory Compliance System
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-regulatory-compliance-system')}
              </small>
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - exdoma_home', 'beranda', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'exdoma_home',
            });
          }}
          href="https://exdoma.hukumonline.com/?utm_source=website&utm_medium=header"
        >
          <div className="d-flex flex-column">
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/main/dms.svg`}
                alt="Icon dms"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                Document Management System
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-exdoma')}
              </small>
            </div>
          </div>
        </a>

        <div className="flex-grow-1 d-flex align-items-end mt-5 ">
          <img
            src={`${BASE_URL}/static/images/main/ask.svg`}
            alt="Icon Ask"
            className="mr-2"
          />
          <span className="small">
            Ada Pertanyaan?{' '}
            <a
              href="https://www.hukumonline.com/produk/hubungi-kami/id/"
              className="font-weight-semibold text-primary"
            >
              {' '}
              Hubungi Kami{' '}
            </a>
          </span>
        </div>
      </div>
      <div className="navbar-list">
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - easybiz_home',
              'beranda',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'easybiz_home',
            });
          }}
          href="https://www.easybiz.id/#layanan-dan-harga?utm_source=hukumonline&utm_medium=navbar"
        >
          <div className="d-flex flex-column">
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/main/izinusaha.svg`}
                alt="Icon izin usaha"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                Izin Usaha
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-izin-usaha')}
              </small>
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - justika_home',
              'beranda',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'justika_home',
            });
          }}
          href="https://www.justika.com/partner/klinik/?utm_source=hukumonline&utm_medium=navbar&utm_campaign=chat"
        >
          <div className="d-flex flex-column">
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/main/konsultasi.svg`}
                alt="Icon Justika"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                Konsultasi Hukum
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-konsultasi-hukum')}
              </small>
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - pembuatan_dokumen_home',
              'beranda',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'pembuatan_dokumen_home',
            });
          }}
          href="https://business.justika.com/template.html/?utm_source=hukumonline&utm_medium=navbar&utm_campaign=template"
        >
          <div className="d-flex flex-column">
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/menu/pembuatandokumen.svg`}
                alt="Icon Dokumen"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                Pembuatan Dokumen
              </span>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-pembuatan-dokumen')}
              </small>
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - hukumonline_360',
              'beranda',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'hukumonline_360',
            });
          }}
          href={`${BASE_URL}/hukumonline-360/`}
        >
          <div className="d-flex flex-column">
            <div className=" mr-1 d-flex align-items-center">
              <img
                src={`${BASE_URL}/static/images/main/hol_360.svg`}
                alt="Icon Dokumen"
                className="mr-2"
              />
              <span className="body text-primary font-weight-semibold">
                Hukumonline 360
              </span>
              <Badge className="ml-2 px-2 py-1 new-badge">New</Badge>
            </div>
            <div className="d-flex flex-column desc-menu">
              <small className="small font-weight-medium text-main">
                {t('desc-menu-hol-360')}
              </small>
            </div>
          </div>
        </a>
      </div>
      <div className="">
        <div className="">
          <span className="small font-weight-semibold text-main">
            PRODUK PILIHAN
          </span>
          <div className="d-flex flex-column  my-2 py-2">
            <a
              onClick={() => {
                structuredEvent(
                  'navbar',
                  'click url - solusi_produk_pilihan_1',
                  'beranda',
                  '',
                );
                pushGa4Generic({
                  event: 'Navbar',
                  action: 'click url',
                  component: 'solusi_produk_pilihan_1',
                });
              }}
              href={`${BASE_URL}/talks/?utm_source=website&utm_medium=header&utm_campaign=produk_pilihan`}
            >
              <div className="d-flex flex-row">
                <div>
                  <img
                    src={`${BASE_URL}/static/images/menu/event.png`}
                    alt="Icon Event"
                  />
                </div>
                <div className="bg-hol-soft pl-2 d-flex flex-column justify-content-center">
                  <span className="extra-small font-weight-semibold text-main">
                    Event
                  </span>
                  <p className="micro text-main m-0">{t('desc-menu-event')}</p>
                </div>
              </div>
            </a>
          </div>
          <div className="d-flex flex-column  my-2 py-2">
            <a
              onClick={() => {
                structuredEvent(
                  'navbar',
                  'click url - solusi_produk_pilihan_2',
                  'beranda',
                  '',
                );
                pushGa4Generic({
                  event: 'Navbar',
                  action: 'click url',
                  component: 'solusi_produk_pilihan_2',
                });
              }}
              href={`${BASE_URL}/stories/?utm_source=website&utm_medium=header&utm_campaign=produk_pilihan `}
            >
              <div className="d-flex flex-row">
                <div>
                  <img
                    src={`${BASE_URL}/static/images/menu/premiumstories.png`}
                    alt="Icon Premium Stories"
                  />
                </div>
                <div className="bg-hol-soft pl-2 d-flex flex-column justify-content-center">
                  <span className="extra-small font-weight-semibold text-main">
                    Premium Stories
                  </span>
                  <p className="micro text-main m-0">
                    {t('desc-menu-premium-stories')}
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="d-flex flex-column  my-2 py-2">
            <a
              onClick={() => {
                structuredEvent(
                  'navbar',
                  'click url - solusi_produk_pilihan_3',
                  'beranda',
                  '',
                );
                pushGa4Generic({
                  event: 'Navbar',
                  action: 'click url',
                  component: 'solusi_produk_pilihan_3',
                });
              }}
              href={`${BASE_URL}/pusatdata/?utm_source=website&utm_medium=header&utm_campaign=produk_pilihan`}
            >
              <div className="d-flex flex-row">
                <div>
                  <img
                    src={`${BASE_URL}/static/images/menu/pusatdata.png`}
                    alt="Icon Pusat Data"
                  />
                </div>
                <div className="bg-hol-soft pl-2 d-flex flex-column justify-content-center">
                  <span className="extra-small font-weight-semibold text-main">
                    Pusat Data
                  </span>
                  <p className="micro text-main m-0">
                    {t('desc-menu-pusat-data')}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
);
// DesktopPro.propTypes = {
//   setMenu: PropTypes.func.isRequired,
//   toggleMenu: PropTypes.func.isRequired,
//   data: PropTypes.shape.isRequired,
// };
export default DesktopSolusi;
