import React, { Component } from 'react';
import routerEvents from 'next-router-events';
import { Progress } from 'reactstrap';
import Meta from '../components/Meta';
import { getCookie, setCookie } from '../libraries/session';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { getUser } from '../libraries/auth';

import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const {
  FINGERPRINT_API_KEY
} = publicRuntimeConfig;

class BaseLayout extends Component {
  state = { pageLoading: true }

  componentDidMount() {
    const runFingerprint = async () => {
      const fpPromise = FingerprintJS.load({ apiKey: FINGERPRINT_API_KEY })

      // Get the _holdi when you need it.
      fpPromise
        .then(fp => fp.get({
          linkedId: getUser().legacy_id,
          tag: {
            'created_at': getUser().created_at,
            'groups': getUser().groups,
            'products': getUser().products,
            'status': getUser().status,
            'subscription_expired_at': getUser().subscription_expired_at,
            'subscription_status': getUser().subscription_status,
            'tags': getUser().tags,
            'updated_at': getUser().updated_at,
          }
        }))
        .then(result => {
          setCookie('_holdi', result.visitorId);
          const isBrowser = typeof window !== 'undefined';
          isBrowser && window.dataLayer && window.dataLayer.push({
            'visitorId': result.visitorId,
          });
        });
    }

    if(!getCookie('_holdi') && getUser()){
        runFingerprint()
    }
    if(getCookie('_holdi')){
      const isBrowser = typeof window !== 'undefined';
      isBrowser && window.dataLayer && window.dataLayer.push({
        'visitorId': getCookie('_holdi'),
      });
    }

    routerEvents.on('routeChangeStart', this.pageLoadStart);
    routerEvents.on('routeChangeComplete', this.pageLoadEnd);
    this.setState(() => ({
      pageLoading: false,
    }));
  }

  componentWillUnmount() {
    routerEvents.off('routeChangeStart', this.pageLoadStart);
    routerEvents.off('routeChangeComplete', this.pageLoadEnd);
  }

  pageLoadStart = (url) => {
    const currentUrl = window.location.pathname + window.location.search;
    if (url !== currentUrl) {
      this.setState(() => ({ pageLoading: true }));
    }
  }

  pageLoadEnd = (url) => {
    this.setState(() => ({ pageLoading: false }));
  }

  render() {
    return (
      <div>
        <Meta {...this.props} />
        {this.state.pageLoading && <Progress className="page-loading-bar" animated value="100" /> }
        {this.props.children}
      </div>
    );
  }
}

export default BaseLayout;
