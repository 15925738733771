import React from 'react';
import { Badge } from 'reactstrap';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, JURNAL_URL, OC_URL, PRO_URL } = publicRuntimeConfig;

const WawasanDesktop = ({ t }) => {
  const utm = '?utm_source=website&utm_medium=header';
  return (
    <>
      <div className="navbar-list-wrapper">
        <div className="navbar-list">
          <div>
            <div className="d-flex flex-column">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - klinik_home',
                    'beranda',
                    '',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'click url',
                    component: 'klinik_home',
                  });
                }}
                href={`${BASE_URL}/klinik/`}
              >
                <div className=" mr-1 d-flex align-items-center">
                  <img
                    src={`${BASE_URL}/static/images/menu/klinik.svg`}
                    alt="Icon klinik"
                    className="mr-2"
                  />
                  <span className="body text-primary font-weight-semibold">
                    Klinik
                  </span>
                </div>

                <div className="d-flex flex-column desc-menu">
                  <small className="small font-weight-medium text-main">
                    {t('desc-menu-klinik')}
                  </small>
                </div>
              </a>
            </div>
            <div className="d-flex flex-column">
              <div className="sub-navbar">
                <div className="d-flex flex-column">
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_tips_hukum',
                        'tips hukum',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_tips_hukum',
                      });
                    }}
                    href={`${BASE_URL}/klinik/tips/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Tips Hukum
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_pidana',
                        'pidana',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_pidana',
                      });
                    }}
                    href={`${BASE_URL}/klinik/pidana/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Pidana
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_keluarga',
                        'keluarga',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_keluarga',
                      });
                    }}
                    href={`${BASE_URL}/klinik/keluarga/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Keluarga
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_perdata',
                        'perdata',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_perdata',
                      });
                    }}
                    href={`${BASE_URL}/klinik/perdata/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Perdata
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_kenegaraan',
                        'kenegaraan',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_kenegaraan',
                      });
                    }}
                    href={`${BASE_URL}/klinik/kenegaraan/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Kenegaraan
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_profesi_hukum',
                        'Profesi Hukum',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_profesi_hukum',
                      });
                    }}
                    href={`${BASE_URL}/klinik/profesi-hukum/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Profesi Hukum
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_ilmu_hukum',
                        'ilmu hukum',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_ilmu_hukum',
                      });
                    }}
                    href={`${BASE_URL}/klinik/ilmu-hukum/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Ilmu Hukum
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_bisnis',
                        'bisnis',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_bisnis',
                      });
                    }}
                    href={`${BASE_URL}/klinik/bisnis/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Bisnis
                    </span>
                  </a>
                </div>
                <div className="d-flex flex-column flex-shrink-1">
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_pertanahan_properti',
                        'pertanahan & properti',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_pertanahan_properti',
                      });
                    }}
                    href={`${BASE_URL}/klinik/pertanahan-properti/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Pertanahan & Properti
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_ketenagakerjaan',
                        'ketenagakerjaan',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_ketenagakerjaan',
                      });
                    }}
                    href={`${BASE_URL}/klinik/ketenagakerjaan/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Ketenagakerjaan
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_perlindungan_konsumen',
                        'perlindungan konsumen',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_perlindungan_konsumen',
                      });
                    }}
                    href={`${BASE_URL}/klinik/perlindungan-konsumen/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Perlindungan Konsumen
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_hak_asasi_manusia',
                        'hak asasi manusia',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_hak_asasi_manusia',
                      });
                    }}
                    href={`${BASE_URL}/klinik/hak-asasi-manusia/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Hak Asasi Manusia
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_kekayaan_intelektual',
                        'kekayaan intelektual',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_kekayaan_intelektual',
                      });
                    }}
                    href={`${BASE_URL}/klinik/kekayaan-intelektual/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Kekayaan Intelektual
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_teknologi',
                        'teknologi',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_teknologi',
                      });
                    }}
                    href={`${BASE_URL}/klinik/teknologi/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Teknologi
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_startup_umkm',
                        'startup & ukm',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_startup_umkm',
                      });
                    }}
                    href={`${BASE_URL}/klinik/start-up-umkm/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Startup & UMKM
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - klinik_olahraga',
                        'olahraga',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'klinik_olahraga',
                      });
                    }}
                    href={`${BASE_URL}/klinik/olahraga/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Olahraga
                    </span>
                  </a>
                </div>
              </div>
              {/* <div className="d-flex flex-column">
            </div> */}
            </div>
          </div>
          <a
            onClick={() => {
              structuredEvent(
                'navbar',
                'click url - hukumonline_stream',
                'beranda',
                '',
              );
              pushGa4Generic({
                event: 'Navbar',
                action: 'click url',
                component: 'hukumonline_stream',
              });
            }}
            href={`${BASE_URL}/stream/`}
          >
            <div className="d-flex flex-column">
              <div className=" mr-1 d-flex align-items-center">
                <img
                  src={`${BASE_URL}/static/images/main/hol_stream.svg`}
                  alt="Icon Dokumen"
                  className="mr-2"
                />
                <span className="body text-primary font-weight-semibold">
                  Hukumonline Stream
                </span>
                <Badge className="ml-2 px-2 py-1 new-badge">New</Badge>
              </div>
              <div className="d-flex flex-column desc-menu">
                <small className="small font-weight-medium text-main">
                  {t('desc-menu-hol-stream')}
                </small>
              </div>
            </div>
          </a>
          <div className="">
            <div className="d-flex flex-column">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - jurnal_home',
                    'beranda',
                    '',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'click url',
                    component: 'jurnal_home',
                  });
                }}
                href={`${JURNAL_URL}/${utm}`}
              >
                <div className=" mr-1 d-flex align-items-center">
                  <img
                    src={`${BASE_URL}/static/images/menu/jurnal.svg`}
                    alt="Icon Jurnal"
                    className="mr-2"
                  />
                  <span className="body text-primary font-weight-semibold">
                    Jurnal
                  </span>
                </div>

                <div className="d-flex flex-column desc-menu">
                  <small className="small font-weight-medium text-main">
                    {t('desc-menu-jurnal')}
                  </small>
                </div>
              </a>
            </div>
            <div className="d-flex flex-column">
              <div className="sub-navbar">
                <div className="d-flex flex-column">
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - jurnal_jurnal',
                        'jurnal',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'jurnal_jurnal',
                      });
                    }}
                    href={`${JURNAL_URL}/journal/${utm}`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Daftar Jurnal
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - jurnal_artikel',
                        'artikel',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'jurnal_artikel',
                      });
                    }}
                    href={`${JURNAL_URL}/article/${utm}`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Artikel
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-grow-1 d-flex align-items-end mt-5 ">
            <img
              src={`${BASE_URL}/static/images/main/ask.svg`}
              alt="Icon Ask"
              className="mr-2"
            />
            <span className="small">
              Ada Pertanyaan?{' '}
              <a
                href="https://www.hukumonline.com/produk/hubungi-kami/id/"
                className="font-weight-semibold text-primary"
              >
                {' '}
                Hubungi Kami{' '}
              </a>
            </span>
          </div>
        </div>
        <div className="navbar-list">
          <div>
            <div className="d-flex flex-column">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - berita_home',
                    'beranda',
                    '',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'click url',
                    component: 'berita_home',
                  });
                }}
                href={`${BASE_URL}/berita/`}
                className="py-1"
              >
                <div className=" mr-1 d-flex align-items-center">
                  <img
                    src={`${BASE_URL}/static/images/menu/berita.svg`}
                    alt="Icon berita"
                    className="mr-2"
                  />
                  <span className="body text-primary font-weight-semibold">
                    Berita
                  </span>
                </div>

                <div className="d-flex flex-column desc-menu">
                  <small className="small font-weight-medium text-main">
                    {t('desc-menu-berita')}
                  </small>
                </div>
              </a>
            </div>
            <div className="d-flex flex-column">
              <div className="sub-navbar">
                <div className="d-flex flex-column">
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_utama',
                        'utama',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_utama',
                      });
                    }}
                    href={`${BASE_URL}/berita/utama/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Utama
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_terbaru',
                        'terbaru',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_terbaru',
                      });
                    }}
                    href={`${BASE_URL}/berita/terbaru/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Terbaru
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_kolom',
                        'kolom',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_kolom',
                      });
                    }}
                    href={`${BASE_URL}/berita/kolom/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Kolom
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_foto',
                        'foto',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_foto',
                      });
                    }}
                    href={`${BASE_URL}/berita/foto/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Foto
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_jeda',
                        'jeda',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_jeda',
                      });
                    }}
                    href={`${BASE_URL}/berita/jeda/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Jeda
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_tajuk',
                        'tajuk',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_tajuk',
                      });
                    }}
                    href={`${BASE_URL}/berita/tajuk/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Tajuk
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_profil',
                        'profil',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_profil',
                      });
                    }}
                    href={`${BASE_URL}/berita/profil/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Profil
                    </span>
                  </a>
                </div>
                <div className="d-flex flex-column flex-shrink-1">
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_isu_hangat',
                        'isu hangat',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_isu_hangat',
                      });
                    }}
                    href={`${BASE_URL}/berita/isu-hangat/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Isu Hangat
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_pojok_1',
                        'pojok 1',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_pojok_1',
                      });
                    }}
                    href={`${BASE_URL}/berita/atp-legal-center/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      ATP Legal Center
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_pojok_2',
                        'pojok 2',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_pojok_2',
                      });
                    }}
                    href={`${BASE_URL}/berita/ini/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Pojok INI
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_pojok_3',
                        'pojok 3',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_pojok_3',
                      });
                    }}
                    href={`${BASE_URL}/berita/akpi/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Pojok AKPI
                    </span>
                  </a>
                  {/* <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_pojok_3',
                        'pojok 3',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_pojok_3',
                      });
                    }}
                    href={`${BASE_URL}/berita/pojok-kuhp/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Pojok KUHP
                    </span>
                  </a>
                   */}

                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_kabar_kampus',
                        'kabar kampus',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_kabar_kampus',
                      });
                    }}
                    href={`${BASE_URL}/berita/kabar-kampus/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Kabar Kampus{' '}
                    </span>
                  </a>
                  <a
                    onClick={() => {
                      structuredEvent(
                        'navbar',
                        'click url - berita_surat_pembaca',
                        'surat pembaca',
                        '',
                        '',
                      );
                      pushGa4Generic({
                        event: 'Navbar',
                        action: 'click url',
                        component: 'berita_surat_pembaca',
                      });
                    }}
                    href={`${BASE_URL}/berita/surat-pembaca/`}
                  >
                    <span className="small font-weight-semibold text-main">
                      Surat Pembaca
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column">
            <a
              onClick={() => {
                structuredEvent(
                  'navbar',
                  'click url - datapribadi',
                  'beranda',
                  '',
                  '',
                );
                pushGa4Generic({
                  event: 'Navbar',
                  action: 'click url',
                  component: 'datapribadi',
                });
              }}
              href="https://www.datapribadi.id/?utm_source=hukumonline&utm_medium=navbar"
            >
              <div className=" mr-1 d-flex align-items-center">
                <img
                  src={`${BASE_URL}/static/images/menu/lockkey.svg`}
                  alt="Icon online publication"
                  className="mr-2"
                />
                <span className="body text-primary font-weight-semibold">
                  Datapribadi.id
                </span>
              </div>
              <div className="d-flex flex-column desc-menu">
                <small className="small font-weight-medium text-main">
                  {t('desc-menu-data-pribadi')}
                </small>
              </div>
            </a>
          </div>
        </div>
        <div className="">
          <div className="">
            <span className="small font-weight-semibold text-main">
              PRODUK PILIHAN
            </span>
            <div className="d-flex flex-column  my-2 py-2">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - info_hukum_produk_pilihan_1',
                    'beranda',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'click url',
                    component: 'info_hukum_produk_pilihan_1',
                  });
                }}
                href={`${OC_URL}`}
              >
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src={`${BASE_URL}/static/images/menu/oc.png`}
                      alt="Icon Premium Stories"
                    />
                  </div>
                  <div className="bg-hol-soft pl-2 d-flex flex-column justify-content-center">
                    <span className="extra-small font-weight-semibold text-main">
                      Online Course
                    </span>
                    <p className="micro text-main m-0">
                      {t('desc-menu-online-course')}
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="d-flex flex-column  my-2 py-2">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - info_hukum_produk_pilihan_2',
                    'beranda',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'click url',
                    component: 'info_hukum_produk_pilihan_2',
                  });
                }}
                href={`${PRO_URL}`}
              >
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src={`${BASE_URL}/static/images/menu/analisis-hukum.png`}
                      alt="Icon Analisis Hukum"
                    />
                  </div>
                  <div className="bg-hol-soft pl-2 d-flex flex-column justify-content-center">
                    <span className="extra-small font-weight-semibold text-main">
                      Analisis Hukum
                    </span>
                    <p className="micro text-main m-0">
                      {t('desc-menu-analisa-hukum')}
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="d-flex flex-column  my-2 py-2">
              <a
                onClick={() => {
                  structuredEvent(
                    'navbar',
                    'click url - info_hukum_produk_pilihan_3',
                    'beranda',
                    '',
                  );
                  pushGa4Generic({
                    event: 'Navbar',
                    action: 'click url',
                    component: 'info_hukum_produk_pilihan_3',
                  });
                }}
                href="https://www.justika.com/partner/klinik/?utm_source=hukumonline&utm_medium=navbar&utm_campaign=chat"
              >
                <div className="d-flex flex-row">
                  <div>
                    <img
                      src={`${BASE_URL}/static/images/menu/konsultasi-hukum.png`}
                      alt="Icon Analisis Hukum"
                    />
                  </div>
                  <div className="bg-hol-soft pl-2 d-flex flex-column justify-content-center">
                    <span className="extra-small font-weight-semibold text-main">
                      Konsultasi Hukum
                    </span>
                    <p className="micro text-main m-0">
                      {t('desc-menu-konsultasi-hukum')}
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
// WawasanDesktop.propTypes = {
//   setMenu: PropTypes.func.isRequired,
//   toggleMenu: PropTypes.func.isRequired,
//   data: PropTypes.shape.isRequired,
// };
export default WawasanDesktop;
