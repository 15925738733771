import React from "react";
import Container from "reactstrap/lib/Container";
import { Nav, Navbar, NavItem, NavLink, Row } from "reactstrap";
import PropTypes from "prop-types";
import SelectedSubMenu from "./sub";

const SubBarMenu = React.memo((props) => {
  const { subMenu, router } = props;
  return (
    subMenu && (
      <>
        <div className="d-print-none">
          <Container>
            <Row>
              <Navbar expand="xs" className="subbar py-0">
                <Nav navbar>
                  <SelectedSubMenu {...props} />
                </Nav>
              </Navbar>
              {subMenu === "jurnal" && (
                <Navbar expand="xs" className="subbar py-0 ml-auto">
                  <Nav navbar>
                    <NavItem>
                      <NavLink
                        href="/how-to-use"
                        active={router.asPath.includes("/how-to-use")}
                        className="px-0 text-center"
                        title="Artikel Akademik Hukum Indonesia"
                      >
                        Cara Penggunaan
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Navbar>
              )}
            </Row>
          </Container>
        </div>
      </>
    )
  );
});
SubBarMenu.propTypes = {
  subMenu: PropTypes.string,
};

SubBarMenu.defaultProps = {
  subMenu: "",
};

export default SubBarMenu;
