import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward } from 'react-icons/io5';
import { TbSpeakerphone } from 'react-icons/tb';
import { BiChat } from 'react-icons/bi';
import { MdLibraryBooks } from 'react-icons/md';
import PropTypes from 'prop-types';
import SecondaryHeader from './secondaryHeader';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, OC_URL } = publicRuntimeConfig;

const WawasanMenu = ({ setMenu, data, toggleMenu, t }) => {
  const secondaryMenuTitle = (
    <div className="d-flex align-items-center">
      <span className="header-4 font-weight-bold text-primary">
        Wawasan Hukum
      </span>
    </div>
  );
  return (
    <>
      <SecondaryHeader
        setMenu={setMenu}
        menu={data}
        lvl="lvl2"
        menuActiveLvl1="mainMenu"
        menuActiveLvl2="wawasanMenu"
        menuActiveLvl3=""
        toggleMenu={toggleMenu}
        header={secondaryMenuTitle}
      />
      <Container className="d-flex flex-column py-3">
        <button
          type="button"
          onClick={() =>
            toggleMenu(
              data,
              {
                name: 'lvl3',
                isOpen: !data.isOpen.lvl3,
                menuActiveLvl3: 'klinikMenu',
              },
              setMenu,
            )
          }
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className="d-flex">
              <div className=" flex-shrink-1 mr-2">
                <img
                  src={`${BASE_URL}/static/images/menu/klinik.svg`}
                  alt="Icon Klinik"
                />
              </div>
              <div className="d-flex flex-column">
                <span className="paragraph font-weight-semibold">Klinik</span>
                <small className="small font-weight-medium text-main">
                  {t('desc-menu-klinik')}
                </small>
              </div>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </button>
        <button
          type="button"
          onClick={() =>
            toggleMenu(
              data,
              {
                name: 'lvl3',
                isOpen: !data.isOpen.lvl3,
                menuActiveLvl3: 'beritaMenu',
              },
              setMenu,
            )
          }
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img
                src={`${BASE_URL}/static/images/menu/berita.svg`}
                alt="Icon Berita"
              />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">Berita</span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-berita')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </button>
        <button
          type="button"
          onClick={() =>
            toggleMenu(
              data,
              {
                name: 'lvl3',
                isOpen: !data.isOpen.lvl3,
                menuActiveLvl3: 'jurnalMenu',
              },
              setMenu,
            )
          }
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img
                src={`${BASE_URL}/static/images/menu/jurnal.svg`}
                alt="Icon jurnal"
              />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">Jurnal</span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-jurnal')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </button>
        <a
          href={`${OC_URL}/?utm_source=website&utm_medium=header`}
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img
                src={`${BASE_URL}/static/images/menu/onlinecourse.svg`}
                alt="Icon Online Course"
              />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">
                Online Course
              </span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-OC')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <button
          type="button"
          onClick={() =>
            toggleMenu(
              data,
              {
                name: 'lvl3',
                isOpen: !data.isOpen.lvl3,
                menuActiveLvl3: 'eventMenu',
              },
              setMenu,
            )
          }
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img
                src={`${BASE_URL}/static/images/menu/event.svg`}
                alt="Icon Event"
              />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">Event</span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-event')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </button>
        <a
          href="https://www.datapribadi.id/?utm_source=hukumonline&utm_medium=navbar"
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img
                src={`${BASE_URL}/static/images/menu/lockkey.svg`}
                alt="Icon Data Pribadi"
              />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">
                Data Pribadi
              </span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-data-pribadi')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - pkpa - home',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'pkpa - home',
            });
          }}
          href="https://pkpa.hukumonline.com/?utm_source=website&utm_medium=header"
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img
                src={`${BASE_URL}/static/images/menu/pkpa.svg`}
                alt="Icon PKPA"
              />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">PKPA</span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-PKPA')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        {/* <a
          href="#!"
          onClick={() => toggleMenu(
            data,
            { name: 'lvl3', isOpen: !data.isOpen.lvl3, menuActiveLvl3: 'rankingMenu' },
            setMenu,
          )
          }
          className="mb-4"
        > */}
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - ranking - home',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'ranking - home',
            });
          }}
          href="https://awards.hukumonline.com/?utm_source=website&utm_medium=header"
          className="py-3"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img
                src={`${BASE_URL}/static/images/menu/ranking.svg`}
                alt="Icon Ranking"
              />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">Rankings</span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-ranking')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - online publication - home',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'pkpa - home',
            });
          }}
          href={`${BASE_URL}/online-publication/`}
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <TbSpeakerphone fontSize="18px" className="text-primary" />
            </div>
            <div className="d-flex flex-column pr-4">
              <span className="paragraph font-weight-semibold">
                Publikasi Online
              </span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-publikasi-online')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
      </Container>
    </>
  );
};
WawasanMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
};
export default WawasanMenu;
