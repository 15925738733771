'use strict'

export const parseIndexName = (index) => {
  const index_parts = index.split('.');
  return [index_parts[0], index_parts[1], index_parts.slice(2).join('.')];
};

export const encodeBase64 = (text) => {
  if (!text) return null;
  let data = text;
  let buff = new Buffer(data);
  return buff.toString('base64');
};

export const decodeBase64 = (hash) => {
  let data = hash;
  let buff = new Buffer(data, 'base64');
  return buff.toString('ascii');
};
export const imageSelector = (link, size) => {
  
  const imageFile = link.substring(link.lastIndexOf('/') + 1);
  let linkImage = '';
  switch (size) {
    case 'large':
      linkImage = link.replace(/\/[^\/]*$/, `/large_${imageFile}`);
      break;
    case 'medium':
      linkImage = link.replace(/\/[^\/]*$/, `/medium_${imageFile}`);
      break;
    case 'small':
      linkImage = link.replace(/\/[^\/]*$/, `/small_${imageFile}`);
      break;
    case 'thumbnail':
      linkImage = link.replace(/\/[^\/]*$/, `/thumbnail_${imageFile}`);
      break;
    default:
      linkImage = link;
      break;
  }
 
  return linkImage;
};

export const stringfyText = (value) => {
  const stringify = JSON.stringify(value);
  return stringify.substring(1, stringify.length - 1);
};

export const encodeQueryString = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const EXTRACT_MARKDOWN = /<\/?[a-z][^>]*>/g;
export const MATCH_UNTIL_PERIOD = /^([^.]+.)/g;
export const PHONE_REGEX = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/g;
export const COUNT_REGEX = /^[\0-9&.-]+$/g;
