import React, { Component, Fragment } from 'react';
import Header from '../components/header_n';
import Footer from '../components/footer_n';
import Layout from './BaseLayout';
// import StickyRCS from '../components/common/StickyRCS';
import StickyIHC from '../components/common/StickyIHC';

const NewsLayout = (props) => (
  <>
    <Layout {...props}>
      {/* <StickyRCS {...props} /> */}
      <StickyIHC {...props} />
      <Header {...props} search dark />
      {props.children}
      <Footer {...props} />
    </Layout>
  </>
);

export default NewsLayout;
