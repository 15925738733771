import React from 'react';
import menuList from '../menu/menuList';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const MainItemMenu = ({
  icon,
  title,
  link,
  trackerSnowPlow = {},
  ga4Tracker = {},
}) => (
  <a
    href={link}
    onClick={() => {
      structuredEvent(
        trackerSnowPlow.event,
        trackerSnowPlow.action,
        trackerSnowPlow.component,
        '',
      );
      pushGa4Generic(ga4Tracker);
    }}
    className="py-2 header-menu"
  >
    {icon}
    {title}
  </a>
);
const MainItemList = () => {
  const mainMenu = Object.keys(menuList).map((item) => (
    <MainItemMenu
      title={menuList[item].mainTitle}
      icon={menuList[item].icon}
      link={menuList[item].link}
      trackerSnowPlow={menuList[item].tracker.snowPlow}
      ga4Tracker={menuList[item].tracker.ga4}
    />
  ));
  return mainMenu;
};

export default MainItemList;
