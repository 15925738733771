import React from 'react';
import SubMenuSearch from './search';
import ProSubMenu from './pro';
import SubMenuJurnal from './jurnal';

// collection submenu
// sumber prop.submenu di turunkan / di ambil dari file layout(folder layout)
// prop tersebut akan di gunakan di dalam object collectionsubbar
// untuk memilih submenu yang akan ditampilkan.
// contoh: di search layout, submenu akan diisi dengan 'search'
// maka file submenu yang akan ditampilkan adalah file search.js
// yang berada di folder search
// jika submenu yang diisi tidak ada di dalam collectionsubbar,
// maka file submenu yang akan ditampilkan adalah null

const SelectedSubMenu = (props) => {
  const collectionSubBar = {
    search: <SubMenuSearch {...props} />,
    pro: <ProSubMenu {...props} />,
    jurnal: <SubMenuJurnal {...props} />,
  };
  return collectionSubBar[props.subMenu] || null;
};
export default SelectedSubMenu;
