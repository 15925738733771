import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward } from 'react-icons/io5';

import PropTypes from 'prop-types';
import SecondaryHeader from './secondaryHeader';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { BASE_URL } = publicRuntimeConfig;

const SolusiMenu = ({ setMenu, data, toggleMenu,t }) => {
  const secondaryMenuTitle = (
    <div className="d-flex align-items-center">
      <span className="header-4 font-weight-bold text-primary">Solusi</span>
    </div>
  );
  return (
    <>
      <SecondaryHeader
        setMenu={setMenu}
        menu={data}
        lvl="lvl2"
        menuActiveLvl1="mainMenu"
        menuActiveLvl2="proMenu"
        menuActiveLvl3=""
        toggleMenu={toggleMenu}
        header={secondaryMenuTitle}
      />
      <Container className="d-flex flex-column py-3">
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - rcs - home', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'rcs - home'
            })
          }}
          href={`https://rcs.hukumonline.com/?utm_source=website&utm_medium=header`}
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img src={`${BASE_URL}/static/images/main/cms.svg`} alt="Icon Compliance Monitor System" />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">Regulatory Compliance System</span>
              <small className="small font-weight-medium text-main">
                  {t('desc-menu-regulatory-compliance-system')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - exdoma - home', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'exdoma - home'
            })
          }}
          href={`https://exdoma.hukumonline.com/?utm_source=website&utm_medium=header`}
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img src={`${BASE_URL}/static/images/main/dms.svg`} alt="Document Management System" />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">Document Management System</span>
              <small className="small font-weight-medium text-main">
               {t('desc-menu-exdoma')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - easybiz- home', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'easybiz- home'
            })
          }}
          href={`https://www.easybiz.id/#layanan-dan-harga?utm_source=hukumonline&utm_medium=navbar`}
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img src={`${BASE_URL}/static/images/main/izinusaha.svg`} alt="Easybiz" />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">Izin Usaha</span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-izin-usaha')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - justika - home', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'justika - home'
            })
          }}
          href={`https://www.justika.com/partner/klinik/?utm_source=hukumonline&utm_medium=navbar&utm_campaign=chat`}
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className='d-flex'>
              <div className=" flex-shrink-1 mr-2">
                <img src={`${BASE_URL}/static/images/main/konsultasi.svg`} alt="Konsultasi" />
              </div>
              <div className="d-flex flex-column">
                <span className="paragraph font-weight-semibold">Konsultasi Hukum</span>
                <small className="small font-weight-medium text-main">
                  {t('desc-menu-konsultasi-hukum')}
                </small>
              </div>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - justika - pembuatan dokumen - home', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'justika - pembuatan dokumen - home'
            })
          }}
          href={`https://business.justika.com/template.html?utm_source=hukumonline&utm_medium=navbar&utm_campaign=template`}
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img src={`${BASE_URL}/static/images/menu/pembuatandokumen.svg`} alt="Konsultasi" />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">Pembuatan Dokumen</span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-pembuatan-dokumen')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
      </Container>
    </>
  );
};
SolusiMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
};
export default SolusiMenu;
