/* eslint-disable react/no-danger */

import React from "react";
import PropTypes from "prop-types";
import { LazyLoadComponent } from "react-lazy-load-image-component";

import parse from "html-react-parser";

// due to onError img component not executed after JS loaded,
// the only solution i can find to render fallback image on SSR is using janky dangerouslySetInnerHTML
// related issue status is still open on https://github.com/facebook/react/issues/15446

// image with fallback component with srcset capability
export const ImageWithFallback = props => {
  const {
    src,
    fallback = "https://static.hukumonline.com/frontend/default/images/kaze/default.jpg",
    alt = "Hukumonline.com",
    title = "Hukumonline.com",
    className = "",
    wrapperClassName = "",
    children,
    figure,
    srcSet = "",
    type = ""
  } = props;
  const imageFile = src.substring(src.lastIndexOf("/") + 1);
  const imageLarge = src.replace(/\/[^\/]*$/, `/large_${imageFile}`);
  const imageMedium = src.replace(/\/[^\/]*$/, `/medium_${imageFile}`);
  const imageSmall = src.replace(/\/[^\/]*$/, `/small_${imageFile}`);
  const imageThumbnail = src.replace(/\/[^\/]*$/, `/thumbnail${imageFile}`);
  //   320px — 480px: Mobile devices
  // 481px — 768px: iPads, Tablets
  // 769px — 1024px: Small screens, laptops
  // 1025px — 1200px: Desktops, large screens
  // 1201px and more —  Extra large screens, TV
  let imageLoader = `
    <picture type='default'>
      <source
        srcset="${src}"
        media="(min-width:  1201px)"
      />
      <source
        srcset="${src}"
        media="(min-width:  1025px)"
      />
      <source
        srcset="${src}"
        media="(min-width:  769px)"
      />
      <source
        srcset="${src}"
        media="(min-width:  481px)"
      />
      <source
        srcset="${src}"
        media="(min-width: 320px)"
      />
      <source
        srcset="${src}"
        media="(min-width: 1px)"
      />
      <img
        alt="${alt}"
        title="${title}"
        src="${src}"
        data-fallback="${fallback}"
        onerror="this.onerror=null;this.src=this.dataset.fallback;"
        class="${className}"
      />
    </picture>
  `;
  if (type == "feed") {
    imageLoader = `
    <picture type='feed'>
      <source
        srcset="${imageMedium}"
        media="(min-width:  1201px)"
      />
      <source
        srcset="${imageMedium}"
        media="(min-width:  1025px)"
      />
      <source
        srcset="${imageMedium}"
        media="(min-width:  769px)"
      />
      <source
        srcset="${imageSmall}"
        media="(min-width:  481px)"
      />
      <source
        srcset="${imageSmall}"
        media="(min-width: 320px)"
      />
      <source
        srcset="${imageSmall}"
        media="(min-width: 1px)"
      />
      <img
        alt="${alt}"
        title="${title}"
        src="${imageMedium}"
        data-fallback="${fallback}"
        onerror="this.onerror=null;this.src=this.dataset.fallback;"
        class="${className}"
      />
    </picture>
  `;
  }
  if (type == "sidebar") {
    imageLoader = `
    <picture type='sidebar'>
      <source
        srcset="${imageSmall}"
        media="(min-width:  1201px)"
      />
      <source
        srcset="${imageSmall}"
        media="(min-width:  1025px)"
      />
      <source
        srcset="${imageSmall}"
        media="(min-width:  769px)"
      />
      <source
        srcset="${imageSmall}"
        media="(min-width:  481px)"
      />
      <source
        srcset="${imageSmall}"
        media="(min-width: 320px)"
      />
      <source
        srcset="${imageSmall}"
        media="(min-width: 1px)"
      />
      <img
        alt="${alt}"
        title="${title}"
        src="${imageSmall}"
        data-fallback="${fallback}"
        onerror="this.onerror=null;this.src=this.dataset.fallback;"
        class="${className}"
      />
    </picture>
  `;
  }
  if (figure) {
    return parse(imageLoader);
  }
  return (
    <>
      <div
        className={wrapperClassName}
        dangerouslySetInnerHTML={{
          __html: imageLoader
        }}
      />
      {children}
    </>
  );
};

export const LazyImageWithFallback = props => (
  <LazyLoadComponent>
    <ImageWithFallback {...props} />
  </LazyLoadComponent>
);

ImageWithFallback.propTypes = {
  src: PropTypes.string.isRequired,
  fallback: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string
};

ImageWithFallback.defaultProps = {
  className: "",
  wrapperClassName: "",
  alt: ""
};
