import React, { Component } from 'react';
import Head from 'next/head';
import striptags from 'striptags';
import _ from 'lodash';
import stylesheet from '../styles/index.scss';
// import { getFingerprint, hashFingerprint } from '../libraries/fingerprint';
// import {
//   setCookie, getCookie, getCookieJSON, removeCookie,
// } from '../libraries/session';
import MetaSeo from './metaSeo';

class Meta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUrl: null,
    };
  }

  componentDidMount() {
    this.setState({ currentUrl: window.location.href });

    // Fingerprint
    // if (!getCookie('_holdi')) {
    //   setTimeout(() => {
    //     getFingerprint().then((fp) => {
    //       const device = _.mapValues(_.keyBy(fp, 'key'), 'value');
    //       const deviceId = hashFingerprint(fp);
    //       setCookie('_holdi', deviceId);
    //     });
    //   }, 500); // delay execution time
    // }
  }

  render() {
    let { preconnectImageLCP } = this.props;

    const { srcLCP = null, src500LCP = null } = { ...preconnectImageLCP };

    return (
      <div>
        <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <meta
            name="google-site-verification"
            content="MUDP2J4JyyfKSWJsjfuglidOXiHOU_vn5VLXY7S-G8w"
          />

          <meta name="revisit-after" content="7" />
          <meta charSet="UTF-8" />
          <meta name="webcrawlers" content="all" />
          <meta name="rating" content="general" />
          <meta name="spiders" content="all" />
          <meta name="robots" content="all" />
          <link rel="shortcut icon" href="/static/images/favicon.ico" />
          <link rel="icon" href="/static/images/favicon.ico" />
          {src500LCP && (
            <link
              rel="preload"
              as="image"
              href={src500LCP}
              media="(max-width: 599.98px)"
            />
          )}
          {srcLCP && (
            <link
              rel="preload"
              as="image"
              href={srcLCP}
              media="(min-width: 600.1px)"
            />
          )}
        </Head>
        <MetaSeo {...this.props} />
      </div>
    );
  }
}

export default Meta;
