import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward } from 'react-icons/io5';
import { BsCheckCircleFill, BsGlobe, BsHouseDoorFill } from 'react-icons/bs';
import PropTypes from 'prop-types';
import SecondaryHeader from './secondaryHeader';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { BASE_URL } = publicRuntimeConfig;

const KlinikMenu = ({ setMenu, data, toggleMenu, i18n }) => {
  const secondaryMenuTitle = (
    <div className="d-flex align-items-center">
      <BsGlobe fontSize="18px" className="text-primary mr-2" />{' '}
      <span className="header-4 font-weight-bold text-primary">Bahasa</span>
    </div>
  );
  const utm = '';
  return (
    <>
      <SecondaryHeader
        setMenu={setMenu}
        menu={data}
        lvl="lvl2"
        menuActiveLvl1="personalisasiMenu"
        menuActiveLvl2="bahasaMenu"
        menuActiveLvl3=""
        toggleMenu={toggleMenu}
        header={secondaryMenuTitle}
      />
      <Container className="d-flex flex-column py-3">
        <div className={'personalisasi-bahasa py-4'}>
          <div
            className={
              'personalisasi-bahasa-item d-flex justify-content-between align-items-center py-3'
            }
          >
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('id');
                structuredEvent('navbar', 'click  - bahasa ', 'id', '');
                pushGa4Generic({
                  event: 'Navbar',
                  action: 'link_click',
                  component: 'bahasa',
                });
              }}
            >
              <div className="d-flex align-items-center">
                <img
                  src={`${BASE_URL}/static/images/flags/flags-iso/shiny/24/id.png`}
                  className="mr-3"
                  alt="Indonesia flag"
                />
                <span className={'paragraph text-main font-weight-semibold'}>
                  Bahasa Indonesia
                </span>
              </div>
            </button>
            <div>
              {i18n.language == 'id' && (
                <BsCheckCircleFill
                  fontSize="24px"
                  className="text-success mr-2"
                />
              )}
            </div>
          </div>
          <div
            className={
              'personalisasi-bahasa-item d-flex justify-content-between align-items-center py-3'
            }
          >
            <button
              type="button"
              onClick={() => {
                i18n.changeLanguage('en');
                structuredEvent('navbar', 'click  - bahasa ', 'en', '');
              }}
            >
              <div className="d-flex align-items-center">
                <img
                  src={`${BASE_URL}/static/images/flags/flags-iso/shiny/24/en.png`}
                  className="mr-3"
                  alt="Indonesia flag"
                />
                <span className={'paragraph text-main font-weight-semibold'}>
                  English
                </span>
              </div>
            </button>
            <div>
              {i18n.language == 'en' && (
                <BsCheckCircleFill
                  fontSize="24px"
                  className="text-success mr-2"
                />
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
KlinikMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
};
export default KlinikMenu;
