import React from 'react';
import Container from 'reactstrap/lib/Container';
import stylesheet from './searchSuggestMenu.scss';

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { SEARCH_URL } = publicRuntimeConfig;

const SearchSuggestMenu = (props) => {
  const { query } = props;
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      <Container className="mobile-search-suggest-title h-100">
        <div>
          <div className="mb-3">
            <div> <a href={`${SEARCH_URL}/search/all/?q=${query}`}>... di Semua</a></div>
            <div className="mt-2"> <a href={`${SEARCH_URL}/search/analyses/?q=${query}`}>... di Analisis</a></div>
            <div className="mt-2"> <a href={`${SEARCH_URL}/search/regulations/?q=${query}`}>... di Peraturan</a></div>
            <div className="mt-2"> <a href={`${SEARCH_URL}/search/decisions/?q=${query}`}>... di Putusan</a></div>
            <div className="mt-2"> <a href={`${SEARCH_URL}/search/news/?q=${query}`}>... di Berita</a></div>
            <div className="mt-2"> <a href={`${SEARCH_URL}/search/clinics/?q=${query}`}>... di Klinik</a></div>
            <div className="mt-2"> <a href={`${SEARCH_URL}/search/events/?q=${query}`}>... di Event</a></div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SearchSuggestMenu;
