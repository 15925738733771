import {
  getData,
  getWp,
} from '../libraries/request';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { TOKEN_PUBLIC, TOKEN_WP, API_BASE_URL_WP } = publicRuntimeConfig;

const Landing = {
  main: async () => {
    const home = await getData('/utils/homedata', TOKEN_PUBLIC);
    if (home.data && home.data.premium_news.data) {
      home.data.premium_news.data = home.data.premium_news.data.slice(0, 2);
    }
    const data = home || [];
    return data;
  },
  popularSearch: async () => {
    // const popularSearch = await getData('/utils/search/popular?limit=3', TOKEN_PUBLIC);
    // const data = popularSearch || [];
    const data = [];
    return data;
  },
  courses: async () => {
    const endpoint = `${API_BASE_URL_WP}/wp-json/hol/v1/kelas`;
    const courses = await getWp(endpoint, TOKEN_WP);
    const status = [403, 500, 404, 503];
    const data = status.includes(courses?.status) ? [] : courses;
    return data;
  },
};

module.exports = {
  Landing,
};
