import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward } from 'react-icons/io5';
import { BsHouseDoorFill } from 'react-icons/bs';
import PropTypes from 'prop-types';
import SecondaryHeader from './secondaryHeader';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { BASE_URL } = publicRuntimeConfig;

const EventMenu = ({ setMenu, data, toggleMenu }) => {
  const secondaryMenuTitle = (
    <div className="d-flex align-items-center">
      <img src={`${BASE_URL}/static/images/menu/event.svg`} alt="Icon Event" className="mr-2" />
      <span className="header-4 font-weight-bold text-primary">Event</span>
    </div>
  );
  const utm = '';
  return (
    <>
      <SecondaryHeader
        setMenu={setMenu}
        menu={data}
        lvl="lvl3"
        menuActiveLvl1="mainMenu"
        menuActiveLvl2="wawasanMenu"
        menuActiveLvl3=""
        toggleMenu={toggleMenu}
        header={secondaryMenuTitle}
      />
      <Container className="d-flex flex-column py-3">
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - event - home', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'event - home'
            })
          }}
          href={`${BASE_URL}/talks/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <BsHouseDoorFill fontSize="20px" className="mr-2" />
              <span className="paragraph font-weight-bold">Beranda</span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - event - kegiatan terkini', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'event - kegiatan terkini'
            })
          }}
          href={`${BASE_URL}/talks/kegiatanterkini/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Kegiatan terkini </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - event - event organizer', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'event - event organizer'
            })
          }}
          href={`${BASE_URL}/talks/eo/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Event Organizer </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - event - iht', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'event - iht'
            })
          }}
          href={`${BASE_URL}/talks/iht/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">In-House Training </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - event - arsip kegiatan', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'event - arsip kegiatan'
            })
          }}
          href={`${BASE_URL}/talks/arsipkegiatan/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Arsip Kegiatan </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - event - narasumber', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'event - narasumber'
            })
          }}
          href={`${BASE_URL}/talks/narasumber/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Narasumber </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
      </Container>
    </>
  );
};
EventMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
};
export default EventMenu;
