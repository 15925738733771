import React from 'react';
import { Col, InputGroupDropdown, Badge } from 'reactstrap';

import FontAwesome from 'react-fontawesome';

const iconStatus = (color, icon, iconType = 'circle', iconColor = 'text-white') => (
  <>
    <span className="fa-stack fa-md">
      <i className={`fa fa-${iconType} fa-stack-1x ${color}`} style={{ fontSize: '22px' }} />
      <i className={`fa fa-${icon} fa-stack-1x ${iconColor}`} />
    </span>
  </>
);
const iconStatusSpan = (color, icon, iconType = 'circle', iconColor = 'text-white') => (
  <>
    <span className="fa-stack fa-md">
      <i className={`fa fa-${iconType} fa-stack-1x ${color}`} style={{ fontSize: '1.3em' }} />
      <i className={`fa fa-${icon} fa-stack-1x ${iconColor}`} />
    </span>
  </>
);
// eslint-disable-next-line import/prefer-default-export
export const LabelStatus = (props) => {
  let {
    status, content, statusTranslate, styleClass, defaultColor, type,
  } = props;
  let icon;
  let mainIconColor;
  let bgColor = '';
  let iconColor = 'text-white';
  let iconType = 'circle';

  if (typeof status === 'undefined' || status == 'unauthorized') {
    console.log(status);
    return null;
  }

  // eslint-disable-next-line default-case
  switch (status.toLowerCase()) {
    case 'in effect':
      icon = 'check';
      iconColor = 'text-success';
      bgColor = 'bg-success';
      break;
    case 'repealed':
      icon = 'times';
      iconColor = 'text-danger';
      bgColor = 'bg-danger';
      break;
    case 'partially repealed':
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'amended':
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'amended and partially repealed':
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'belum diubah/dicabut':
      icon = 'check';
      iconColor = 'text-success';
      bgColor = 'bg-success';
      break;
    case 'dikabulkan':
      icon = 'gavel';
      iconColor = 'text-success';
      bgColor = 'bg-success';
      break;
    case 'telah dicabut':
      icon = 'times';
      iconColor = 'text-danger';
      bgColor = 'bg-danger';
      break;
    case 'ditolak':
      icon = 'gavel';
      iconColor = 'text-danger';
      bgColor = 'bg-danger';
      break;
    case 'dikabulkan sebagian':
      icon = 'gavel';
      iconColor = 'text-secondary';
      break;
    case 'telah diubah':
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'telah diubah & dicabut sebagian':
    case 'telah diubah dan dicabut sebagian':
      statusTranslate = 'Telah Diubah & Dicabut Sebagian';
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'telah dicabut sebagian':
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'bahasa':
      icon = 'globe';
      iconColor = 'text-primary';
      iconType = 'square';
      break;
    case 'ilb':
      icon = 'gavel';
      iconColor = 'text-primary';
      iconType = '';
      mainIconColor = 'text-primary';
      break;
    case 'ild':
      icon = 'newspaper';
      iconColor = 'text-primary';
      iconType = '';
      mainIconColor = 'text-primary';
      break;
  }
  if (type == 'Tingkat' || type == 'Level') {
    icon = 'university';
    iconColor = 'text-light';
    mainIconColor = 'text-black';
  }
  console.log(statusTranslate);
  return (

    <Badge
      color="muted"
      style={{ background: '#f2f2f2' }}
      pill
      className="d-inline-flex flex-row justify-content-center p-1 px-3 my-1 align-items-center"
    >
      {iconStatus(iconColor, icon, iconType, mainIconColor)}
      <span className="ml-2 font-weight-normal">
        <strong>{type} </strong>
        {statusTranslate}
        {content}
      </span>
    </Badge>
  );
};
export const LabelPro = (props) => {
  let color = 'info';
  if (props.multiple) {
    color = props.active ? 'info' : 'dark';
  }
  if (props.color) {
    color = props.color;
  }
  return (
    <Badge
      color={color}
      pill
      className="d-inline-flex flex-row justify-content-center py-1 px-0  px-3 my-md-1 my-1 align-items-center"
    >
      {props.data}
    </Badge>
  );
};

export const IsPaid = props => (
  <Badge
    color="primary"
    pill
    className={`d-inline-flex flex-row justify-content-center align-items-center ${props.className}`}
  >
    <i className="fa fa-star mr-2" />
    Pro
  </Badge>
);
export const SpanStatus = (props) => {
  let {
    status, content, statusTranslate, styleClass, defaultColor, type, t, statusOnly,iconOnly
  } = props;
  let icon;
  let mainIconColor;
  let bgColor = '';
  let iconColor = 'text-white';
  let iconType = 'circle';
  const typeStatus = props.typeStatus ? props.typeStatus : 'status';
  let iconComplete = '';

  if (typeof status === 'undefined') {
    return null;
  }

  // eslint-disable-next-line default-case
  switch (status.toLowerCase()) {
    case 'in effect':
      icon = 'check';
      iconColor = 'text-success';
      bgColor = 'bg-success';
      break;
    case 'repealed':
      icon = 'times';
      iconColor = 'text-danger';
      bgColor = 'bg-danger';
      break;
    case 'partially repealed':
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'amended':
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'amended and partially repealed':
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'belum diubah/dicabut':
      icon = 'check';
      iconColor = 'text-success';
      bgColor = 'bg-success';
      break;
    case 'dikabulkan':
      icon = 'gavel';
      iconColor = 'text-success';
      bgColor = 'bg-success';
      break;
    case 'telah dicabut':
      icon = 'times';
      iconColor = 'text-danger';
      bgColor = 'bg-danger';
      break;
    case 'ditolak':
      icon = 'gavel';
      iconColor = 'text-danger';
      bgColor = 'bg-danger';
      break;
    case 'dikabulkan sebagian':
      icon = 'gavel';
      iconColor = 'text-secondary';
      break;
    case 'telah diubah':
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'telah diubah & dicabut sebagian':
      icon = 'exclamation';
      iconColor = 'text-secondary';
      break;
    case 'bahasa':
      icon = 'globe';
      iconColor = 'text-primary';
      iconType = 'square';
      break;
    case 'ilb':
      icon = 'gavel';
      iconColor = 'text-primary';
      iconType = '';
      mainIconColor = 'text-primary';
      break;
    case 'ild':
      icon = 'newspaper';
      iconColor = 'text-primary';
      iconType = '';
      mainIconColor = 'text-primary';
      break;
    case 'unauthorized':
      icon = 'question-circle';
      statusTranslate = t('only subscriber');
      iconColor = 'text-muted';
      iconType = '';
      mainIconColor = 'text-muted';
      break;
  }
  iconComplete = iconStatusSpan(iconColor, icon, iconType, mainIconColor);
  if (typeStatus == 'Tingkat') {
    iconComplete = <FontAwesome className="mx-2 " name="university" />;
  }
  if (statusOnly) {
    return statusTranslate;
  }
  if (iconOnly) {
    return iconComplete;
  }

  return (
    <div className={`d-flex align-items-center ${styleClass}`}>
      {' '}
      <strong className="text-capitalize">{typeStatus} </strong> {iconComplete}
      <strong>{type} </strong>&nbsp;
      {statusTranslate}
      {content}
    </div>
  );
};

export const Headline = ({ value }) => <h1>{value}</h1>;
