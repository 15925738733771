import React from 'react';
import Container from 'reactstrap/lib/Container';
import PropTypes from 'prop-types';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, OC_URL, PRO_URL } = publicRuntimeConfig;

const SearchMenu = ({
  popularSearch, userDetail
}) => (
  <Container className="py-4 d-flex flex-column justify-content-between align-items-center h-100">
    <div>
      {/* <div className="populer-search mb-3">
        <span className="body font-weight-semibold">Pencarian Populer</span>
        <div className="d-flex flex-column">
          {popularSearch && popularSearch.map((item) => (
            <a
              href={item.url}
              key={`${item.guid}`}
              className="body text-main my-1"
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
          ))
          }
        </div>
      </div> */}
      <div className="selected-product">
        <span className="body font-weight-semibold">Produk Pilihan</span>
        <div className="d-flex flex-column py-2">
          <a href={`${userDetail.isAuthenticated ? `${PRO_URL}/home` : PRO_URL}`}>
            <div className="d-flex flex-row">
              <div>
                <img
                  src={`${BASE_URL}/static/images/menu/legal-pilihan.webp`}
                  alt="Icon Kamus"
                />
              </div>
              <div className="bg-hol-soft px-2 d-flex flex-column justify-content-center">
                <span className="extra-small font-weight-semibold text-main">
                  Legal Analysis
                </span>
                <p className="micro text-main m-0">
                  Analisis komprehensif isu hukum terkini secara bilingual dan terintegrasi dengan pusat data
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="d-flex flex-column py-2">
          <a href={`${OC_URL}`}>
            <div className="d-flex flex-row bg-hol-soft ">
              <div>
                <img
                  src={`${BASE_URL}/static/images/menu/online-pilihan.png`}
                  alt="Icon Online course"
                />
              </div>
              <div className="bg-hol-soft px-2 d-flex flex-column justify-content-center">
                <span className="extra-small font-weight-semibold text-main">
                  Online Course
                </span>
                <p className="micro text-main m-0">
                  Platform belajar hukum tanpa batasan ruang dan waktu, tersedia sertifikat setelah kursus selesai
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="d-flex flex-column py-2">
          <a
            href="https://rcs.hukumonline.com/?utm_source=website&utm_medium=header"
          >
            <div className="d-flex flex-row">
              <div>
                <img
                  src={`${BASE_URL}/static/images/menu/rcs-pilihan.png`}
                  alt="Icon RCS"
                />
              </div>
              <div className="bg-hol-soft px-2 d-flex flex-column justify-content-center">
                <span className="extra-small font-weight-semibold text-main">
                  Regulatory Compliance System
                </span>
                <p className="micro text-main m-0">
                  Platform pemantauan kepatuhan hukum terintegrasi dan berbasis teknologi Artificial Intelligence
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div className="flex-grow-1 d-flex align-items-end mt-5 ">
      <img src={`${BASE_URL}/static/images/main/ask.svg`} alt="Icon Ask" className="mr-2" />
      <span className="small">
        Ada Pertanyaan?{' '}
        <a
          href="https://www.hukumonline.com/produk/hubungi-kami/id/"
          className="font-weight-semibold text-primary"
        >
          {' '}
          Hubungi Kami{' '}
        </a>
      </span>
    </div>
  </Container>
);

SearchMenu.propTypes = {
  popularSearch: PropTypes.shape.isRequired,
};
export default SearchMenu;
