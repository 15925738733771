import React from 'react';

import { structuredEvent, pushGa4Generic } from '../../libraries/tracker';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, JURNAL_URL, PRO_URL, OC_URL } = publicRuntimeConfig;

const DesktopFooter = ({
  t,
  logo,
  textColor,
  primaryTextColor,
  logoKominfo,
}) => (
  <footer className="footer-container">
    <div className="footer-item">
      <div className="mb-4">
        <img src={logo} alt="Logo Hukumonline" className="footer-logo" />
      </div>
      <div className="footer-location">
        <address className="d-flex mb-0">
          <div className="flex-shrink-0 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#162533"
              viewBox="0 0 256 256"
            >
              <path d="M200,224H150.54A266.56,266.56,0,0,0,174,200.25c27.45-31.57,42-64.85,42-96.25a88,88,0,0,0-176,0c0,31.4,14.51,64.68,42,96.25A266.56,266.56,0,0,0,105.46,224H56a8,8,0,0,0,0,16H200a8,8,0,0,0,0-16ZM128,72a32,32,0,1,1-32,32A32,32,0,0,1,128,72Z" />
            </svg>
          </div>

          <span className={`${textColor} small ml-2`}>
            AD Premier 9th floor, Jl. TB Simatupang No.5 Ragunan, Pasar Minggu,
            Jakarta Selatan 12550, DKI Jakarta, Indonesia
          </span>
        </address>
        <div className="d-flex ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#162533"
            viewBox="0 0 256 256"
          >
            <path d="M231.88,175.08A56.26,56.26,0,0,1,176,224C96.6,224,32,159.4,32,80A56.26,56.26,0,0,1,80.92,24.12a16,16,0,0,1,16.62,9.52l21.12,47.15,0,.12A16,16,0,0,1,117.39,96c-.18.27-.37.52-.57.77L96,121.45c7.49,15.22,23.41,31,38.83,38.51l24.34-20.71a8.12,8.12,0,0,1,.75-.56,16,16,0,0,1,15.17-1.4l.13.06,47.11,21.11A16,16,0,0,1,231.88,175.08Z" />
          </svg>
          <div className="d-flex flex-column ml-2">
            <a className={`${textColor} small`} href="tel:+622122708910">
              Phone: +62 21 - 2270 - 8910
            </a>
            <a className={`${textColor} small`} href="fax:+622122708909">
              Fax: +62 21 - 2270 - 8909
            </a>
          </div>
        </div>
        <div className="d-flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#162533"
            viewBox="0 0 256 256"
          >
            <path d="M224,48H32a8,8,0,0,0-8,8V192a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A8,8,0,0,0,224,48ZM98.71,128,40,181.81V74.19Zm11.84,10.85,12,11.05a8,8,0,0,0,10.82,0l12-11.05,58,53.15H52.57ZM157.29,128,216,74.18V181.82Z" />
          </svg>{' '}
          <div className="d-flex flex-column ml-2">
            <a
              className={`${textColor} small`}
              href="mailto:customer@hukumonline.com"
            >
              customer@hukumonline.com
            </a>
            <a
              className={`${textColor} small`}
              href="mailto:redaksi@hukumonline.com"
            >
              redaksi@hukumonline.com
            </a>
          </div>
        </div>
        <div className="pt-3 d-flex flex-row">
          <a
            onClick={() => {
              structuredEvent(
                'footer',
                'click url - facebook',
                'beranda',
                '',
                '',
              );
              pushGa4Generic({
                event: 'footer',
                action: 'click url',
                component: 'facebook',
              });
            }}
            className={`${textColor} small`}
            href="https://www.facebook.com/hukumonlinecom/"
          >
            <img
              src={`${BASE_URL}/static/images/social-media/facebook.svg`}
              alt="Icon Facebook"
              width={24}
              height={24}
            />
          </a>
          <a
            onClick={() => {
              structuredEvent(
                'footer',
                'click url - instagram',
                'beranda',
                '',
                '',
              );
              pushGa4Generic({
                event: 'footer',
                action: 'click url',
                component: 'instagram',
              });
            }}
            className={`${textColor} small ml-3`}
            href="https://www.instagram.com/hukum_online/"
          >
            <img
              src={`${BASE_URL}/static/images/social-media/instagram.svg`}
              alt="Icon instagram"
              width={24}
              height={24}
            />
          </a>
          <a
            onClick={() => {
              structuredEvent(
                'footer',
                'click url - linkedin',
                'beranda',
                '',
                '',
              );
              pushGa4Generic({
                event: 'footer',
                action: 'click url',
                component: 'linkedin',
              });
            }}
            className={`${textColor} small ml-3`}
            href="https://www.linkedin.com/company/hukumonline.com/"
          >
            <img
              src={`${BASE_URL}/static/images/social-media/linkedin.svg`}
              alt="Icon Linkedin"
              width={24}
              height={24}
            />
          </a>
          <a
            onClick={() => {
              structuredEvent(
                'footer',
                'click url - youtube',
                'beranda',
                '',
                '',
              );
              pushGa4Generic({
                event: 'footer',
                action: 'click url',
                component: 'youtube',
              });
            }}
            className={`${textColor} small ml-3`}
            href="https://www.youtube.com/user/hukumonlinevideo"
          >
            <img
              src={`${BASE_URL}/static/images/social-media/youtube.svg`}
              alt="Icon Ask"
              width={24}
              height={24}
            />
          </a>
          <a
            onClick={() => {
              structuredEvent(
                'footer',
                'click url - twitter',
                'beranda',
                '',
                '',
              );
              pushGa4Generic({
                event: 'footer',
                action: 'click url',
                component: 'twitter',
              });
            }}
            className={`${textColor} small ml-3`}
            href="https://www.twitter.com/hukumonline"
          >
            <img
              src={`${BASE_URL}/static/images/social-media/x.svg`}
              alt="Icon X"
              width={24}
              height={24}
            />
          </a>
          <a
            onClick={() => {
              structuredEvent(
                'footer',
                'click url - tiktok',
                'beranda',
                '',
                '',
              );
              pushGa4Generic({
                event: 'footer',
                action: 'click url',
                component: 'tiktok',
              });
            }}
            className={`${textColor} small ml-3`}
            href="https://www.tiktok.com/@hukumonline?lang=id-ID"
          >
            <img
              src={`${BASE_URL}/static/images/social-media/tiktok.svg`}
              alt="Icon tiktok"
              width={24}
              height={24}
            />
          </a>
        </div>
      </div>
    </div>
    <div className="footer-item">
      <span className={`body font-weight-bold mb-1 ${primaryTextColor}`}>
        Pro
      </span>
      <div className="footer-shortcut">
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - analisis_hukum',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'analisis_hukum',
            });
          }}
          className={`${textColor} small`}
          href={`${PRO_URL}/?utm_source=hukumonline&utm_medium=footer`}
        >
          Analisis Hukum
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - pusat_data',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'pusat_data',
            });
          }}
          className={`${textColor} small`}
          href={`${BASE_URL}/pusatdata`}
        >
          {t('pusat data')}
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - legal_intelligence_updates',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'legal_intelligence_updates',
            });
          }}
          className={`${textColor} small`}
          href={`${PRO_URL}/legal-intelligence/?utm_source=website&utm_medium=footer`}
        >
          Legal Intelligence Updates
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - premium_stories',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'premium_stories',
            });
          }}
          className={`${textColor} small`}
          href={`${BASE_URL}/stories/?utm_source=website&utm_medium=footer`}
        >
          Premium Stories
        </a>
      </div>
    </div>
    <div className="footer-item">
      <span className={`body font-weight-bold ${primaryTextColor}`}>
        Solusi
      </span>
      <div className="footer-shortcut small mt-1">
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - unisol', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'unisol',
            });
          }}
          className={`${textColor}`}
          href={`${PRO_URL}/solusi-universitas-hukum/?utm_source=website&utm_medium=footer`}
        >
          University Solutions
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - rcs', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'rcs',
            });
          }}
          className={`${textColor}`}
          href="https://rcs.hukumonline.com/?utm_source=website&utm_medium=footer"
        >
          Regulatory Compliance System
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - exdoma ', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'solusi - exdoma',
            });
          }}
          className={`${textColor}`}
          href="https://exdoma.hukumonline.com/?utm_source=website&utm_medium=footer"
        >
          Document Management System
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - easybiz', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'easybiz',
            });
          }}
          className={`${textColor}`}
          href="https://www.easybiz.id/#layanan-dan-harga?utm_source=hukumonline&utm_medium=footer"
        >
          Izin Usaha
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - konsultasi_hukum',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'konsultasi_hukum',
            });
          }}
          className={`${textColor}`}
          href="https://www.justika.com/partner/klinik/?utm_source=hukumonline&utm_medium=footer&utm_campaign=chat"
        >
          Konsultasi Hukum
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - pembuatan_dokumen',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'pembuatan_dokumen',
            });
          }}
          className={`${textColor}`}
          href="https://business.justika.com/template.html?utm_source=hukumonline&utm_medium=navbar&utm_campaign=template"
        >
          Pembuatan Dokumen
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - hol360', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'hol_360',
            });
          }}
          className={`${textColor}`}
          href="https://www.hukumonline.com/hukumonline-360/"
        >
          Hukumonline 360
        </a>
      </div>
    </div>
    <div className="footer-item">
      <span className={`body font-weight-bold ${primaryTextColor}`}>
        Info Hukum
      </span>
      <div className="footer-shortcut small mt-1">
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - klinik', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'klinik',
            });
          }}
          className={`${textColor}`}
          href="https://www.hukumonline.com/klinik/?utm_source=hukumonline&utm_medium=footer"
        >
          Klinik
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - hukumonline_stream',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'hukumonline_stream',
            });
          }}
          className={`${textColor}`}
          href="https://www.hukumonline.com/stream/?utm_source=hukumonline&utm_medium=footer"
        >
          Hukumonline Stream
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - jurnal', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'jurnal',
            });
          }}
          className={`${textColor}`}
          href="https://jurnal.hukumonline.com/?utm_source=website&utm_medium=footer"
        >
          Jurnal
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - berita', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'berita',
            });
          }}
          className={`${textColor}`}
          href="https://www.hukumonline.com/berita/?utm_source=website&utm_medium=footer"
        >
          Berita
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - datapribadi',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'datapribadi',
            });
          }}
          className={`${textColor}`}
          href="https://www.datapribadi.id/?utm_source=hukumonline&utm_medium=footer"
        >
          Data Pribadi
        </a>
      </div>
    </div>
    <div className="footer-item">
      <span className={`body font-weight-bold ${primaryTextColor}`}>
        Event & Awards
      </span>
      <div className="footer-shortcut small mt-1">
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - event', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'event',
            });
          }}
          className={`${textColor}`}
          href="https://www.hukumonline.com/events/"
        >
          Event
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - awards', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'awards',
            });
          }}
          className={`${textColor}`}
          href="https://awards.hukumonline.com/?utm_source=website&utm_medium=footer"
        >
          Awards
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - online_publication',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'online_publication ',
            });
          }}
          className={`${textColor}`}
          href="https://www.hukumonline.com/online-publication/"
        >
          Publikasi Online
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - online_course',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'online course',
            });
          }}
          className={`${textColor}`}
          href="https://learning.hukumonline.com/?utm_source=website&utm_medium=footer"
        >
          Online Course
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - pkpa', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'pkpa',
            });
          }}
          className={`${textColor}`}
          href="https://pkpa.hukumonline.com/?utm_source=website&utm_medium=footer"
        >
          PKPA
        </a>
      </div>
    </div>
    <div className="footer-item d-flex flex-column ">
      <span className={`body font-weight-bold ${primaryTextColor}`}>
        Hukumonline
      </span>
      <div className="footer-shortcut small mt-1">
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - tentang_kami',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'tentang_kami',
            });
          }}
          className={`${textColor}`}
          href={`${BASE_URL}/tentangkami`}
        >
          {t('tentang kami')}
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - katalog_produk',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'katalog_produk',
            });
          }}
          className={`${textColor}`}
          href={`${BASE_URL}/produk/id/?utm_source=website&utm_medium=footer`}
        >
          {t('katalog produk')}
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - redaksi', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'redaksi',
            });
          }}
          className={`${textColor}`}
          href={`${BASE_URL}/redaksi`}
        >
          {t('redaksi')}
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - pedoman_media_siber',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'pedoman_media_siber',
            });
          }}
          className={`${textColor}`}
          href={`${BASE_URL}/pedoman-berita`}
        >
          {t('pedoman media siber')}
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - kode_etik',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'kode_etik',
            });
          }}
          className={`${textColor}`}
          href={`${BASE_URL}/kode-etik`}
        >
          {t('kode etik')}
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - syarat_penggunaan_layanan',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'syarat_penggunaan_layanan',
            });
          }}
          className={`${textColor}`}
          href={`${BASE_URL}/syarat-penggunaan-layanan`}
        >
          {t('syarat pengunaan layanan')}
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'footer',
              'click url - bantuan_faq',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'bantuan_faq',
            });
          }}
          className={`${textColor}`}
          href={`${BASE_URL}/helpcenter`}
        >
          {t('bantuan')}
        </a>
        <a
          onClick={() => {
            structuredEvent('footer', 'click url - karir', 'beranda', '', '');
            pushGa4Generic({
              event: 'footer',
              action: 'click url',
              component: 'karir',
            });
          }}
          className={`${textColor}`}
          href={`${BASE_URL}/karir`}
        >
          {t('karir')}
        </a>
      </div>
      <div className="mt-5 d-flex">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://pse.kominfo.go.id/tdpse-detail/5020"
        >
          <img src={logoKominfo} alt="logo kominfo" className="isoLinks" />
        </a>
        <img
          src={`${BASE_URL}/static/images/logo/trusted.png`}
          alt="logo trusted"
          className="isoLinks ml-3"
        />
      </div>
    </div>
  </footer>
);
export default DesktopFooter;
