import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward } from 'react-icons/io5';
import { BsAward, BsFillStarFill } from 'react-icons/bs';
import { AiOutlineFileSearch, AiOutlineFolder } from 'react-icons/ai';
import PropTypes from 'prop-types';
import SecondaryHeader from './secondaryHeader';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';
import { t } from 'i18next';

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { BASE_URL, PRO_URL } = publicRuntimeConfig;

const ProMenu = ({ setMenu, data, toggleMenu, t }) => {
  const secondaryMenuTitle = (
    <div className="d-flex align-items-center">
      <span className="header-4 font-weight-bold text-primary">Pro</span>
      <BsFillStarFill fontSize="14px" className="ml-1 text-secondary" />
    </div>
  );
  return (
    <>
      <SecondaryHeader
        setMenu={setMenu}
        menu={data}
        lvl="lvl2"
        menuActiveLvl1="mainMenu"
        menuActiveLvl2="proMenu"
        menuActiveLvl3=""
        toggleMenu={toggleMenu}
        header={secondaryMenuTitle}
      />
      <Container className="d-flex flex-column py-3">
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - legal analysis - home',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'legal analysis - home',
            });
          }}
          href={`${PRO_URL}/home/?utm_source=website&utm_medium=header`}
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <AiOutlineFileSearch fontSize="14px" />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">
                Analisis Hukum
              </span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-analisa-hukum')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <button
          type="button"
          onClick={() =>
            toggleMenu(
              data,
              {
                name: 'lvl3',
                isOpen: !data.isOpen.lvl3,
                menuActiveLvl3: 'pusatDataMenu',
              },
              setMenu,
            )
          }
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <AiOutlineFolder fontSize="14px" />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">Pusat Data</span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-pusat-data')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </button>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - premium stories - home',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'premium stories - home',
            });
          }}
          href={`${BASE_URL}/stories/`}
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <BsAward fontSize="14px" />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">
                Premium Stories
              </span>
              <small className="small font-weight-medium text-main">
                {t('desc-menu-premium-stories')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - university solution - home',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'university solution - home',
            });
          }}
          href={`${PRO_URL}/solusi-universitas-hukum/?utm_source=website&utm_medium=navbar `}
          className="mb-4"
        >
          <div className="d-flex flex-row text-primary justify-content-between">
            <div className=" flex-shrink-1 mr-2">
              <img
                src={`${BASE_URL}/static/images/menu/student.svg`}
                alt="Icon University Solutions"
                width={14}
              />
            </div>
            <div className="d-flex flex-column">
              <span className="paragraph font-weight-semibold">
                University Solutions{' '}
              </span>
              {/*  */}
              <small className="small font-weight-medium text-main">
                {t('desc-menu-university-solution')}
              </small>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
      </Container>
    </>
  );
};
ProMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
};
export default ProMenu;
