import React, { useState, useEffect } from 'react';

const StickyCovid = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(true);
  }, []);
  if (!isOpen) return null;
  return (
    <div id="covidBanner" className="d-flex justify-content-around align-items-center" id="covidBanner">
      <a
        target="_blank"
        href="https://rcs.hukumonline.com/?utm_source=website&utm_medium=sticky_banner"
        style={{ textDecoration: 'none', color: '#fff' }}
        rel="noreferrer"
      >
        <span style={{ fontWeight: '400', color: '#fff' }}>
        Pantau Kewajiban Hukum Perusahaan Anda Di Sini!
        </span>
        
      </a>
      <i
        className="fa fa-times"
        style={{ cursor: 'pointer'}}
        onClick={() => setIsOpen(false)}
        
      />
    </div>
  );
};

export default StickyCovid;
