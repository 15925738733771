import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward } from 'react-icons/io5';
import { BsHouseDoorFill } from 'react-icons/bs';
import PropTypes from 'prop-types';
import SecondaryHeader from './secondaryHeader';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { BASE_URL, JURNAL_URL } = publicRuntimeConfig;

const JurnalMenu = ({ setMenu, data, toggleMenu }) => {
  const secondaryMenuTitle = (
    <div className="d-flex align-items-center">
      <img src={`${BASE_URL}/static/images/menu/jurnal.svg`} alt="Icon jurnal" className="mr-2" />
      <span className="header-4 font-weight-bold text-primary">Jurnal</span>
    </div>
  );
  const utm = '?utm_source=website&utm_medium=header';
  return (
    <>
      <SecondaryHeader
        setMenu={setMenu}
        menu={data}
        lvl="lvl3"
        menuActiveLvl1="mainMenu"
        menuActiveLvl2="wawasanMenu"
        menuActiveLvl3=""
        toggleMenu={toggleMenu}
        header={secondaryMenuTitle}
      />
      <Container className="d-flex flex-column py-3">
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - jurnal -  home', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'jurnal -  home'
            })
          }}
          href={`${JURNAL_URL}/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <BsHouseDoorFill fontSize="20px" className="mr-2" />
              <span className="paragraph font-weight-bold">Beranda</span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - jurnal - jurnal', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'jurnal - jurnal'
            })
          }}
          href={`${JURNAL_URL}/journal${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Jurnal </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - jurnal - artikel', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'jurnal - artikel'
            })
          }}
          href={`${JURNAL_URL}/article${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Artikel </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
      </Container>
    </>
  );
};
JurnalMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
};
export default JurnalMenu;
