import React from 'react';
import Container from 'reactstrap/lib/Container';
import { MdLocationPin, MdPhone, MdMail } from 'react-icons/md';
import { RiCopyrightFill } from 'react-icons/ri';
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsYoutube,
  BsTwitter,
} from 'react-icons/bs';
import { isMobile } from 'react-device-detect';
import { withCommons } from '../../libraries/commons';
import { structuredEvent } from '../../libraries/tracker';
import DesktopFooter from './dektopFooter';
import MobileFooter from './mobileFooter';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const {
  BASE_URL, JURNAL_URL, PRO_URL, OC_URL,
} = publicRuntimeConfig;

const Footer = (props) => {
  const {
    darkMode = {},
    router: { route },
  } = props;
  const { toggle: isDarkMode = false } = darkMode;
  const logo = isDarkMode && route === '/premium/article' ? `${BASE_URL}/static/images/logo_white.svg` : `${BASE_URL}/static/images/logo.svg`;
  const logoKominfo = isDarkMode && route === '/premium/article' ? `${BASE_URL}/static/images/logo/kominfo-white-logo.png` : `${BASE_URL}/static/images/logo/kominfo-logo.png`;
  const textColor = isDarkMode && route === '/premium/article' ? 'text-white' : 'text-main';
  const bgColor = isDarkMode && route === '/premium/article' ? 'bg-black-light' : 'bg-light';
  const primaryTextColor = isDarkMode && route === '/premium/article' ? 'text-secondary' : 'text-primary';
  return (
    <>
      {isMobile && <div className="py-4 container"> <MobileFooter t={props.t} logo={logo} textColor={textColor} primaryTextColor={primaryTextColor} logoKominfo={logoKominfo} bgColor={bgColor} /></div>}
      <div className={`${isDarkMode && route === '/premium/article' ? 'bg-black-light' : 'bg-light'}`}>
        {!isMobile && <div className="py-4 container"> <DesktopFooter t={props.t} logo={logo} textColor={textColor} primaryTextColor={primaryTextColor} logoKominfo={logoKominfo} /></div>}

        <div className="bg-primary ">
          <Container className="d-flex align-items-center">
            <RiCopyrightFill fontSize="16px" className="text-white my-2 mr-2" />
            <span className="small font-weight-semibold text-white">
              {' '}
              {new Date().getFullYear()} Hak Cipta Milik Hukumonline.com
            </span>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Footer;
