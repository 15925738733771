import React from 'react';

import Head from 'next/head';

import { Row, Col, Button, Container } from 'reactstrap';

import * as Sentry from '@sentry/nextjs';
import NextErrorComponent from 'next/error';
import Layout from '../layouts/HomeLayout';
import { withCommons } from '../libraries/commons';

const NotFound = () => (
  <>
    <Head>
      <title>404 Content Not Found</title>
      <meta
        name="description"
        content="Halaman Tidak Ditemukan, Maaf, halaman yang dituju tidak tersedia. Mohon cek ulang tautan pada browser atau hubungi"
      />
      <meta name="keywords" content="hukum online" />
      <link rel="canonical" href="https://www.hukumonline.com/404/" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="404 Content Not Found" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="/static/images/404.png" />
      <meta property="og:url" content="https://www.hukumonline.com/404/" />
      <meta
        property="og:description"
        content="Halaman Tidak Ditemukan, Maaf, halaman yang dituju tidak tersedia. Mohon cek ulang tautan pada browser atau hubungi"
      />
    </Head>

    <Row className="py-5 d-flex align-items-center justify-content-center bg-hol-light">
      <Col md={5} className="mx-auto">
        <div className="d-flex flex-column align-center justify-content-center text-center">
          <div className="my-3">
            <img
              src="/static/images/404.png"
              className="img-fluid"
              alt="404 - Halaman tidak ditemukan"
            />
          </div>
          <div>
            <h1 className="header-3 text-primary font-weight-bold">
              Halaman tidak ditemukan
            </h1>
            <p className="paragraph text-muted font-weight-medium">
              Maaf, halaman yang dituju tidak tersedia. Mohon cek ulang tautan
              pada browser atau hubungi{' '}
              <a
                href="mailto:customer@hukumonline.com"
                className="body text-primary font-weight-medium"
              >
                customer@hukumonline.com
              </a>
            </p>
          </div>
          <div className="my-3">
            <Button
              color="primary"
              outline
              href="/"
              className="body font-weight-semibold"
            >
              Halaman Utama
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  </>
);

const FoundError = () => (
  <>
    <Head>
      <title>500 Internal Server Error</title>
      <meta name="description" content="500 Internal Server Error" />
      <meta name="keywords" content="hukum online" />
      <link rel="canonical" href="https://www.hukumonline.com/500/" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="500 Internal Server Error" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="/static/images/500.png" />
      <meta property="og:url" content="https://www.hukumonline.com/404/" />
      <meta property="og:description" content="500 Internal Server Error" />
    </Head>

    <Row className="py-5 d-flex align-items-center justify-content-center bg-hol-light">
      <Col md={5} className="mx-auto">
        <div className="d-flex flex-column align-center justify-content-center text-center">
          <div className="my-3">
            <img
              src="/static/images/500.png"
              className="img-fluid"
              alt="error_image"
            />
          </div>
          <div>
            <h1 className="header-3 text-primary font-weight-bold">
              Mohon maaf, halaman yang dituju sedang mengalami perbaikan
            </h1>
            <p className="paragraph text-muted font-weight-medium">
              Kami sedang bekerja untuk memperbaiki halaman ini agar dapat
              kembali beroperasi dengan normal.
            </p>
          </div>
          <div className="my-3">
            <Button
              color="primary"
              outline
              href="/"
              className="body font-weight-semibold"
            >
              Halaman Utama
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  </>
);

const ErrorPage = (props) => {
  const { statusCode } = props;
  // const pathname = router?.pathname ? router.pathname : null
  // Sentry.setTag('pathname', pathname)
  // Sentry.setTag('username', user_identity?.username)
  // Sentry.setTag('url', currentUrl)
  let imageCode;

  switch (statusCode) {
    case 404:
      // Sentry.captureMessage(404)
      imageCode = <NotFound />;
      break;
    case 401:
      // Sentry.captureMessage(401)
      imageCode = '401.jpg';
      break;
    case 429:
      // Sentry.captureMessage(429)
      imageCode = <FoundError />;
      break;
    case 502:
      // Sentry.captureMessage(502)
      imageCode = <FoundError />;
      break;
    default:
      // Sentry.captureMessage(statusCode)
      imageCode = <FoundError />;
      break;
  }

  return (
    <Layout {...props} meta_title={statusCode}>
      <div className="bg-hol-light">
        <Container>{imageCode}</Container>
      </div>
    </Layout>
  );
};

Error.getInitialProps = async (contextData) => {
  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  await Sentry.captureUnderscoreErrorException(contextData);

  // This will contain the status code of the response
  return NextErrorComponent.getInitialProps(contextData);
};

export default withCommons(ErrorPage);
