import React, { useRef, useEffect } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { structuredEvent } from '../../../../libraries/tracker';
import { useAdditionalMenuContext } from '../../../../context/additionalHeaderContext';

const SubMenuPro = ({ router }) => {
  const { activeMenu: active } = useAdditionalMenuContext();
  const wrapperRef = useRef(null);

  useEffect(() => {
    // Center the active item
    if (wrapperRef.current) {
      const activeItem = wrapperRef.current.querySelector(
        '.additional-menu-pro-active',
      );

      if (activeItem) {
        const wrapperWidth = wrapperRef.current.offsetWidth;
        const activeItemWidth = activeItem.offsetWidth;
        wrapperRef.current.scrollLeft =
          activeItem.offsetLeft - (wrapperWidth - activeItemWidth) / 2;
      }
    }
  }, [active]);

  return (
    <>
      <div className="additional-menu-pro-wrapper" ref={wrapperRef}>
        <a
          href="/home/"
          className={`additional-menu-pro ${
            active === 'home' ? 'additional-menu-pro-active' : ''
          }`}
          onClick={() =>
            structuredEvent('navbar', 'legal_analysis_home', '', '', '')
          }
        >
          Home
        </a>
        <a
          href="/c/legal-brief/"
          className={`additional-menu-pro ${
            router.asPath === '/c/legal-brief/'
              ? 'additional-menu-pro-active'
              : ''
          }`}
          onClick={() =>
            structuredEvent(
              'navbar',
              'click url - legal_analysis_ilb',
              '',
              '',
              '',
            )
          }
        >
          Legal Brief
        </a>
        <a
          href="/c/law-digest/"
          className={`additional-menu-pro ${
            router.asPath === '/c/law-digest/'
              ? 'additional-menu-pro-active'
              : ''
          }`}
          onClick={() =>
            structuredEvent(
              'navbar',
              'click url - legal_analysis_ild',
              '',
              '',
              '',
            )
          }
        >
          Law Digest
        </a>
        <a
          href="/c/law-review/"
          className={`additional-menu-pro ${
            router.asPath === '/c/law-review/'
              ? 'additional-menu-pro-active'
              : ''
          }`}
          onClick={() =>
            structuredEvent(
              'navbar',
              'click url - legal_analysis_mlr',
              '',
              '',
              '',
            )
          }
        >
          Law Review
        </a>
        <a
          href="/c/daily-update/"
          className={`additional-menu-pro ${
            router.asPath === '/c/daily-update/'
              ? 'additional-menu-pro-active'
              : ''
          }`}
          onClick={() =>
            structuredEvent(
              'navbar',
              'click url - legal_analysis_du',
              '',
              '',
              '',
            )
          }
        >
          Daily Updates
        </a>
        <a
          href="/regulation/"
          className={`additional-menu-pro ${
            router.asPath === '/regulation/' ? 'additional-menu-pro-active' : ''
          }`}
          onClick={() =>
            structuredEvent(
              'navbar',
              'click url - legal_analysis_translations',
              '',
              '',
              '',
            )
          }
        >
          Regulation Translations
        </a>
      </div>
    </>
  );
};

export default SubMenuPro;
