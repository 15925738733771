import React, { useMemo, useState, useRef, useEffect } from 'react';

import { Badge } from 'reactstrap';
import menuList from '../menu/menuList';

import { setCookie, getCookie } from '../../../libraries/session';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

function SubMenu({ menuType, activeMenu }) {
  const [isOpen, setIsOpen] = useState(false);
  const submenuRef = useRef(null);
  const [isHorizontalScroll, setIsHorizontalScroll] = useState(false);
  const [showRedDot, setShowRedDot] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const onScroll = () => {
    if (submenuRef.current?.scrollLeft > 60) {
      setIsHorizontalScroll(true);
    } else {
      setIsHorizontalScroll(false);
    }
  };
  useEffect(() => {
    const isShow = getCookie('showRedDotMenu');
    if (new Date().getDay() === 1 || !isShow) {
      setCookie('showRedDotMenu', '1');
      setShowRedDot(true);
    } else {
      setShowRedDot(isShow === '1');
    }
    if (submenuRef.current) {
      const container = submenuRef.current;
      const activeChild = container.querySelector('.submenu-active');

      if (activeChild) {
        const containerWidth = container.offsetWidth;
        const activeChildWidth = activeChild.offsetWidth;
        const activeChildLeft = activeChild.offsetLeft;
        const activeChildRight = activeChildLeft + activeChildWidth;
        const containerScrollLeft = container.scrollLeft;
        const containerScrollRight = containerScrollLeft + containerWidth;
        const targetScrollLeft =
          (activeChildLeft + activeChildRight - containerWidth) / 2;

        if (
          activeMenu !== 'klinik' &&
          activeMenu !== 'solusi' &&
          activeMenu !== 'event' &&
          (activeChildLeft > containerScrollLeft ||
            activeChildRight < containerScrollRight)
        ) {
          container.scrollLeft = targetScrollLeft;
        }
      }
    }
  }, []);

  const toggleDropdown = () => {
    setCookie('showRedDotMenu', '0');
    setShowRedDot(false);
    setIsOpen(!isOpen);
  };
  const menuTitle = isHorizontalScroll ? (
    <div className="icon-transition">{menuList[menuType].icon}</div>
  ) : (
    menuList[menuType].mainTitle
  );

  const menuDropdown = Object.keys(menuList).map((item, index) => {
    const isActive = menuType === item;
    const { snowPlow, ga4 } = menuList[item].tracker;
    return (
      <React.Fragment key={`dropdown-${index}`}>
        <a
          key
          href={menuList[item].link}
          className={`dropdown-item ${isActive ? 'dropdown-item-active' : ''}`}
          onClick={() => {
            structuredEvent(
              snowPlow.event,
              snowPlow.action_v,
              snowPlow.component,
              '',
            );
            pushGa4Generic({
              event: ga4.event,
              action: ga4.action,
              component: ga4.component_v,
            });
          }}
        >
          {menuList[item].iconDark}
          {menuList[item].mainTitle}
        </a>
      </React.Fragment>
    );
  });
  const horizontalScrollMenu = menuList[menuType].subMenu.map((item, index) => {
    const isActive = activeMenu == item.menu.toLowerCase();
    return (
      <React.Fragment key={`sideMenu-${index}`}>
        <a
          href={item.link}
          className={`submenu d-flex align-items-center ${
            isActive ? 'submenu-active' : ''
          }`}
          onClick={() => {
            structuredEvent(
              item.tracker.snowPlow.event,
              item.tracker.snowPlow.action,
              item.tracker.snowPlow.component,
              '',
              '',
            );
            pushGa4Generic(item.tracker.ga4);
          }}
        >
          {item.featureNew && <Badge className="mr-2  new-badge">New</Badge>}

          {item.menu}
        </a>
      </React.Fragment>
    );
  });

  return (
    <>
      <div className="menu-mobile">
        <div className="dropdown-mobile-wrapper">
          <button
            type="button"
            title="Drowndown Menu"
            aria-label="dropdown"
            onClick={() => {
              toggleDropdown();
              structuredEvent(
                'navbar',
                'click url - m_hamburger',
                'beranda',
                '',
              );
              pushGa4Generic({
                event: 'navbar',
                action: 'click url',
                component: 'm_hamburger',
              });
            }}
          >
            <div className="main-dropdown py-2 pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#ffffff"
                viewBox="0 0 256 256"
                className="mr-1"
              >
                <path d="M228,128a12,12,0,0,1-12,12H40a12,12,0,0,1,0-24H216A12,12,0,0,1,228,128ZM40,76H216a12,12,0,0,0,0-24H40a12,12,0,0,0,0,24ZM216,180H40a12,12,0,0,0,0,24H216a12,12,0,0,0,0-24Z" />
              </svg>
              <div
                className={`icon-transition ${
                  isHorizontalScroll ? 'active' : ''
                }`}
              >
                {menuType == 'pro' ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#ffffff"
                    viewBox="0 0 256 256"
                  >
                    <path d="M239.2,97.29a16,16,0,0,0-13.81-11L166,81.17,142.72,25.81h0a15.95,15.95,0,0,0-29.44,0L90.07,81.17,30.61,86.32a16,16,0,0,0-9.11,28.06L66.61,153.8,53.09,212.34a16,16,0,0,0,23.84,17.34l51-31,51.11,31a16,16,0,0,0,23.84-17.34l-13.51-58.6,45.1-39.36A16,16,0,0,0,239.2,97.29Zm-15.22,5-45.1,39.36a16,16,0,0,0-5.08,15.71L187.35,216v0l-51.07-31a15.9,15.9,0,0,0-16.54,0l-51,31h0L82.2,157.4a16,16,0,0,0-5.08-15.71L32,102.35a.37.37,0,0,1,0-.09l59.44-5.14a16,16,0,0,0,13.35-9.75L128,32.08l23.2,55.29a16,16,0,0,0,13.35,9.75L224,102.26S224,102.32,224,102.33Z" />
                  </svg>
                ) : (
                  menuList[menuType].icon
                )}
              </div>
              <div
                className={`text-transition ${
                  isHorizontalScroll ? 'active' : ''
                }`}
              >
                {menuList[menuType].mainTitle}
              </div>
              {showRedDot && <div className="notification-dot" />}
            </div>
          </button>
          <div
            className={`dropdown-items ${isOpen ? 'dropdown-items-open' : ''}`}
          >
            {menuDropdown}
          </div>
        </div>
        <div className="vl mx-2 " />
        <div ref={submenuRef} onScroll={onScroll} className="submenu-wrapper">
          {horizontalScrollMenu}
        </div>
      </div>
    </>
  );
}

export default SubMenu;
