import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { structuredEvent, pushGa4Generic } from '../../libraries/tracker';

import { getCookie, setCookieWithExpire } from '../../libraries/session';

import stylesheet from './buttonWorkspace.scss';
import { useCoachmarkV2Context } from '../coachMark/v2/CoachmarkV2Provider';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL } = publicRuntimeConfig;

export default function ButtonWorkspace() {
  const { setDashboardProCoachmarkTriggered } = useCoachmarkV2Context();
  // it will only appear once if user never click the button
  const [isDashboardProCoachmark, setIsDashboardProCoachmark] = useState(() => {
    const cookie = getCookie('dashboardProCoachmark');
    return cookie === undefined;
  });
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const handleSetCookieDashboardCoachmark = () => {
    setCookieWithExpire('dashboardProCoachmark', true, 300);
    setIsDashboardProCoachmark(false);
    setDashboardProCoachmarkTriggered(true);
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)',
  });

  if (!isClient) return <div />;
  if (!isDesktopOrLaptop) return <div />;

  return (
    <>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: stylesheet }}
      />
      <div
        style={{
          position: 'relative',
          zIndex: isDashboardProCoachmark ? 101 : 0,
        }}
      >
        <a
          href="https://pro.hukumonline.com/workspace/"
          className="button-workspace"
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - workspace',
              'workspace',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'click url',
              component: 'workspace',
            });
            handleSetCookieDashboardCoachmark();
          }}
        >
          <img
            src={`${BASE_URL}/static/images/icon-workspace.svg`}
            alt="workspace"
            title="workspace"
            width={24}
            height={24}
          />
          <span className="button-workspace__text">My Workspace</span>
        </a>

        {/* POPUP TOOLTIP WHEN USER NOT CLICK YET */}
        {isDashboardProCoachmark && (
          <div className="coachmark-workspace">
            <div className="position-relative">
              <div className="coachmark-workspace__title">
                Jelajahi My Workspace
              </div>
              <div className="coachmark-workspace__description">
                Jelajahi workspace dan personalisasi tampilan halaman Anda
              </div>
              <div className="coachmark-workspace__cta">
                <a
                  href="https://pro.hukumonline.com/workspace/"
                  target="_blank"
                  rel="noreferrer"
                  title="https://pro.hukumonline.com/workspace/"
                  onClick={() => handleSetCookieDashboardCoachmark()}
                  className="w-100"
                >
                  <Button
                    color="secondary"
                    size="sm"
                    block
                    style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
                  >
                    Jelajahi Sekarang
                  </Button>
                </a>
                <Button
                  size="sm"
                  color="primary"
                  style={{
                    color: '#F5F7F9',
                    padding: '0.5rem 1rem',
                  }}
                  onClick={() => handleSetCookieDashboardCoachmark()}
                >
                  Tutup
                </Button>
              </div>

              <img
                src={`${BASE_URL}/static/images/tooltip-tail.svg`}
                alt="tooltip-tail"
                style={{
                  position: 'absolute',
                  right: '-0.125rem',
                  top: '-1.3125rem',
                }}
              />
            </div>
          </div>
        )}
        {/* POPUP TOOLTIP WHEN USER NOT CLICK YET */}
      </div>
      {/* BACKGROUND WHEN POPUP TOOLTIP APPEAR */}
      {isDashboardProCoachmark && (
        <div className="coachmark-workspace__background" />
      )}
      {/* BACKGROUND WHEN POPUP TOOLTIP APPEAR */}
    </>
  );
}
