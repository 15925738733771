import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward } from 'react-icons/io5';
import { BsHouseDoorFill } from 'react-icons/bs';
import PropTypes from 'prop-types';
import SecondaryHeader from './secondaryHeader';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { BASE_URL } = publicRuntimeConfig;

const KlinikMenu = ({ setMenu, data, toggleMenu }) => {
  const secondaryMenuTitle = (
    <div className="d-flex align-items-center">
      <img
        src={`${BASE_URL}/static/images/menu/klinik.svg`}
        alt="Icon Klinik"
        className="mr-2"
      />
      <span className="header-4 font-weight-bold text-primary">Klinik</span>
    </div>
  );
  const utm = '';
  return (
    <>
      <SecondaryHeader
        setMenu={setMenu}
        menu={data}
        lvl="lvl3"
        menuActiveLvl1="mainMenu"
        menuActiveLvl2="wawasanMenu"
        menuActiveLvl3=""
        toggleMenu={toggleMenu}
        header={secondaryMenuTitle}
      />
      <Container className="d-flex flex-column py-3">
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - home ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - home',
            });
          }}
          href={`${BASE_URL}/klinik/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <BsHouseDoorFill fontSize="20px" className="mr-2" />
              <span className="paragraph font-weight-bold">Beranda</span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - tips hukum ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - tips hukum',
            });
          }}
          href={`${BASE_URL}/klinik/tips/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Tips Hukum{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - pidana ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - pidana',
            });
          }}
          href={`${BASE_URL}/klinik/pidana/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Pidana </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - keluarga ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - keluarga',
            });
          }}
          href={`${BASE_URL}/klinik/keluarga/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Keluarga </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - perdata ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - perdata',
            });
          }}
          href={`${BASE_URL}/klinik/perdata/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Perdata </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - kenegaraan ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - kenegaraan',
            });
          }}
          href={`${BASE_URL}/klinik/kenegaraan/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Kenegaraan{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - ilmu hukum ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - ilmu hukum',
            });
          }}
          href={`${BASE_URL}/klinik/ilmu-hukum/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Ilmu Hukum{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - ketenagakerjaan ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - ketenagakerjaan',
            });
          }}
          href={`${BASE_URL}/klinik/ketenagakerjaan/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Ketenagakerjaan{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - pertanahan & properti ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - pertanahan & properti',
            });
          }}
          href={`${BASE_URL}/klinik/pertanahan-properti/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Pertanahan dan Properti{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - perlindungan konsumen ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - perlindungan konsumen',
            });
          }}
          href={`${BASE_URL}/klinik/perlindungan-konsumen/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Perlindungan Konsumen
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - hak asasi manusia ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - hak asasi manusia',
            });
          }}
          href={`${BASE_URL}/klinik/hak-asasi-manusia/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Hak Asasi Manusia
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - kekayaan intelektual ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - kekayaan intelektual',
            });
          }}
          href={`${BASE_URL}/klinik/kekayaan-intelektual/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Kekayaan Intelektual
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - teknologi ',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - teknologi',
            });
          }}
          href={`${BASE_URL}/klinik/teknologi/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Teknologi</span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - klinik - startup & umkm',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'klinik - startup & umkm',
            });
          }}
          href={`${BASE_URL}/klinik/start-up-umkm/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Startup dan umkm{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
      </Container>
    </>
  );
};
KlinikMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
};
export default KlinikMenu;
