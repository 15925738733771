import React, { useEffect, useState, createContext, useReducer } from 'react';

import Container from 'reactstrap/lib/Container';
import { BsFillStarFill } from 'react-icons/bs';
import { AiFillAppstore } from 'react-icons/ai';
import { useMediaQuery } from 'react-responsive';

import jwt from 'jsonwebtoken';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { is } from 'core-js/core/object';
import stylesheet from './style.scss';
import { withCommons } from '../../libraries/commons';
import MenuDesktop from './menuDesktop';
import MenuMobile from './menuMobile';
import { structuredEvent, pushGa4Generic } from '../../libraries/tracker';
import { encodeBase64 } from '../../libraries/utils';
import { getJwt, getUser } from '../../libraries/auth';
// eslint-disable-next-line import/named
import { Landing } from '../../services/landing';
import SubBar from './subBar';
import ExpiredLabel from '../common/ExpiredLabel';
import NewExpiredLabel from '../common/NewExpiredLabel';
// import HeaderBanner from '../common/HeaderBanner';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL, PRO_URL } = publicRuntimeConfig;

const initUserDetail = {
  username: '',
  groups: '',
  subscription_expired_at: '',
  products: {},
  isAuthenticated: false,
  isPaidMember: false,
  isExpired: true,
  showExpired: false,
};
function mapStateToProps(state) {
  return state;
}

const updateStatusNotif = (status) => {
  localStorage.setItem('expiredNotif', status);
};

const paidPackage = [
  'member-standard',
  'member-professional',
  'member-professional-trial',
  'member-professional-plus',
  'member-professional-plus-trial',
];

const getUserFromJwt = () => {
  const token = getJwt() && typeof getJwt() !== 'undefined' ? getJwt() : '';
  if (token) {
    const decodedJWT = jwt.decode(token);
    const fullName = `${getUser().first_name} ${getUser().last_name}`;
    const isPaidMember =
      _.findIndex(paidPackage, (o) => o == getUser().groups) != -1;
    const today = moment(new Date());
    const expired = moment(getUser().subscription_expired_at);

    const diff = expired.diff(today, 'days');
    const isExpired = diff < 0;

    if (decodedJWT.unm) {
      return {
        username: decodedJWT.unm,
        groups: decodedJWT.aud ? decodedJWT.aud : '',
        fullName: fullName || '',
        subscription_expired_at: decodedJWT.sbx ? decodedJWT.sbx : '',
        products: decodedJWT.ups ? decodedJWT.ups : {},
        isAuthenticated: true,
        isPaidMember,
        isExpired,
      };
    }
  }
  return initUserDetail;
};
// To do pisahkan context di luar file
export const NavbarContext = createContext();
const navbarReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SEARCH_SUGGESTION':
      return {
        ...state,
        searchSuggestion: action.payload,
      };
  }
};
const HeaderLabel = ({ userDetail, menuType }) => {
  const YellowLabel = ({ link, label }) => (
    <div className=" bg-yellow d-flex align-items-center px-2">
      <a
        onClick={() => {
          structuredEvent('navbar', 'click url - berlangganan', 'beranda', '');
          pushGa4Generic({
            event: 'navbar',
            action: 'click url',
            component: 'berlangganan',
          });
        }}
        href={link}
      >
        <span className="body py-1 text-primary font-weight-medium">
          {label}
        </span>
        <BsFillStarFill fontSize="12px" className="text-primary ml-1" />{' '}
      </a>
    </div>
  );
  const LabelCatalog = ({ isHide }) => {
    const labelCat = (
      <a
        onClick={() => {
          structuredEvent(
            'navbar',
            'click url - katalog_produk',
            'beranda',
            '',
          );
          pushGa4Generic({
            event: 'navbar',
            action: 'link_click',
            component: 'katalog_produk',
          });
        }}
        href={`${BASE_URL}/produk/id/`}
        className="font-weight-medium text-white body px-2 py-1"
      >
        Katalog Produk
        <AiFillAppstore fontSize="18px" className="text-white ml-1" />
      </a>
    );
    return isHide ? null : labelCat;
  };

  if (!userDetail?.groups || !paidPackage.includes(userDetail?.groups)) {
    return (
      <>
        <LabelCatalog isHide={userDetail.groups} />
        <YellowLabel
          label="Berlangganan Pro"
          link={`${PRO_URL}/paket/?utm_source=website&utm_medium=header`}
        />
      </>
    );
  }
  return null;
};
const Header = (props) => {
  const { router, menuType = '', activeMenu = '' } = props;

  const mainPath = router?.pathname.split('/')[1];
  const returnURL = encodeBase64(props.currentUrl);
  const [popularSearch, setPopularSearch] = useState([]);
  const [userDetail, setUserDetail] = useState(initUserDetail);
  const pageWithRenewalReminder = ['pro', 'data', 'landing', 'search'];
  const pageWithHeaderBannder = ['pro', 'data'];

  const cta360Filter = ['member-standard', 'member-professional'];
  const showLink360 = cta360Filter.includes(userDetail.groups);

  const [navbarState, dispatch] = useReducer(navbarReducer, {
    searchSuggestion: [],
  });
  const navbarValue = {
    searchSuggestions: navbarState.searchSuggestion,
    setSearchSuggestions: (payload) =>
      dispatch({ type: 'SET_SEARCH_SUGGESTION', payload }),
  };
  //
  useEffect(() => {
    // const promise = Landing.popularSearch()
    //   .then((res) => {
    //     setPopularSearch(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    setUserDetail(getUserFromJwt());
  }, []);

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const menu = isMobile ? (
    <MenuMobile
      {...props}
      returnURL={returnURL}
      popularSearch={popularSearch}
      isMobile={isMobile}
      userDetail={userDetail}
    />
  ) : (
    <MenuDesktop
      {...props}
      returnURL={returnURL}
      popularSearch={popularSearch}
      userDetail={userDetail}
      isMobile={isMobile}
    />
  );
  return (
    <NavbarContext.Provider value={navbarValue}>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
      <div className="bg-hol-primary-500 d-none d-lg-block">
        <Container className="d-flex text-white justify-content-end">
          {!!userDetail?.username && (
            <a
              onClick={() => {
                // structuredEvent(
                //   'navbar',
                //   'click url  - katalog produk',
                //   'beranda',
                //   '',
                // );
                // pushGa4Generic({
                //   event: 'navbar',
                //   action: 'link_click',
                //   component: 'katalog produk',
                // });
              }}
              href={`${PRO_URL}/workspace/profile/`}
              className="font-weight-medium text-white body px-2 d-flex align-items-center text-decoration-none"
            >
              <img
                src={`${BASE_URL}/static/images/icon-user.svg`}
                alt="Icon User"
                className="mr-1"
                width={18}
                height={18}
              />
              {userDetail?.username?.replace('-', ' ')}
            </a>
          )}
          {userDetail && (
            <HeaderLabel userDetail={userDetail} menuType={menuType} />
          )}
        </Container>
      </div>

      <div className="bg-hol-blue d-lg-block d-none" id="mobile">
        <div className="py-3">
          <MenuDesktop
            {...props}
            returnURL={returnURL}
            popularSearch={popularSearch}
            userDetail={userDetail}
            isMobile={isMobile}
          />
        </div>
      </div>
      <div className=" d-lg-none d-block" id="mobile">
        {/* //desktop */}
        <MenuMobile
          {...props}
          returnURL={returnURL}
          popularSearch={popularSearch}
          isMobile={isMobile}
          menuType={menuType}
          activeMenu={activeMenu}
          userDetail={userDetail}
        />
      </div>
      {props.subMenu ? (
        <div className="d-md-block d-none">
          <SubBar {...props} />
        </div>
      ) : null}

      {/* {userDetail.subscription_expired_at && (
      <ExpiredLabel
        {...props}
        expiredDate={userDetail.subscription_expired_at}
      />
      )} */}
      {pageWithRenewalReminder.includes(mainPath) && (
        <NewExpiredLabel {...props} />
      )}
      {/* {pageWithHeaderBannder.includes(mainPath) && !paidPackage.includes(userDetail.groups) && <HeaderBanner/>} */}
    </NavbarContext.Provider>
  );
};

Header.propTypes = {
  currentUrl: PropTypes.string,
  subMenu: PropTypes.string,
};
Header.defaultProps = {
  currentUrl: '',
  subMenu: '',
};
export default connect(mapStateToProps)(Header);
