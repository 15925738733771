import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward } from 'react-icons/io5';
import { BsHouseDoorFill } from 'react-icons/bs';
import PropTypes from 'prop-types';
import SecondaryHeader from './secondaryHeader';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL } = publicRuntimeConfig;

const BeritaMenu = ({ setMenu, data, toggleMenu }) => {
  const secondaryMenuTitle = (
    <div className="d-flex align-items-center">
      <img
        src={`${BASE_URL}/static/images/menu/berita.svg`}
        alt="Icon Berita"
        className="mr-2"
      />
      <span className="header-4 font-weight-bold text-primary">Berita</span>
    </div>
  );
  const utm = '';
  return (
    <>
      <SecondaryHeader
        setMenu={setMenu}
        menu={data}
        lvl="lvl3"
        menuActiveLvl1="mainMenu"
        menuActiveLvl2="wawasanMenu"
        menuActiveLvl3=""
        toggleMenu={toggleMenu}
        header={secondaryMenuTitle}
      />
      <Container className="d-flex flex-column py-3">
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - home',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Home',
            });
          }}
          href={`${BASE_URL}/berita/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <BsHouseDoorFill fontSize="20px" className="mr-2" />
              <span className="paragraph font-weight-bold">Beranda</span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - utama',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Utama',
            });
          }}
          href={`${BASE_URL}/berita/utama/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Utama </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - terbaru',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Terbaru',
            });
          }}
          href={`${BASE_URL}/berita/terbaru/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Terbaru </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - kolom',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Kolom',
            });
          }}
          href={`${BASE_URL}/berita/kolom/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Kolom </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - foto',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Foto',
            });
          }}
          href={`${BASE_URL}/berita/foto/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Foto </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - jeda',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Jeda',
            });
          }}
          href={`${BASE_URL}/berita/jeda/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Jeda </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - tajuk',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Tajuk',
            });
          }}
          href={`${BASE_URL}/berita/tajuk/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Tajuk </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - profil',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Profil',
            });
          }}
          href={`${BASE_URL}/berita/profil/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Profil </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - isu hangat',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Isu Hangat',
            });
          }}
          href={`${BASE_URL}/berita/isu-hangat/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Isu Hangat{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - pojok akpi',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Pojok AKPI',
            });
          }}
          href={`${BASE_URL}/berita/akpi/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Pojok AKPI{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - pojok ini',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita - Pojok INI',
            });
          }}
          href={`${BASE_URL}/berita/ini/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Pojok INI </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>

        {/* <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - pojok peradi',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'Berita -Pojok Peradi',
            });
          }}
          href={`${BASE_URL}/berita/peradi/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Pojok PERADI{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a> */}
        {/* <a
          onClick={() => {
            structuredEvent('navbar', 'click url - berita - pojok mpr-ri', 'beranda', '', '');
          }}
          href={`${BASE_URL}/berita/pojok-mprri/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Pojok MPR-RI </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a> */}
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - pojok kuhp',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'berita - pojok kuhp',
            });
          }}
          href={`${BASE_URL}/berita/pojok-kuhp/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Pojok KUHP{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - Kabar Kampus',
              'beranda',
              '',
              '',
            );
          }}
          href={`${BASE_URL}/berita/kabar-kampus/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Kabar Kampus
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent(
              'navbar',
              'click url - berita - surat pembaca',
              'beranda',
              '',
              '',
            );
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'berita - surat pembaca',
            });
          }}
          href={`${BASE_URL}/berita/surat-pembaca/${utm}`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">
                Surat Pembaca{' '}
              </span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
      </Container>
    </>
  );
};
BeritaMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
};
export default BeritaMenu;
