import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward } from 'react-icons/io5';
import { BsHouseDoorFill } from 'react-icons/bs';
import { AiOutlineFolder } from 'react-icons/ai';
import PropTypes from 'prop-types';
import SecondaryHeader from './secondaryHeader';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { BASE_URL } = publicRuntimeConfig;

const PusatDataMenu = ({ setMenu, data, toggleMenu }) => {
  const secondaryMenuTitle = (
    <div className="d-flex align-items-center">
      <AiOutlineFolder fontSize="20px" className="mr-2" />
      <span className="header-4 font-weight-bold text-primary">Pusat Data</span>
    </div>
  );
  return (
    <>
      <SecondaryHeader
        setMenu={setMenu}
        menu={data}
        lvl="lvl3"
        menuActiveLvl1="mainMenu"
        menuActiveLvl2="proMenu"
        menuActiveLvl3=""
        toggleMenu={toggleMenu}
        header={secondaryMenuTitle}
      />
      <Container className="d-flex flex-column py-3">
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - pusat data - home', 'beranda', '', '');
          }}
          href={`${BASE_URL}/pusatdata/`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <BsHouseDoorFill fontSize="20px" className="mr-2" />
              <span className="paragraph font-weight-bold">Beranda</span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - pusat data - peraturan pusat', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'pusat data - peraturan pusat'
            })
          }}
          href={`${BASE_URL}/pusatdata/peraturan-pusat/`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Peraturan Pusat</span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - pusat data - peraturan daerah', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'pusat data - peraturan daerah'
            })
          }}
          href={`${BASE_URL}/pusatdata/peraturan-daerah/`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Peraturan Daerah</span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - pusat data - putusan', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'pusat data - putusan'
            })
          }}
          href={`${BASE_URL}/pusatdata/putusan/`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Putusan</span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
        <a
          onClick={() => {
            structuredEvent('navbar', 'click url - pusat data - precedent', 'beranda', '', '');
            pushGa4Generic({
              event: 'Navbar',
              action: 'link_click',
              component: 'pusat data - precedent'
            })
          }}
          href={`${BASE_URL}/pusatdata/precedent/`}
          className="py-3"
        >
          <div className="d-flex flex-row text-main justify-content-between">
            <div className="d-flex align-items-center mr-2">
              <span className="paragraph font-weight-semibold">Precedent</span>
            </div>
            <div className=" flex-shrink-1 ml-3 align-items-center d-flex">
              <IoChevronForward fontSize="16px" className="mx-2 text-main" />
            </div>
          </div>
        </a>
      </Container>
    </>
  );
};
PusatDataMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
};
export default PusatDataMenu;
