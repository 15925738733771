import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import Moment from 'react-moment';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';

moment.locale('id');
const ExpiredLabel = (props) => {
  const { t, i18n } = props;
  moment.locale(i18n.language);
  const [show, setShow] = useState(false);
  const [expiredStatus, setexpiredStatus] = useState(false);

  const expiredDate = moment(props.expiredDate, 'YYYY-MM-DD').format(
    'd MMMM YYYY',
  );

  const cutOffDate = moment(props.expiredDate).add(12, 'days');

  const emailCustomer = (
    <a
      href="mailto:customer@hukumonline.com"
      className={`font-weight-bold ${expiredStatus && 'text-white'}`}
    >
      customer@hukumonline.com
    </a>
  );

  const notif = (
    <>
      <span>
        {t('notif expire info')}&nbsp;
        <strong>
          {' '}
          <Moment
            locale={i18n.language}
            format="D MMMM YYYY"
            className="font-weight-bold"
          >
            {props.expiredDate}
          </Moment>
        </strong>
        . {t('notif expire renew')}
      </span>
      <span>
        {t('further information')} {emailCustomer} {t('contact soon notif')}
      </span>
    </>
  );
  const expiredNotif = (
    <>
      <span>
        {t('notif expired')}&nbsp;
        <strong>
          {' '}
          <Moment
            locale={i18n.language}
            format="D MMMM YYYY"
            className="font-weight-bold"
          >
            {props.expiredDate}
          </Moment>
        </strong>
        .
      </span>

      <span>
        {t('notif expired renew')}
        &nbsp;
        {emailCustomer}
        &nbsp;
        {t('contact soon')}
      </span>
      <span>
        {t('notif to renew')}&nbsp;
        <strong>{cutOffDate.format('d MMMM YYYY')}</strong>&nbsp;
        {t('no access notif')}&nbsp;
      </span>
    </>
  );
  const today = moment(new Date());
  const expired = moment(props.expiredDate);

  const diff = expired.diff(today, 'days');
  // const diff = -1;

  useEffect(() => {
    let statusNotif = 'notActive';
    if (_.includes([14, 30, 60], diff)) {
      localStorage.setItem('expiredNotif', 'active');
    }
    if (!localStorage.getItem('expiredNotif')) {
      localStorage.setItem('expiredNotif', 'notActive');
    } else {
      statusNotif = localStorage.getItem('expiredNotif');
    }

    if (diff < 0) {
      localStorage.setItem('expiredNotif', 'active');
      setexpiredStatus(true);
      setShow(true);
    } else if (diff <= 14 && statusNotif != 'closed') {
      localStorage.setItem('expiredNotif', 'active');
      setShow(true);
    } else if (diff <= 30 && statusNotif != 'closed') {
      localStorage.setItem('expiredNotif', 'active');
      setShow(true);
    } else if (diff <= 60 && statusNotif != 'closed') {
      localStorage.setItem('expiredNotif', 'active');
      setShow(true);
    }
  }, []);

  const closeNotif = () => {
    localStorage.setItem('expiredNotif', 'closed');
    setShow(false);
  };

  const notifLabel = (
    <div className={` ${expiredStatus ? 'bg-danger' : 'bg-secondary'} py-2`}>
      <Container
        className={`${
          expiredStatus ? 'text-white' : 'text-primary'
        } d-flex flex-row justify-content-between`}
      >
        <div className="d-flex flex-column">
          <span>
            <strong>{t('greeting notif')}</strong>
          </span>
          {expiredStatus ? expiredNotif : notif}
        </div>
        {!expiredStatus && (
          <div>
            <a onClick={() => closeNotif()}>
              {' '}
              <FontAwesome name="times" className="mr-2" />
            </a>
          </div>
        )}
      </Container>
    </div>
  );
  return show ? notifLabel : null;
};

ExpiredLabel.propTypes = {};

export default ExpiredLabel;
