import { getData } from '../libraries/request';

async function getAutocomplete(keyword, jwt) {
  const result = await getData(`/v3/autocomplete?q=${keyword}`, jwt);
  return result;
}

module.exports = {
  getAutocomplete,
};
