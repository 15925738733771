import React, { useRef, useEffect } from 'react';
import menuList from '../menu/menuList';
import { structuredEvent, pushGa4Generic } from '../../../libraries/tracker';
import { useAdditionalMenuContext } from '../../../context/additionalHeaderContext';

function AdditionalHeaderButton({ menuType, activeMenu }) {
  const { activeMenu: active } = useAdditionalMenuContext();
  const wrapperRef = useRef(null);

  useEffect(() => {
    // Center the active item
    if (wrapperRef.current && active) {
      const activeItem = wrapperRef.current.querySelector('.active');
      if (activeItem) {
        const wrapperWidth = wrapperRef.current.offsetWidth;
        const activeItemWidth = activeItem.offsetWidth;
        wrapperRef.current.scrollLeft =
          activeItem.offsetLeft - (wrapperWidth - activeItemWidth) / 2;
      }
    }
  }, [active]);

  let additionalHeaderList = [];

  menuList[menuType].subMenu &&
    menuList[menuType].subMenu.forEach((element) => {
      if (element.menu.toLowerCase() === activeMenu) {
        additionalHeaderList = element.subMenu;
      }
    });

  const additionalHeaderItem =
    additionalHeaderList &&
    additionalHeaderList.map((item, index) => {
      const isActive = active
        ? active.toLowerCase().replace('-', '') ===
          item.menu.toLowerCase().replace('-', '')
        : false;
      return (
        <a
          key={index}
          href={item.link}
          onClick={() => {
            structuredEvent(
              item.tracker.snowPlow.event,
              item.tracker.snowPlow.action,
              item.tracker.snowPlow.component,
              '',
              index + 1,
            );
            pushGa4Generic(item.tracker.ga4);
          }}
          className={`additional-menu-item ${
            active && isActive ? 'active' : ''
          }`}
        >
          {item.menu}
        </a>
      );
    });

  return additionalHeaderList ? (
    <div className="additional-menu-wrapper" ref={wrapperRef}>
      {additionalHeaderItem}
    </div>
  ) : (
    ''
  );
}

export default AdditionalHeaderButton;
