import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  IoClose,
  IoPersonCircle,
  IoPersonCircleOutline,
  IoChevronDownOutline,
  IoCheckmarkOutline,
} from 'react-icons/io5';
import { AiFillAppstore, AiOutlineMenu } from 'react-icons/ai';
import {
  BsSearch,
  BsArrowLeft,
  BsBoxArrowInRight,
  BsFillStarFill,
} from 'react-icons/bs';
import Container from 'reactstrap/lib/Container';
import PropTypes from 'prop-types';
import { Form } from 'reactstrap';
import { useRouter } from 'next/router';
import { isPaidMember } from '../../libraries/memberCheck';
import MainMenu from './menu/mainMenu';
import ProMenu from './menu/proMenu';
import PusatDataMenu from './menu/pusatDataMenu';
import SolusiMenu from './menu/solusiMenu';
import WawasanMenu from './menu/wawasanMenu';
import KlinikMenu from './menu/klinikMenu';
import BeritaMenu from './menu/beritaMenu';
import JurnalMenu from './menu/jurnalMenu';
import EventMenu from './menu/eventMenu';
import RankingMenu from './menu/rankingMenu';
import SearchMenu from './menu/searchMenu';
import SearchSuggestMenu from './menu/searchSuggestMenu';
import PersonalisasiMenu from './menu/personalisasiMenu';
import BahasaMenu from './menu/bahasaMenu';
import { pushGa4Generic, structuredEvent } from '../../libraries/tracker';
import DesktopSearchSuggest from './menu/desktopSearchSuggest';
import stylescheet from './menuMobile.scss';
import { searchOption } from './menuDesktop';
import MainItemList from './mobile/mainItem';
import SubMenuItem from './mobile/subMenuItem';
import SubMenuPro from './subBar/sub/pro';
import SubMenu from './mobile/subMenuItem';
import AdditionalHeaderButton from './mobile/additionalHeaderButton';
import { LanguageDropdown } from './LanguageDropdown';
import { NavbarContext } from '.';
import { ButtonNotification } from './buttonNotification';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { IDENTITY_URL, PRO_URL, BASE_URL, SEARCH_URL } = publicRuntimeConfig;

const paidPackage = [
  'member-standard',
  'member-professional',
  'member-lite',
  'member-lite-trial',
  'member-professional-trial',
  'member-professional-plus',
  'member-professional-plus-trial',
];
const menuState = {
  isOpen: {
    lvl1: false,
    lvl2: false,
    lvl3: false,
  },
  menuActiveLvl1: '',
  menuActiveLvl2: '',
  menuActiveLvl3: '',
};

const menuSearchState = {
  isOpen: {
    lvl1: true,
    lvl2: false,
    lvl3: false,
  },
  menuActiveLvl1: 'searchMenu',
  menuActiveLvl2: '',
  menuActiveLvl3: '',
};

const menuSearchSuggestState = {
  isOpen: {
    lvl1: true,
    lvl2: false,
    lvl3: false,
  },
  menuActiveLvl1: 'searchSuggestMenu',
  menuActiveLvl2: '',
  menuActiveLvl3: '',
};

const mapSearchOption = {
  Semua: 'all',
  Analisis: 'analyses',
  Peraturan: 'regulations',
  Putusan: 'decisions',
  'Legal Intelligence': 'liu',
  Berita: 'news',
  Klinik: 'clinics',
  Events: 'events',
};

const toggleMenu = (state, data, setMenu) => {
  const payload = {
    ...state,
    ...state,
    isOpen: {
      ...state.isOpen,
      [data.name]: data.isOpen,
    },
    menuActiveLvl1: data.menuActiveLvl1,
    menuActiveLvl2: data.menuActiveLvl2,
    menuActiveLvl3: data.menuActiveLvl3,
  };
  setMenu(payload);
};

const PrimaryHeader = ({ setMenu }) => (
  <>
    <Container className="py-3 border-bottom">
      <div className="d-flex align-items-center justify-content-between">
        <img
          src="/static/images/logo/hol-main-blue.png"
          alt="Logo Hukumonline"
          className="mini-logo"
        />
        <IoClose
          onClick={() => setMenu(menuState)}
          fontSize="30px"
          className="text-main"
        />{' '}
      </div>
    </Container>
  </>
);
const PersonalisasiHeader = ({ setMenu }) => (
  <>
    <Container className="py-3 border-bottom">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-row align-items-center">
          <IoPersonCircleOutline
            fontSize="24px"
            className="text-primary mr-2"
          />{' '}
          <span className="header-4 font-weight-semibold text-primary">
            Personalisasi
          </span>
        </div>
        <IoClose
          onClick={() => setMenu(menuState)}
          fontSize="30px"
          className="text-main"
        />{' '}
      </div>
    </Container>
  </>
);
const SearchHeader = ({
  setMenu,
  valueSearch,
  valueSearch1,
  setvalueSearch1,
  userDetail,
  currentOption,
  setCurrentOption,
}) => {
  const [formSearch, setFormSearch] = useState(valueSearch);
  const [openSearchOption, setOpenSearchOption] = useState(false);

  const router = useRouter();
  const { searchSuggestions } = useContext(NavbarContext);

  const handleChange = (e) => {
    setvalueSearch1(e.target.value);
  };

  const handleMenuSearch = (e) => {
    if (valueSearch1.length > 0) {
      setMenu(menuSearchSuggestState);
    } else {
      setMenu(menuSearchState);
    }
  };

  useEffect(() => {
    handleMenuSearch();
  }, [valueSearch1]);

  const handleSubmit = ({
    isSuggestions = false,
    selectedSuggestions = '',
    index = 0,
  } = {}) => {
    // p=0&q=uu+cipta+kerja&l=10&o=desc&s=relevancy&language=&year=&input=uu&pos=0&recoms=12

    const params = {
      p: 0,
      q: isSuggestions ? selectedSuggestions : valueSearch1,
      l: 10,
      o: 'desc',
      s: 'relevancy',
      language: '',
      year: '',
      input: valueSearch1,
      pos: index,
      recoms: searchSuggestions.length,
    };

    structuredEvent(
      'navbar_search_click',
      'button - button search navbar',
      '',
      '',
    );

    pushGa4Generic({
      event: 'navbar_search_click',
      action: 'button',
      component: 'button search navbar',
    });

    router.push({
      pathname: `${SEARCH_URL}/search/${mapSearchOption[currentOption]}/`,
      query: params,
    });

    // window.location.replace(url);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode == 9) {
      handleSubmit();
    }
    if (e.keyCode === 10) {
      handleSubmit();
    }
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: stylescheet }} />
      <Container className="py-3 border-bottom">
        <div className="d-flex align-items-center">
          <BsArrowLeft
            onClick={() => setMenu(menuState)}
            fontSize="30px"
            className="text-main min-w-30"
          />{' '}
          <div className="w-100">
            <form onKeyUp={handleKeyDown}>
              <input
                type="text"
                className="form-control border-0 ml-3"
                aria-describedby="Cari Peraturan"
                placeholder="Cari peraturan, artikel, dan lainnya..."
                onChange={handleChange}
                value={valueSearch1}
                autoFocus
              />
            </form>
          </div>
        </div>
        <div className="search-option-mobile-wrapper">
          <div
            className="search-option-mobile"
            onClick={() => setOpenSearchOption(!openSearchOption)}
          >
            <div className="d-flex align-items-center">{currentOption}</div>
            <div className="d-flex align-items-center">
              <IoChevronDownOutline fontSize="20px" />
            </div>
            {openSearchOption && (
              <div className="search-option-mobile-list">
                {searchOption.map((item, index) => (
                  <div
                    className="d-flex align-items-center item"
                    key={index}
                    onClick={() => {
                      setCurrentOption(item);
                      setOpenSearchOption(false);
                    }}
                  >
                    {item}
                    {item === currentOption && (
                      <IoCheckmarkOutline
                        fontSize="16px"
                        className="text-primary"
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="search-option-button">
            <div
              className="d-flex align-items-center"
              onClick={() => handleSubmit()}
            >
              Cari
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

PrimaryHeader.propTypes = {
  setMenu: PropTypes.func.isRequired,
};
MainMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
  returnURL: PropTypes.string.isRequired,
};

const collectionMenu = {
  lvl1: {
    mainMenu: (state, setMenu, props = '') => (
      <MainMenu
        currentUrl={props.returnURL}
        data={state}
        toggleMenu={toggleMenu}
        setMenu={setMenu}
        t={props.t}
      />
    ),
    searchMenu: (state, setMenu, props = '') => (
      <SearchMenu
        currentUrl={props.returnURL}
        popularSearch={props.popularSearch}
        data={state}
        userDetail={props.userDetail}
        toggleMenu={toggleMenu}
        setMenu={setMenu}
        t={props.t}
      />
    ),
    searchSuggestMenu: (
      state,
      setMenu,
      props = '',
      valueSearch1,
      currentOption,
      handleSubmit,
    ) => (
      <div className="mt-4 ml-4">
        <DesktopSearchSuggest
          query={valueSearch1}
          searchOption={currentOption}
          t={props.t}
          handleSubmit={handleSubmit}
        />
      </div>
    ),
    personalisasiMenu: (state, setMenu, props) => (
      <PersonalisasiMenu
        {...props}
        data={state}
        toggleMenu={toggleMenu}
        setMenu={setMenu}
        t={props.t}
      />
    ),
  },
  lvl2: {
    proMenu: (state, setMenu, props) => (
      <ProMenu
        data={state}
        toggleMenu={toggleMenu}
        setMenu={setMenu}
        t={props.t}
      />
    ),
    solusiMenu: (state, setMenu, props) => (
      <SolusiMenu
        data={state}
        toggleMenu={toggleMenu}
        setMenu={setMenu}
        t={props.t}
      />
    ),
    bahasaMenu: (state, setMenu, props) => (
      <BahasaMenu
        {...props}
        data={state}
        toggleMenu={toggleMenu}
        setMenu={setMenu}
        t={props.t}
      />
    ),
    wawasanMenu: (state, setMenu, props) => (
      <WawasanMenu
        {...props}
        data={state}
        toggleMenu={toggleMenu}
        setMenu={setMenu}
        t={props.t}
      />
    ),
  },
  lvl3: {
    pusatDataMenu: (state, setMenu) => (
      <PusatDataMenu data={state} toggleMenu={toggleMenu} setMenu={setMenu} />
    ),
    klinikMenu: (state, setMenu) => (
      <KlinikMenu data={state} toggleMenu={toggleMenu} setMenu={setMenu} />
    ),
    beritaMenu: (state, setMenu) => (
      <BeritaMenu data={state} toggleMenu={toggleMenu} setMenu={setMenu} />
    ),
    jurnalMenu: (state, setMenu) => (
      <JurnalMenu data={state} toggleMenu={toggleMenu} setMenu={setMenu} />
    ),
    eventMenu: (state, setMenu) => (
      <EventMenu data={state} toggleMenu={toggleMenu} setMenu={setMenu} />
    ),
    rankingMenu: (state, setMenu) => (
      <RankingMenu data={state} toggleMenu={toggleMenu} setMenu={setMenu} />
    ),
  },
};

const HeaderLabel = ({ userDetail, menuType }) => {
  const YellowLabel = ({ link, label }) => (
    <div className=" bg-yellow d-flex align-items-center px-2 py-1">
      <a
        onClick={() => {
          structuredEvent('navbar', 'click url - berlangganan', 'beranda', '');
          pushGa4Generic({
            event: 'navbar',
            action: 'click url',
            component: 'Berlangganan',
          });
        }}
        href={link}
      >
        <span className="body py-1 text-primary font-weight-medium">
          {label}
        </span>
        <BsFillStarFill fontSize="12px" className="text-primary ml-1 mb-1" />{' '}
      </a>
    </div>
  );
  const LabelCatalog = ({ isHide }) => {
    const labelCat = (
      <a
        onClick={() => {
          structuredEvent(
            'navbar',
            'click url - katalog_produk',
            'beranda',
            '',
          );
          pushGa4Generic({
            event: 'navbar',
            action: 'click url',
            component: 'katalog produk',
          });
        }}
        href={`${BASE_URL}/produk/id`}
        className="font-weight-medium text-white label-product px-2 py-1 d-flex align-items-center"
      >
        katalog Produk
        <AiFillAppstore fontSize="16px" className="text-white ml-1" />
      </a>
    );
    return isHide ? null : labelCat;
  };
  const LabelWorkspace = ({ isHide }) => {
    const labelCat = (
      <a
        onClick={() => {
          structuredEvent(
            'navbar_my_workspace',
            'link - link my workspace',
            '',
            '',
          );
          pushGa4Generic({
            event: 'navbar',
            action: 'link_click',
            component: 'katalog_produk',
          });
        }}
        href="https://pro.hukumonline.com/workspace/"
        className="font-weight-medium d-flex align-items-center text-secondary px-3 py-1 workspace-button __navbar_my_workspace"
      >
        <img
          src={`${BASE_URL}/static/images/icon-workspace.svg`}
          alt="workspace"
          title="workspace"
          width={16}
          height={16}
          className="mr-2"
        />
        My Workspace
      </a>
    );
    return isHide ? null : labelCat;
  };

  if (!userDetail?.groups || !paidPackage.includes(userDetail?.groups)) {
    return (
      <>
        <LabelCatalog />
        <YellowLabel
          label="Berlangganan Pro"
          link={`${PRO_URL}/paket/?utm_source=website&utm_medium=header`}
        />
      </>
    );
  }
  if (!userDetail?.groups || paidPackage.includes(userDetail?.groups)) {
    return (
      <>
        <LabelCatalog />
        <LabelWorkspace />
      </>
    );
  }
  return null;
};

const MenuMobile = (props) => {
  const { userDetail, t, showLink360, menuType, activeMenu, returnURL } = props;

  const valueSearch =
    (props.router && props.router.query && props.router.query.q) || '';
  const [menu, setMenu] = useState(menuState);
  const [valueSearch1, setvalueSearch1] = useState(valueSearch);
  const [currentOption, setCurrentOption] = useState('Semua');
  const [isActive, setIsActive] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const { searchSuggestions } = useContext(NavbarContext);
  const router = useRouter();

  const handleSubmit = ({
    isSuggestions = false,
    selectedSuggestions = '',
    index = 0,
  } = {}) => {
    // p=0&q=uu+cipta+kerja&l=10&o=desc&s=relevancy&language=&year=&input=uu&pos=0&recoms=12

    const params = {
      p: 0,
      q: isSuggestions ? selectedSuggestions : valueSearch1,
      l: 10,
      o: 'desc',
      s: 'relevancy',
      language: '',
      year: '',
      input: valueSearch1,
      pos: index,
      recoms: searchSuggestions.length,
    };

    structuredEvent(
      'navbar_search_click',
      'button - button search navbar',
      '',
      '',
    );

    pushGa4Generic({
      event: 'navbar_search_click',
      action: 'button',
      component: 'button search navbar',
    });

    router.push({
      pathname: `${SEARCH_URL}/search/${mapSearchOption[currentOption]}/`,
      query: params,
    });
  };

  const handleIconProfileClick = () => {
    if (userDetail.isAuthenticated) {
      window.location.href = 'https://id.hukumonline.com/';
    } else {
      setIsActive(!isActive);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 30);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const headerClass = visible
    ? 'sticky-header show-sticky '
    : 'sticky-header hide-sticky ';
  const logoHolpro = {
    src: '/static/images/pro/hol-pro-white.png',
    href: 'https://pro.hukumonline.com/?utm_source=website&utm_medium=header',
  };
  const logoHol = {
    src: 'https://images.hukumonline.com/website-assets/navbar/logo-hol.png',
    href: 'https://www.hukumonline.com',
  };
  const logo = isPaidMember(props.userDetail.groups) ? logoHolpro : logoHol;

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: stylescheet }} />

      <div className="product-header ">
        <HeaderLabel userDetail={userDetail} menuType={menuType} />
      </div>
      <div style={{ height: prevScrollPos < 20 ? '0px' : '150px' }} />
      <header
        className={`header-mobile ${headerClass} ${
          prevScrollPos < 20 ? '' : 'position-fixed'
        }`}
      >
        <div className="header-main ">
          <div className="d-flex align-items-center">
            <a href={BASE_URL}>
              <img
                src={logo.src}
                alt="Logo Hukumonline"
                className="main-logo"
              />
            </a>
            {/* {menuType === 'pro' && userDetail.isAuthenticated && (
              <LanguageDropdown {...props} />
            )} */}
          </div>

          <div className="header-icon-wrapper">
            <button
              aria-label="Notification"
              title="Notification"
              type="button"
              onClick={() => {
                toggleMenu(
                  menu,
                  {
                    name: 'lvl1',
                    isOpen: !menu.isOpen.lvl1,
                    menuActiveLvl1: 'searchMenu',
                  },
                  setMenu,
                );
              }}
            >
              <div className="header-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#fff"
                  viewBox="0 0 256 256"
                >
                  <path d="M232.49,215.51,185,168a92.12,92.12,0,1,0-17,17l47.53,47.54a12,12,0,0,0,17-17ZM44,112a68,68,0,1,1,68,68A68.07,68.07,0,0,1,44,112Z" />
                </svg>
              </div>
            </button>

            <ButtonNotification />

            <div
              className={`header-icon ${isActive ? 'header-icon-active' : ''}`}
            >
              <button
                aria-label="Profile User"
                title="Profile User"
                type="button"
                onClick={() => {
                  handleIconProfileClick();
                  pushGa4Generic({
                    event: 'navbar',
                    action: 'click url',
                    component: 'logo_profile',
                  });
                  structuredEvent(
                    'navbar',
                    'click url - logo_profile',
                    'beranda',
                    '',
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#fff"
                  viewBox="0 0 256 256"
                >
                  <path d="M234.38,210a123.36,123.36,0,0,0-60.78-53.23,76,76,0,1,0-91.2,0A123.36,123.36,0,0,0,21.62,210a12,12,0,1,0,20.77,12c18.12-31.32,50.12-50,85.61-50s67.49,18.69,85.61,50a12,12,0,0,0,20.77-12ZM76,96a52,52,0,1,1,52,52A52.06,52.06,0,0,1,76,96Z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`account-dropdown ${
            isActive ? 'dropdown-account-open' : ''
          }`}
        >
          <a
            href={`${IDENTITY_URL}/user/signup`}
            onClick={() => {
              pushGa4Generic({
                event: 'navbar',
                action: 'link_click',
                component: 'daftar',
              });
              structuredEvent('navbar', 'click url  - daftar', 'beranda', '');
            }}
            className="btn btn-secondary btn-block account-dropdown-item text-primary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#003366"
              viewBox="0 0 256 256"
              className="mr-2"
            >
              <path d="M256,136a12,12,0,0,1-12,12h-8v8a12,12,0,0,1-24,0v-8h-8a12,12,0,0,1,0-24h8v-8a12,12,0,0,1,24,0v8h8A12,12,0,0,1,256,136Zm-54.81,56.28a12,12,0,1,1-18.38,15.44C169.12,191.42,145,172,108,172c-28.89,0-55.46,12.68-74.81,35.72a12,12,0,0,1-18.38-15.44A124.08,124.08,0,0,1,63.5,156.53a72,72,0,1,1,89,0A124,124,0,0,1,201.19,192.28ZM108,148a48,48,0,1,0-48-48A48.05,48.05,0,0,0,108,148Z" />
            </svg>
            Daftar
          </a>
          <a
            href={`${IDENTITY_URL}/user/login?returnUrl=${returnURL}`}
            onClick={() => {
              structuredEvent('navbar', 'click url - login', 'beranda', '');
              pushGa4Generic({
                event: 'navbar',
                action: 'click url',
                component: 'login',
              });
            }}
            className="btn btn-primary btn-block account-dropdown-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#ffffff"
              viewBox="0 0 256 256"
              className="mr-2"
            >
              <path d="M234.38,210a123.36,123.36,0,0,0-60.78-53.23,76,76,0,1,0-91.2,0A123.36,123.36,0,0,0,21.62,210a12,12,0,1,0,20.77,12c18.12-31.32,50.12-50,85.61-50s67.49,18.69,85.61,50a12,12,0,0,0,20.77-12ZM76,96a52,52,0,1,1,52,52A52.06,52.06,0,0,1,76,96Z" />
            </svg>
            Masuk
          </a>
          <small className="copyright">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#2B4156"
              viewBox="0 0 256 256"
              className="mr-2"
            >
              <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216ZM96,128a32,32,0,0,0,57.6,19.2,8,8,0,0,1,12.8,9.61,48,48,0,1,1,0-57.62,8,8,0,0,1-12.8,9.61A32,32,0,0,0,96,128Z" />
            </svg>
            {new Date().getFullYear()} Hak Cipta Milik Hukumonline.com
          </small>
        </div>
        <div className="header-menu-wrapper main-menu">
          {menuType === '' && <MainItemList />}
        </div>
        <div className="header-menu-wrapper">
          {menuType !== '' && (
            <SubMenu menuType={menuType} activeMenu={activeMenu} />
          )}
        </div>
        {activeMenu !== '' && (
          <AdditionalHeaderButton menuType={menuType} activeMenu={activeMenu} />
        )}
        {activeMenu === 'analisis hukum' && (
          <div className="d-md-none d-block">
            <SubMenuPro {...props} />
          </div>
        )}
        <div
          className={`menuMobile left-side d-flex flex-column ${
            menu.isOpen.lvl1 === true ? ' open' : ''
          }
       `}
        >
          {menu.menuActiveLvl1 === 'searchMenu' && (
            <SearchHeader
              setMenu={setMenu}
              userDetail={userDetail}
              valueSearch={valueSearch}
              valueSearch1={valueSearch1}
              setvalueSearch1={setvalueSearch1}
              currentOption={currentOption}
              setCurrentOption={setCurrentOption}
            />
          )}
          {menu.menuActiveLvl1 === 'searchSuggestMenu' && (
            <SearchHeader
              setMenu={setMenu}
              userDetail={userDetail}
              valueSearch1={valueSearch1}
              setvalueSearch1={setvalueSearch1}
              currentOption={currentOption}
              setCurrentOption={setCurrentOption}
            />
          )}
          <div
            className={`${
              menu.menuActiveLvl1 === 'searchMenu' ? 'd-block' : 'd-none'
            }`}
          >
            {collectionMenu.lvl1.searchMenu(menu, setMenu, props, valueSearch1)}
          </div>
          <div
            className={`${
              menu.menuActiveLvl1 === 'searchSuggestMenu' ? 'd-block' : 'd-none'
            }`}
          >
            {collectionMenu.lvl1.searchSuggestMenu(
              menu,
              setMenu,
              props,
              valueSearch1,
              currentOption,
              handleSubmit,
            )}
          </div>
        </div>
      </header>
    </>
  );
};
export default MenuMobile;
