import React from 'react';
import Head from 'next/head';
import _ from 'lodash';
import { route } from 'next/dist/next-server/server/router';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { BASE_URL } = publicRuntimeConfig;

const objectSelector = (data, metaType) => {
  let dataSeo = {};

  const staticMetaSeo = require('../static/static_content/staticSeo.json');

  let router = data && data.router && data.router.asPath;

  const { currentUrl } = data;
  /*   if (currentUrl && currentUrl.indexOf('?') !== -1) {
    router = currentUrl.substring(0, currentUrl.indexOf('?'));
  }
  if (currentUrl?.indexOf('?type=column') !== -1) {
    router = currentUrl;
  }
  if (router !== undefined && router?.indexOf('/tag/') !== -1) {
    router = router.replace('/tag/berita/', '/tag/');
    router = router.replace('/tag/klinik/', '/tag/');
  } */

  const oriRoute = data && data.router && data.router.route;
  let baseUrl = BASE_URL;
  if (oriRoute && oriRoute.indexOf('/journal') === 0) {
    baseUrl = 'https://jurnal.hukumonline.com';
  } else if (oriRoute && oriRoute.indexOf('/pro') === 0) {
    baseUrl = 'https://pro.hukumonline.com';
  }

  router = router.indexOf('http') !== -1 ? router : baseUrl + router;

  if (router.indexOf('?') !== -1) {
    router = router.substring(0, router.indexOf('?'));
  }

  if (currentUrl?.indexOf('type=column') !== -1) {
    router += (router.endsWith('/') ? '?type=column' : '/?type=column');
  }

  if (currentUrl?.indexOf('type=stories') !== -1) {
    router += (router.endsWith('/') ? '?type=stories' : '/?type=stories');
  }

  if (router?.indexOf('/tag/') !== -1) {
    router = router.replace('/tag/berita/', '/tag/');
    router = router.replace('/tag/klinik/', '/tag/');
  }

  const defaultImage =
    'https://static.hukumonline.com/frontend/default/images/kaze/default.jpg';
  let title;
  let description;
  let keywords;
  let canonical = router;
  let imageMeta =
    'https://static.hukumonline.com/frontend/default/images/kaze/default.jpg';
  let noIndex = false;
  let noFollow = false;
  let author;
  let isArticle;
  let publisher;
  let language;
  const { meta_title = '', meta_description = '-', no_index = '' } = data;

  const { metaSeo = {} } = data;
  if (!_.isEmpty(metaSeo)) {
    _.forEach(metaSeo, (value, key) => {
      if (_.isNull(metaSeo[key]) || metaSeo[key] == '') {
        delete metaSeo[key];
      }
    });
  }
  switch (metaType) {
    case 'mainCategoryPro':
    case 'subCategoryPro':
      if (data && data.category) {
        const { category } = data;
        const defaultTitle = `List Analysis ${category.title} - Hukumonline`;
        const defaultDescMain =
          'Konten premium Hukumonline menyajikan analisis hukum dwibahasa dan translasi regulasi yang terintegrasi dalam satu platform, memudahkan Anda dalam melakukan riset hukum .';
        const defaultDescSub =
          'Analisis mengenai peraturan perundang-undangan dan putusan pengadilan yang disusun oleh tim khusus Hukumonline.com yang terdiri dari personil berlatar belakang hukum dari perguruan tinggi ternama Indonesia dan luar negeri.';
        const DefaultDesc =
          metaType == 'mainCategoryPro' ? defaultDescMain : defaultDescSub;
        const defaultKeywords = 'Peraturan, Perundang-undangan, undang-undang';
        ({
          title = defaultTitle,
          description = DefaultDesc,
          keywords = defaultKeywords,
          imageMeta = defaultImage,
          canonical = canonical,
          isArticle = true,
          noIndex = true,
          noFollow = false,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'regulationPro':
      if (data && data.categoryActive) {
        const { categoryActive } = data;
        const titleSelect = categoryActive.title_en
          ? categoryActive.title_en
          : categoryActive.title;
        const defaultTitle = `List Regulasi ${titleSelect} - Hukumonline`;
        const defaultDesc =
          'Koleksi ratusan terjemahan peraturan terkait peraturan bisnis.';
        const defaultKeywords = 'peraturan terjemahan ';
        ({
          title = defaultTitle,
          description = defaultDesc,
          keywords = defaultKeywords,
          imageMeta = defaultImage,
          canonical = canonical,
          isArticle = false,
          noIndex = false,
          noFollow = false,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'regulationDetailPro':
      if (data && data.regulation) {
        const { regulation } = data;
        const metaTitle = regulation.title_en || regulation.title || '';
        const metaSubtitle =
          regulation.subtitle_en || regulation.subtitle || '';
        const metadesc =
          'Koleksi ratusan terjemahan peraturan terkait peraturan bisnis.';
        const metaKeywords =
          regulation.tags.length > 0
            ? regulation.tags.join()
            : 'Terjemahan Peraturan';
        ({
          title = `${metaTitle} - Regulation Translation Hukumonline`,
          description = metaSubtitle || metadesc,
          keywords = metaKeywords,
          imageMeta = defaultImage,
          canonical = canonical,
          isArticle = false,
          noIndex = false,
          noFollow = false,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'analysisDetailPro':
      if (data && data.article) {
        const { article } = data;
        const metaTitle = article.title || '';
        const metaSubtitle = article.intro || '';
        const metaKeywords =
          article.tags.length > 0
            ? article.tags.join()
            : 'Analysis Hukumonline';
        ({
          title = `${metaTitle} - Hukumonline.com`,
          description = metaSubtitle,
          keywords = metaKeywords,
          imageMeta = defaultImage,
          canonical = canonical,
          isArticle = false,
          noIndex = false,
          noFollow = false,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'detailBerita':
      if (data && data.article) {
        const { article } = data;
        const articleDesc = article?.description.substring(0, 160);
        const defaultDescription =
          'Kumpulan artikel berita pada situs berita hukumonline. Artikel diolah dan ditayangkan secara khusus oleh tim jurnalis berpengalaman Hukumonline.';
        const defaultKeywords = 'Berita, Hukum, Artikel Hukum';
        const featuredImage =
          article?.featured_images && article?.featured_images.length > 0
            ? `https://images.hukumonline.com/frontend/${article.featured_images[0].parentGuid}/${article.featured_images[0].filename}`
            : defaultImage;
        canonical += canonical.endsWith('/') ? '' : '/';
        ({
          title = `${article.title}`,
          description = articleDesc || defaultDescription,
          keywords = article.keywords || defaultKeywords,
          imageMeta = featuredImage,
          canonical = canonical,
          isArticle = true,
          noIndex = false,
          noFollow = false,
          author = article.author_name || 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'detailFoto':
      if (data && data.article) {
        const { article } = data;
        const articleDesc = article?.description.substring(0, 160);
        const defaultDescription =
          'Kumpulan artikel berita pada situs berita hukumonline. Artikel diolah dan ditayangkan secara khusus oleh tim jurnalis berpengalaman Hukumonline.';
        const defaultKeywords = 'Berita, Hukum, Artikel Hukum, Berita Foto';
        const featuredImage =
          article?.featured_images && article?.featured_images.length > 0
            ? `https://images.hukumonline.com/frontend/${article.featured_images[0].parentGuid}/${article.featured_images[0].filename}`
            : '';
        const metaDesc =
          article?.featured_images && article?.featured_images.length > 0
            ? article.featured_images[0].title.substring(0, 160)
            : '';
        ({
          title = `${article.title}`,
          description = articleDesc || metaDesc || defaultDescription,
          keywords = article.keywords || defaultKeywords,
          imageMeta = featuredImage,
          canonical = canonical,
          isArticle = true,
          noIndex = false,
          noFollow = false,
          author = article.author_name || 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'detailPremium':
      if (data && data.article) {
        const { article } = data;
        const defaultDescription =
          'Kumpulan artikel berita pada situs berita hukumonline. Artikel diolah dan ditayangkan secara khusus oleh tim jurnalis berpengalaman Hukumonline.';
        const defaultKeywords =
          'Artikel hukum, problematika hukum , Ulasan Hukum';
        const featuredImage =
          article?.featured_images && article?.featured_images.length > 0
            ? `https://images.hukumonline.com/frontend/${article.featured_images[0].parentGuid}/${article.featured_images[0].filename}`
            : '';
        canonical += canonical.endsWith('/') ? '' : '/';
        ({
          title = `${article.title}`,
          description = article.description.substring(0, 160) ||
            defaultDescription,
          keywords = article.keywords || defaultKeywords,
          imageMeta = featuredImage,
          canonical = canonical,
          isArticle = true,
          noIndex = false,
          noFollow = false,
          author = article.author_name || 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'tagDetail':
      if (data) {
        let tagClean = data?.tagName.replace(/-/g, ' ');
        tagClean = tagClean.replace(/_/g, '-');
        const defaultKeywords =
          'Artikel hukum, problematika hukum , Ulasan Hukum';
        const featuredImage = '';
        ({
          title = `Berita dan informasi seputar ${tagClean}`,
          description = `Kumpulan artikel berita  terkait ${tagClean} yang diolah dan ditayangkan secara khusus oleh tim jurnalis berpengalaman Hukumonline.`,
          keywords = data?.meta?.related_tags || defaultKeywords,
          imageMeta = featuredImage,
          canonical = canonical,
          isArticle = false,
          noIndex = false,
          noFollow = false,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'detailGrafis':
      if (data && data.article) {
        const { article } = data;
        const metaDesc =
          'Kumpulan artikel berita pada situs berita hukumonline. Artikel diolah dan ditayangkan secara khusus oleh tim jurnalis berpengalaman Hukumonline.';
        const metaKeywords =
          'Infografis Hukum , klinik hukumonline, solusi hukum';
        const featuredImage =
          article.images && article.images > 0 ? article.images : defaultImage;
        canonical += canonical.endsWith('/') ? '' : '/';
        ({
          title = `${article.title}- Klinik Hukumonline`,
          description = article.description || metaDesc,
          keywords = article.keywords || metaKeywords,
          imageMeta = featuredImage,
          canonical = canonical,
          isArticle = true,
          noIndex = false,
          noFollow = false,
          author = article.author_name || 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'categoriesClinic':
      if (data && data?.metaKey && staticMetaSeo[data.metaKey]) {
        const urlActive = BASE_URL + router;
        const isMultiplePage = urlActive.includes('page');
        // const { selectedCategory } = data;
        ({
          title = title,
          description = 'Penyedia produk dan jasa hukum terlengkap, terintegrasi dan terpercaya.',
          keywords = keywords,
          imageMeta = defaultImage,
          canonical = canonical,
          isArticle = false,
          noIndex = isMultiplePage,
          noFollow = isMultiplePage,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = staticMetaSeo[data.metaKey]);
      }
      break;
    case 'narsumEvent':
      if (data && data.narsumDetail) {
        const { narsumDetail } = data;
        const metaDesc =
          'Kumpulan artikel berita pada situs berita hukumonline. Artikel diolah dan ditayangkan secara khusus oleh tim jurnalis berpengalaman Hukumonline.';
        const metaKeywords = 'Belajar Hukum, Kegiatan Hukum';
        const featuredImage =
          narsumDetail?.featured_images &&
          narsumDetail?.featured_images.length > 0
            ? `https://images.hukumonline.com/frontend/${narsumDetail.featured_images[0].parentGuid}/${narsumDetail.featured_images[0].filename}`
            : '';
        ({
          title = `${narsumDetail.title} - Event & Training Hukumonline`,
          description = narsumDetail.description || metaDesc,
          keywords = narsumDetail.keywords || metaKeywords,
          imageMeta = featuredImage,
          canonical = canonical,
          isArticle = true,
          noIndex = false,
          noFollow = false,
          author = narsumDetail.name || 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'authorDetailPremium':
      if (data && data.authorDetail) {
        const { authorDetail } = data;
        const defaultTitle =
          data.pageType == 'column'
            ? `Kolom opini dari ${authorDetail.name} | Hukumonline`
            : `Arsip Artikel Hukum Premium yang Ditulis Oleh ${authorDetail.name}`;
        const defaultDesc =
          data.pageType == 'column'
            ? `Menyajikan pendapat dari  ${authorDetail.name} atas isu terkini yang sedang ramai dibicarakan oleh masyarakat. Memberikan opini yang berbobot dan kredibel.`
            : `Daftar artikel Premium Stories yang ditulis oleh  ${authorDetail.name}.  Mengulas berbagai isu hukum penting yang berdampak pada bisnis.`;
        const metaDesc =
          'Kumpulan artikel berita pada situs berita hukumonline. Artikel diolah dan ditayangkan secara khusus oleh tim jurnalis berpengalaman Hukumonline.';
        const metaKeyword =
          data.pageType == 'column'
            ? 'Narasumber Hukum, Belajar Hukum '
            : 'Penulis Hukum, Ulasan Hukum , Artikel hukum';
        const featuredImage =
          authorDetail?.featured_images &&
          authorDetail?.featured_images.length > 0
            ? `https://images.hukumonline.com/frontend/${authorDetail.featured_images[0].parentGuid}/${authorDetail.featured_images[0].filename}`
            : defaultImage;

        ({
          title = defaultTitle,
          description = defaultDesc || metaDesc,
          keywords = authorDetail.keywords || metaKeyword,
          imageMeta = featuredImage,
          canonical = canonical,
          isArticle = true,
          noIndex = false,
          noFollow = false,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'authorDetailKlinik':
    case 'mitraDetailKlinik':
      if (data && (data.authorDetail || data.mitraDetail)) {
        const { authorDetail, mitraDetail = {} } = data;
        const metaDesc =
          'Kumpulan artikel berita pada situs berita hukumonline. Artikel diolah dan ditayangkan secara khusus oleh tim jurnalis berpengalaman Hukumonline.';
        const metaKeyword = 'Narasumber Hukum, Belajar Hukum';

        const authorTitle = `${authorDetail?.name} - Klinik Hukumonline`;
        const mitraTitle = `Mitra : ${mitraDetail?.name} - Hukumonline.com`;

        const defaultTitle =
          metaType == 'authorDetailKlinik' ? authorTitle : mitraTitle;
        const defaultDesc =
          metaType == 'authorDetailKlinik' ? authorDetail.bio : mitraDetail.bio;
        const cleanDesc = defaultDesc.replace(/<\/?[^>]+(>|$)/g, '');

        const defaultKeywords = authorDetail?.keywords || metaKeyword;
        const featuredImage =
          metaType == 'authorDetailKlinik'
            ? authorDetail?.avatar?.url
            : mitraDetail?.avatar?.url;
        const urlActive = BASE_URL + router;
        const isMultiplePage = urlActive.includes('page');
        ({
          title = defaultTitle,
          description = cleanDesc.substring(0, 160) || metaDesc,
          keywords = defaultKeywords,
          imageMeta = featuredImage || defaultImage,
          canonical = canonical,
          isArticle = true,
          noIndex = isMultiplePage,
          noFollow = isMultiplePage,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'detailClinic':
      if (data && data.article) {
        const { article } = data;
        const defaultTitle = `${article.title} - Klinik Hukumonline`;
        const defaultDesc =
          'Kumpulan artikel berita pada situs berita hukumonline. Artikel diolah dan ditayangkan secara khusus oleh tim jurnalis berpengalaman Hukumonline.';
        const metaDesc = article.question || defaultDesc;
        const metaKeywords =
          article.keywords || 'klinik hukumonline, solusi hukum, tanya hukum';
        const defImage = article.image || defaultImage;
        const defaultAuthor =
          article.author?.name || 'https://www.hukumonline.com';
        canonical += canonical.endsWith('/') ? '' : '/';
        ({
          title = defaultTitle,
          description = metaDesc,
          keywords = metaKeywords,
          imageMeta = defImage,
          canonical = canonical,
          isArticle = true,
          noIndex = false,
          noFollow = false,
          author = defaultAuthor,
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'detailTerm':
      ({
        title = '',
        description = '',
        keywords = '-',
        imageMeta = defaultImage,
        canonical = canonical,
        isArticle = true,
        noIndex = false,
        noFollow = false,
        author = 'Hukumonline',
        publisher = 'PT Justika Siar Publika',
        language = 'id',
      } = metaSeo);

      break;
    case 'arsipDetailEvent':
    case 'detailEvent':
      if (data && data.detailEvent) {
        const { detailEvent } = data;
        const metaDesc =
          'Kumpulan artikel berita pada situs berita hukumonline. Artikel diolah dan ditayangkan secara khusus oleh tim jurnalis berpengalaman Hukumonline.';
        const metaKeywords = 'Belajar Hukum, Kegiatan Hukum';
        const featuredImage =
          detailEvent?.featured_images &&
          detailEvent?.featured_images.length > 0
            ? `https://images.hukumonline.com/frontend/${detailEvent.featured_images[0].parentGuid}/${detailEvent.featured_images[0].filename}`
            : '';
        ({
          title = `${detailEvent.title} - Event & Training Hukumonline`,
          description = detailEvent.description || metaDesc,
          keywords = detailEvent.keywords || metaKeywords,
          imageMeta = featuredImage,
          canonical = canonical,
          isArticle = true,
          noIndex = false,
          noFollow = false,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'detailPusatData':
      if (data && data.catalog) {
        const { catalog } = data;
        const metaKeywords =
          catalog?.tags && catalog?.tags.length > 0
            ? catalog.tags.join()
            : 'Data Peraturan, Data Putusan';
        ({
          title = `${catalog.title} - Pusat Data Hukumonline`,
          description = `${catalog.subtitle} - ${catalog.title}`,
          keywords = metaKeywords,
          imageMeta = defaultImage,
          canonical = `${BASE_URL}/pusatdata/detail/${catalog.id}/${catalog.slug}`,
          isArticle = true,
          noIndex = false,
          noFollow = false,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = metaSeo);
      }
      break;
    case 'static':
      if (data && data?.metaKey && staticMetaSeo[data.metaKey]) {
        const urlActive = BASE_URL + router;
        const isMultiplePage = urlActive.includes('page');
        ({
          title = 'Hukumonline',
          description = 'Penyedia produk dan jasa hukum terlengkap, terintegrasi dan terpercaya.',
          keywords = 'berita hukum',
          imageMeta = defaultImage,
          canonical = canonical,
          isArticle = false,
          noIndex = isMultiplePage,
          noFollow = isMultiplePage,
          author = 'Hukumonline',
          publisher = 'PT Justika Siar Publika',
          language = 'id',
        } = staticMetaSeo[data.metaKey]);
      }
      break;
    default:
      ({
        title = meta_title != ''
          ? `${meta_title} - Hukumonline.com`
          : 'Hukumonline.com',
        description = meta_description,
        keywords = '-',
        imageMeta = defaultImage,
        canonical = canonical,
        isArticle = false,
        noIndex = no_index === true ? 'noindex' : '',
        noFollow = false,
        author = 'Hukumonline',
        publisher = 'PT Justika Siar Publika',
        language = 'id',
      } = metaSeo);
      break;
  }
  const cleanTitle = title && title.replace(/(<([^>]+)>)/gi, '');
  dataSeo = {
    metaTitle: cleanTitle,
    metaDesc: description,
    metaKeywords: keywords,
    metaCanonical: canonical,
    isArticle,
    noIndex,
    noFollow,
    metaImage: imageMeta,
    metaAuthor: author,
    metaPublisher: publisher,
    language: 'id',
  };
  //
  return dataSeo;
};
const getTagArticle = (keywords) => {
  let tagArticle;

  if (Array.isArray(keywords)) {
    tagArticle = keywords.map((item, index) => (
      <meta property="article:tag" key={`meta+${index}`} content={item} />
    ));
  } else {
    const keywordsArr = keywords.split(',');
    tagArticle = keywordsArr.map((item, index) => (
      <meta property="article:tag" key={`meta+${index}`} content={item} />
    ));
  }

  return (
    <>
      {/* <meta property="article:published_time" content="2021-06-12T05:35:18.000Z" />
      <meta property="article:modified_time" content="2021-06-12T05:35:18.000Z" /> */}
      {tagArticle}
    </>
  );
};

const MetaSeo = (props) => {
  const { metaType = 'default', metaSeo } = props;
  const {
    metaTitle,
    metaDesc,
    metaKeywords,
    metaCanonical,
    noIndex,
    isArticle,
    noFollow,
    metaUrl,
    metaImage,
    metaAuthor,
    metaPublisher,
    language,
  } = objectSelector(props, metaType);

  const ORG_SCHEMA = {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'WebPage',
        '@id': props?.currentUrl || props?.og_url,
        url: props?.currentUrl || props?.og_url,
        name: metaTitle,
        description: metaDesc,
        isPartOf: { '@id': 'https://www.hukumonline.com' },
        inLanguage: 'id-ID',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [props?.currentUrl || props?.og_url],
          },
        ],
        publisher: {
          '@type': 'Organization',
          name: 'PT Justika Siar Publika',
          logo: {
            '@type': 'ImageObject',
            url:
              'https://static.hukumonline.com/frontend/default/images/kaze/default.jpg',
          },
        },
      },
    ],
  };
  if (metaSeo?.publishedAt) {
    ORG_SCHEMA['@graph'][0].datePublished = metaSeo?.publishedAt;
  }
  if (metaSeo?.modifiedAt) {
    ORG_SCHEMA['@graph'][0].dateModified = metaSeo?.modifiedAt;
  }

  const metaIndex = noIndex ? 'noindex, ' : '';
  const metaFollow = noFollow ? 'nofollow, ' : '';
  const metaIsArticle = isArticle ? 'article' : 'website';
  const metaLanguage = language == 'id' ? 'Indonesia' : 'English';
  const metaTagArticle =
    metaIsArticle == 'article' ? getTagArticle(metaKeywords) : '';

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDesc} />
        <meta name="author" content={metaAuthor} />
        <meta name="publisher" content={metaPublisher} />
        <meta name="keywords" content={metaKeywords} />
        <meta
          name="robots"
          content={`${metaIndex}${metaFollow}max-image-preview:large`}
        />
        <link rel="canonical" href={metaCanonical} />
        <meta name="referrer" content="no-referrer-when-downgrade" />
        <meta name="language" content={metaLanguage} />

        <meta property="og:site_name" content="hukumonline.com" />
        <meta property="og:type" content={metaIsArticle} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDesc} />
        <meta property="og:url" content={metaCanonical} />
        <meta property="og:image" content={metaImage} />
        {metaTagArticle}

        {metaIsArticle == 'article' ? (
          <meta property="article:publisher" content={metaPublisher} />
        ) : (
          ''
        )}
        {metaIsArticle == 'article' ? (
          <meta property="article:author" content={metaAuthor} />
        ) : (
          ''
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDesc} />
        <meta name="twitter:url" content={metaCanonical} />
        <meta name="twitter:image" content={metaImage} />
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content="hukumonline.com" />
        <meta name="twitter:site" content="@hukumonline" />
        <meta property="og:image:width" content="676" />
        <meta property="og:image:height" content="610" />
      </Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ORG_SCHEMA) }}
      />
    </>
  );
};
function propsAreEqual(prevProps, nextProps) {
  return prevProps.metaType === nextProps.metaType;
}
const MemoizedMetaSeo = React.memo(MetaSeo, propsAreEqual);
export default MemoizedMetaSeo;
