import { useCallback, useEffect, useMemo, useState } from 'react';

import { getNotification } from '../../services/notification';
import { getCookie } from '../../libraries/session';
import { trackerMain } from '../../libraries/tracker';

import stylesheet from './buttonNotification.scss';

export function ButtonNotification() {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const jwt = getCookie('jwt') || '';

  const [width, setWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const isMobile = useMemo(() => width > 0 && width <= 1124, [width]);

  const [isLoading, setIsLoading] = useState(true);
  const [isUnreadData, setIsUnreadData] = useState(false);

  const checkNotif = useCallback(async () => {
    try {
      const data = await getNotification(jwt);
      setIsLoading(false);
      setIsUnreadData(!!data?.data);
    } finally {
      // setIsLoading(false);
    }
  }, [jwt]);

  useEffect(() => {
    if (isClient) {
      checkNotif();

      const interval = setInterval(checkNotif, 60 * 60 * 1000); // 1 hour

      return () => clearInterval(interval);
    }
  }, [isClient]);

  if (!isClient) return null;
  if (!jwt) return null;

  return (
    <>
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: stylesheet }}
      />
      <a
        className="button-notification"
        target="_blank"
        rel="noreferrer"
        href="https://pro.hukumonline.com/workspace/notification/"
        onClick={() => {
          trackerMain({
            snowplow: [
              'notifikasi_open',
              'button - button buka notifikasi',
              '',
              '',
              '',
            ],
            ga4: [
              'notifikasi_open',
              '',
              '',
              'button',
              `button buka notifikasi`,
              '',
              '',
              '',
            ],
          });
        }}
      >
        <img
          className="button-notification--icon"
          alt="bell"
          src={
            isMobile
              ? '/static/images/bell.svg'
              : '/static/images/bell-bold.svg'
          }
        />
        {!isLoading && isUnreadData && (
          <div className="button-notification--red-dot" />
        )}
      </a>
    </>
  );
}
