import React from 'react';
import Container from 'reactstrap/lib/Container';
import { IoChevronForward, IoListSharp } from 'react-icons/io5';
import { BsBoxArrowInRight, BsFillStarFill } from 'react-icons/bs';
import PropTypes from 'prop-types';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { IDENTITY_URL, BASE_URL } = publicRuntimeConfig;

const MainMenu = ({ setMenu, data, toggleMenu, currentUrl }) => (
  <Container className="py-4 d-flex flex-column justify-content-between align-items-center h-100">
    <nav className="d-flex flex-column">
      <button
        type="button"
        onClick={() =>
          toggleMenu(
            data,
            {
              name: 'lvl2',
              isOpen: !data.isOpen.lvl2,
              menuActiveLvl1: 'mainMenu',
              menuActiveLvl2: 'proMenu',
            },
            setMenu,
          )
        }
        className="mb-4 pb-2"
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column align-items-start">
            <span className="paragraph font-weight-bold">
              Pro{' '}
              <BsFillStarFill fontSize="14px" className="ml-1 text-secondary" />
            </span>

            <p className="small text-main mb-0 font-weight-medium">
              Layanan premium berupa analisis hukum dwibahasa, pusat data
              peraturan dan putusan pengadilan, serta artikel premium.
            </p>
          </div>
          <div className=" flex-shrink-1 ml-3">
            <IoChevronForward fontSize="16px" className="mx-2 text-main" />
          </div>
        </div>
      </button>
      <button
        type="button"
        onClick={() =>
          toggleMenu(
            data,
            {
              name: 'lvl2',
              isOpen: !data.isOpen.lvl2,
              menuActiveLvl1: 'mainMenu',
              menuActiveLvl2: 'solusiMenu',
            },
            setMenu,
          )
        }
        className="mb-4 pb-2"
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column align-items-start">
            <span className="paragraph font-weight-bold">Solusi</span>
            <p className="small text-main mb-0 font-weight-medium">
              Solusi kebutuhan dan permasalahan hukum Anda melalui pemanfaatan
              teknologi.
            </p>
          </div>
          <div className=" flex-shrink-1 ml-3">
            <IoChevronForward fontSize="16px" className="mx-2 text-main" />
          </div>
        </div>
      </button>
      <button
        type="button"
        onClick={() =>
          toggleMenu(
            data,
            {
              name: 'lvl2',
              isOpen: !data.isOpen.lvl2,
              menuActiveLvl1: 'mainMenu',
              menuActiveLvl2: 'wawasanMenu',
            },
            setMenu,
          )
        }
        className="mb-4 pb-2"
        aria-label="Wawasan Hukum"
        title="Wawasan Hukum"
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column align-items-start">
            <span className="paragraph font-weight-bold">Wawasan Hukum</span>
            <p className="small text-main mb-0 font-weight-medium">
              Layanan edukasi dan informasi hukum tepercaya sesuai dengan
              perkembangan hukum di Indonesia.
            </p>
          </div>
          <div className=" flex-shrink-1 ml-3">
            <IoChevronForward fontSize="16px" className="mx-2 text-main" />
          </div>
        </div>
      </button>
      <a href={`${BASE_URL}/produk/id`} className="mb-4 pb-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <IoListSharp fontSize="20px" className="mr-2" />
            <span className="paragraph font-weight-bold">Katalog Produk</span>
          </div>
          <div className=" flex-shrink-1">
            <IoChevronForward fontSize="16px" className="mx-2 text-main" />
          </div>
        </div>
      </a>
    </nav>
  </Container>
);

MainMenu.propTypes = {
  setMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
  currentUrl: PropTypes.string.isRequired,
};
export default MainMenu;
