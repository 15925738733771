import React, { useEffect, useRef, useContext } from 'react';
import { pushGa4Generic, structuredEvent } from '../../../libraries/tracker';
import stylesheet from './DesktopSearchSuggest.scss';
import { getAutocomplete } from '../../../services/search';
import { BiSearch } from 'react-icons/bi';
import { NavbarContext } from '../index';

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { TOKEN_PUBLIC } = publicRuntimeConfig;

function getHighlightedText({ text, highlight }) {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span className="suggestItem">
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          className={`${
            part.toLowerCase() === highlight.toLowerCase() ? 'highlight' : ''
          }`}
        >
          {part}
        </span>
      ))}{' '}
    </span>
  );
}

const DesktopSearchSuggest = ({ query, searchOption, handleSubmit }) => {
  const { searchSuggestions, setSearchSuggestions } = useContext(NavbarContext);
  const mapSearchOption = {
    Semua: 'all',
    Analisis: 'analyses',
    Peraturan: 'regulations',
    Putusan: 'decisions',
    Berita: 'news',
    Klinik: 'clinics',
    Events: 'events',
  };

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    (async () => {
      const getAutocompleteData = await getAutocomplete(query, TOKEN_PUBLIC);
      setSearchSuggestions(getAutocompleteData?.recommendation || []);
    })();
  }, [query]);

  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />

      <div className={'row'}>
        <div
          className={'col-12 pl-0'}
          style={{ marginLeft: '-20px', marginTop: '-15px' }}
        >
          <div className={'desktop-search-suggest-title mb-2'}>
            {searchSuggestions &&
              searchSuggestions?.map((item, index) => {
                return (
                  <div key={index} className="itemSuggestWrapper">
                    <a
                      onClick={() => {
                        structuredEvent(
                          'navbar_search_recom_click',
                          `link - link search recommendation navbar-${item.key}`,
                          '',
                          '',
                          index + 1,
                        );
                        pushGa4Generic({
                          event: 'navbar_search_recom_click',
                          action: 'link',
                          component: `link search recommendation navbar-${item.key}`,
                        });
                        const params = {
                          isSuggestions: true,
                          selectedSuggestions: item.key,
                          index: index + 1,
                        };
                        handleSubmit(params);
                      }}
                      className={`search-recommendation-item-${index + 1}`}
                    >
                      <BiSearch className={'mr-2'} />
                      {getHighlightedText({ text: item.key, highlight: query })}
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default DesktopSearchSuggest;
