const packageAccess = [
  'member-basic',
  'member-standard',
  'member-professional',
  'member-professional-trial',
  'member-professional-plus',
  'member-professional-plus-trial',
];

export const isPaidMember = (accessLevel) => {
  if (!accessLevel) {
    return false;
  }
  return packageAccess.includes(accessLevel);
};
