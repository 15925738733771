import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import qs from 'qs';
import PropTypes from 'prop-types';
import { structuredEvent } from '../../../../libraries/tracker';

const getConfig = require('next/config').default;

const { publicRuntimeConfig } = getConfig();
const { SEARCH_URL } = publicRuntimeConfig;

const SubMenuSearch = ({ t, router }) => (
  <>
    <div className="d-flex">
      <NavItem className="mr-3">
        <NavLink
          href={`${SEARCH_URL}/search/all${router && router.query && router.query.q ? `?${qs.stringify({ q: router.query.q })}` : ''}`}
          className="px-0 text-center"
          active={router.pathname === '/search/all'}
          title={t('all')}
          onClick={() => structuredEvent('search page', 'tab - search page', 'all', '', '')}
        >
          {t('all')}
        </NavLink>
      </NavItem>
      <NavItem className="mx-3">
        <NavLink
          href={`${SEARCH_URL}/search/analyses${router && router.query && router.query.q ? `?${qs.stringify({ q: router.query.q })}` : ''}`}
          className="px-0 text-center"
          active={router.pathname === '/search/analyses'}
          title={t('analyses')}
          onClick={() => structuredEvent('search page', 'tab - search page', 'analyses', '', '')}
        >
          {t('analyses')}
        </NavLink>
      </NavItem>
      <NavItem className="mx-3">
        <NavLink
          href={`${SEARCH_URL}/search/regulations${router && router.query && router.query.q ? `?${qs.stringify({ q: router.query.q })}` : ''}`}
          className="px-0 text-center"
          active={router.pathname === '/search/regulations'}
          title={t('regulations')}
          onClick={() => structuredEvent('search page', 'tab - search page', 'regulations', '', '')}
        >
          {t('regulations')}
        </NavLink>
      </NavItem>
      <NavItem className="mx-3">
        <NavLink
          href={`${SEARCH_URL}/search/decisions${router && router.query && router.query.q ? `?${qs.stringify({ q: router.query.q })}` : ''}`}
          className="px-0 text-center"
          active={router.pathname === '/search/decisions'}
          title={t('decisions')}
          onClick={() => structuredEvent('search page', 'tab - search page', 'decisions', '', '')}
        >
          {t('decisions')}
        </NavLink>
      </NavItem>
      <NavItem className="mx-3">
        <NavLink
          href={`${SEARCH_URL}/search/news${router && router.query && router.query.q ? `?${qs.stringify({ q: router.query.q })}` : ''}`}
          className="px-0 text-center"
          active={router.pathname === '/search/news'}
          title={t('news')}
          onClick={() => structuredEvent('search page', 'tab - search page', 'news', '', '')}
        >
          {t('news')}
        </NavLink>
      </NavItem>
      <NavItem className="mx-3">
        <NavLink
          href={`${SEARCH_URL}/search/clinics${router && router.query && router.query.q ? `?${qs.stringify({ q: router.query.q })}` : ''}`}
          className="px-0 text-center"
          active={router.pathname === '/search/clinics'}
          title={t('clinics')}
          onClick={() => structuredEvent('search page', 'tab - search page', 'clinics', '', '')}
        >
          {t('clinics')}
        </NavLink>
      </NavItem>

      <NavItem className="mx-3">
        <NavLink
          href={`${SEARCH_URL}/search/events${router && router.query && router.query.q ? `?${qs.stringify({ q: router.query.q })}` : ''}`}
          className="px-0 text-center"
          active={router.pathname === '/search/events'}
          title={t('events')}
          onClick={() => structuredEvent('search page', 'tab - search page', 'events', '', '')}
        >
          {t('events')}
        </NavLink>
      </NavItem>
    </div>
  </>
);
SubMenuSearch.propTypes = {
  router: PropTypes.objectOf(),
  t: PropTypes.func.isRequired,
};

SubMenuSearch.defaultProps = {
  router: {},
};


export default SubMenuSearch;
