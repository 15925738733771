import React from 'react';
import { NavItem, NavLink } from 'reactstrap';


const SubMenuJurnal = ({ router }) => (
  <>
    <div className="d-flex">
      <NavItem>
        <NavLink
          href="/"
          className="px-0 mr-3 text-center"
          active={router.asPath.includes('/?') || router.asPath === '/'}
          title="Pusat Data Jurnal Hukum Indonesia"
        >
          Beranda
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="/journal"
          active={router.asPath.includes('/journal')}
          className="px-0 mr-3 text-center"
          title="Jurnal Hukum Indonesia"
        >
          Jurnal
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          href="/article"
          active={router.asPath.includes('/article')}
          className="px-0 mr-3 text-center"
          title="Artikel Akademik Hukum Indonesia"
        >
          Artikel
        </NavLink>
      </NavItem>
    </div>
  </>
);

export default SubMenuJurnal;
