/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Media,
  Badge,
  Collapse,
} from 'reactstrap';

import Link from 'next/link';
import WordLimit from 'react-word-limit';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Moment from 'react-moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee,
  faChevronCircleUp,
  faSortAmountUp,
  faLayerGroup,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import slugify from 'slugify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import numeral, { value } from 'numeral';
import { LabelStatus, Headline, LabelPro, IsPaid } from '../common/labelStatus';
import { structuredEvent, trackerMain } from '../../libraries/tracker';

import { imageSelector } from '../../libraries/utils';

const getConfig = require('next/config').default;
const { publicRuntimeConfig } = getConfig();
const { PRO_URL } = publicRuntimeConfig;

moment.locale('id');
if (numeral.locales.id === undefined) {
  numeral.register('locale', 'id', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'rb',
      million: 'jt',
      billion: 'm',
      trillion: 't',
    },
    currency: {
      symbol: 'Rp.',
    },
  });
}

export const PusatData = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const { location } = props;
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const tabContent = props.data.map((item, index) => {
    const itemPusatData = (detail, index) => {
      let ga4Events = 'eventCoreV1Regulation';
      if (item.title.toLowerCase() == 'putusan')
        ga4Events = 'eventCoreV1Decision';
      else if (item.title.toLowerCase() == 'english translated')
        ga4Events = 'eventCoreV1Translation';

      const title =
        item.title == 'English Translated' ? detail.title_en : detail.title;
      const subtitle =
        item.title == 'English Translated'
          ? detail.subtitle_en
          : detail.subtitle;
      const isTranslated =
        item.title == 'English Translated'
          ? 'peraturanTranslated'
          : 'peraturan';
      const contentPreference = detail.content_preference;
      const isPaid = detail.is_paid && <IsPaid />;
      return (
        <div
          className="content d-flex regulation flex-column border-bottom py-3"
          key={detail.guid}
        >
          <span className="cat-reg text-capitalized">{contentPreference}</span>
          <div>
            <a
              href={linkGen(detail, isTranslated)}
              onClick={() => {
                trackerMain({
                  snowplow: [
                    location,
                    `click link - blok pusat data - ${item.title.toLowerCase()} articles`,
                    `${detail.guid}`,
                    '',
                    `${index}`,
                  ],
                  ga4: [
                    ga4Events,
                    location,
                    'section_pusat_data',
                    'link_click',
                    `index ${item.title.toLowerCase()}`,
                    index,
                    `${title}`,
                    `${detail.guid}`,
                  ],
                });
              }}
            >
              <span className="mr-2"> {title}</span>
            </a>
            {isPaid}
          </div>
          <span className="small"> {subtitle}</span>
        </div>
      );
    };
    const listContent = item.items.map((regulation, index) =>
      itemPusatData(regulation, index),
    );
    return (
      <TabPane tabId={`${item.id}`} key={index}>
        <Row>
          <Col sm="12">
            <div className="section-content">{listContent}</div>
            <div className="read-more pb-4 p-0">
              <a
                href={item.view_all}
                className="d-md-block d-none text-uppercase my-2"
                onClick={() => {
                  trackerMain({
                    snowplow: [
                      location,
                      `click button - blok pusat data - ${item.title.toLowerCase()} - lihat semua`,
                      '',
                      '',
                      '',
                    ],
                    ga4: [
                      'eventCoreV1BasicData',
                      location,
                      'section_pusat_data',
                      'button_click',
                      `button lihat semua ${item.title.toLowerCase()}`,
                      '',
                      '',
                      '',
                    ],
                  });
                }}
              >
                <span>
                  Lihat Semua <i className="fa fa-arrow-right ml-2" />
                </span>
              </a>
              <a
                href={item.view_all}
                className="btn btn-outline-primary btn-block btn-more d-md-none my-md-2 my-1"
                onClick={() =>
                  trackerMain({
                    snowplow: [
                      location,
                      `click button - blok pusat data - ${item.title.toLowerCase()} - lihat semua`,
                      '',
                      '',
                      '',
                    ],
                    ga4: [
                      'eventCoreV1BasicData',
                      location,
                      'section_pusat_data',
                      'button_click',
                      `button lihat semua ${item.title.toLowerCase()}`,
                      '',
                      '',
                      '',
                    ],
                  })
                }
              >
                <span>Lihat Semua </span>
              </a>
            </div>
          </Col>
        </Row>
      </TabPane>
    );
  });

  return (
    <div id="section-latest" className="section px-0">
      <div className="section-head d-flex justify-content-between align-items-center border-bottom">
        <h3>Pusat Data</h3>
      </div>
      <div className="section-content">
        <Nav tabs className="justify-content-between">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
                trackerMain({
                  snowplow: [
                    location,
                    'click tab - blok pusat data',
                    'peraturan',
                    '',
                    '',
                  ],
                  ga4: [
                    'eventCoreV1BasicData',
                    location,
                    'section_pusat_data',
                    'button_click',
                    'button peraturan',
                    '',
                    '',
                    '',
                  ],
                });
              }}
            >
              Peraturan
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
                toggle('2');
                trackerMain({
                  snowplow: [
                    location,
                    'click tab - blok pusat data',
                    'putusan',
                    '',
                    '',
                  ],
                  ga4: [
                    'eventCoreV1BasicData',
                    location,
                    'section_pusat_data',
                    'button_click',
                    'button putusan',
                    '',
                    '',
                    '',
                  ],
                });
              }}
            >
              Putusan
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggle('3');
                trackerMain({
                  snowplow: [
                    location,
                    'click tab - blok pusat data',
                    'english translated',
                    '',
                    '',
                  ],
                  ga4: [
                    'eventCoreV1BasicData',
                    location,
                    'section_pusat_data',
                    'button_click',
                    'button english translated',
                    '',
                    '',
                    '',
                  ],
                });
              }}
            >
              English Translated
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>{tabContent}</TabContent>
      </div>
    </div>
  );
};
const linkGen = (data, type) => {
  let link = '';
  if (data.title == null) {
    data.title = 'untitled';
  }
  switch (type) {
    case 'events':
      link = `/talks/baca/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/`;
      break;
    case 'berita':
      link = `/berita/a/${slugify(data.title.toLowerCase(), '-')}-${data.guid
        }/`;
      break;
    case 'stories':
      link = `/stories/article/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/`;
      break;
    case 'foto':
      link = `/berita/foto/f/${slugify(data.title.toLowerCase(), '-')}-${data.guid
        }/`;
      break;
    case 'putusan':
      break;
    case 'peraturan':
      link = `/pusatdata/detail/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/`;
      break;
    case 'peraturanTranslated':
      link = `/pusatdata/detail/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/translations`;
      break;
    case 'pro':
      link = `https://pro.hukumonline.com/a/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/`;
      break;
    case 'stories':
      link = `/stories/article/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}`;
      break;
    case 'klinik':
      link = `/klinik/a/${slugify(data.title.toLowerCase(), '-')}-${data.guid
        }/`;
      break;
    default:
    // code block
  }
  return link;
};
const newLinkGen = (data, type) => {
  let link = '';
  if (data.title == null) {
    data.title = 'untitled';
  }
  switch (type) {
    case 'events':
      link = `/talks/baca/${data.guid}/${data.slug}/`;
      break;
    case 'berita':
      link = `/berita/a/${data.slug}-${data.guid}/`;
      break;
    case 'stories':
      link = `/stories/article/${data.guid}/${data.slug}/`;
      break;

    case 'foto':
      link = `/berita/foto/f/${data.slug}-${data.guid}/`;
      break;
    case 'putusan':
      break;
    case 'peraturan':
      link = `/pusatdata/detail/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/`;
      break;
    case 'peraturanTranslated':
      link = `/pusatdata/detail/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/translations`;
      break;
    case 'pro':
      link = `https://pro.hukumonline.com/a/${data.guid}/${slugify(
        data.title.toLowerCase(),
        '-',
      )}/`;
      break;
    case 'klinik':
      link = `/klinik/a/${data.slug}-${data.guid}`;
      break;
    default:
    // code block
  }
  return link;
};
export const SectionTerbaru = (props) => {
  const { location } = props;
  const List = ({ item, index }) => {
    const isStories = item.type == 'Stories';
    const link = newLinkGen(item, isStories ? 'stories' : 'berita');
    return (
      <div className="d-inline-flex flex-column content">
        {item.type == 'Stories' && (
          <div>
            <LabelPro data="Premium Stories" />
          </div>
        )}

        <a
          href={link}
          onClick={() => {
            trackerMain({
              snowplow: [
                location,
                'click article link - blok terbaru',
                `${item.guid}`,
                '',
                `${index}`,
              ],
              ga4: [
                'eventCoreV1Article',
                location,
                'section_terbaru',
                'link_click',
                'index article terbaru',
                index,
                `${item.title}`,
                `${item.guid}`,
              ],
            });
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: item.title }} />
        </a>
      </div>
    );
  };
  const itemList = props.data.slice(0, 2).map((item, index) => (
    <List key={`${item.guid}-${item.type}-terbaru`} item={item} index={index} />
  ));
  return (
    <div id="section-latest" className="section mb-4">
      <div className="section-head d-flex justify-content-between align-items-center border-bottom">
        <h3>Terbaru</h3>
        <span>{moment().format('DD MMM YYYY')}</span>
      </div>
      <div className="section-content">{itemList}</div>
      <div className="read-more p-0 py-md-2">
        <a
          href="/berita/terbaru"
          className="btn btn-outline-primary btn-block btn-more d-md-none py-2"
          onClick={() =>
            trackerMain({
              snowplow: [
                location,
                'click link - blok terbaru - lihat semua',
                '',
                '',
                '',
              ],
              ga4: [
                'eventCoreV1BasicData',
                location,
                'section_terbaru',
                'button_click',
                'button lihat semua',
                '',
                '',
                '',
              ],
            })
          }
        >
          <span>Lihat Semua </span>
        </a>
        <a
          href="/berita/terbaru"
          className="d-md-block d-none"
          onClick={() => {
            trackerMain({
              snowplow: [
                location,
                'click link - blok terbaru - lihat semua',
                '',
                '',
                '',
              ],
              ga4: [
                'eventCoreV1BasicData',
                location,
                'section_terbaru',
                'button_click',
                'button lihat semua',
                '',
                '',
                '',
              ],
            });
          }}
        >
          <span>
            Lihat Semua <i className="fa fa-arrow-right ml-2" />
          </span>
        </a>
      </div>
    </div>
  );
};
export const MediaHighlight = (props) => (
  <Media className="d-flex flex-md-row flex-column align-items-start">
    <Media
      left
      href={linkGen(props.data, 'pro')}
      onClick={() =>
        trackerMain({
          snowplow: [
            props.location,
            'click image artikel - blok legal highlighted',
            `${props.data.guid}`,
            '',
            '',
          ],
          ga4: [
            'eventCoreV1Analysis',
            props.location,
            'section_legal_analysis',
            'image_click',
            'image legal analysis',
            0,
            `${props.data.title}`,
            `${props.data.guid}`,
          ],
        })
      }
    >
      <LazyLoadImage
        title={props.data.content_preference}
        alt={props.data.content_preference}
        effect="opacity"
        width="300px"
        src={props.data.featured_image}
        className="img-fluid my-md-2 my-1 d-md-block d-none"
      />

      {/* <Media
        object
        src={props.data.featured_image}
        className="img-fluid my-md-2 my-1 d-md-block d-none"
        alt="props.data.content_preference"
        style={{ maxWidth: '300px' }}
      />
      */}
      <Media
        object
        src={props.data.featured_image}
        className="img-fluid my-md-2 my-1 d-md-none "
        alt="props.data.content_preference"
        style={{ maxWidth: '500px' }}
      />
    </Media>
    <Media body className="ml-md-3 mb-4">
      <LabelPro data={props.data.category} color="muted" />
      <a
        href={linkGen(props.data, 'pro')}
        onClick={() =>
          trackerMain({
            snowplow: [
              props.location,
              'click link artikel - blok legal highlighted',
              `${props.data.guid}`,
              '',
              '',
            ],
            ga4: [
              'eventCoreV1Analysis',
              props.location,
              'section_legal_analysis',
              'link_click',
              'index legal analysis',
              0,
              `${props.data.title}`,
              `${props.data.guid}`,
            ],
          })
        }
      >
        <h4
          className="my-md-2 media-heading highlight my-1"
          dangerouslySetInnerHTML={{ __html: props.data.title }}
        />
      </a>
      <span className="category my-md-2 my-1">
        {props.data.content_preference}
      </span>
      {props.data.subtitle.length > 0 ? (
        <p
          className="my-md-2 my-1 d-md-block subtitle-analysis d-none"
          dangerouslySetInnerHTML={{ __html: props.data.subtitle }}
        />
      ) : null}

      {/* <p className="my-md-2 my-1 d-md-block d-none limit">{props.data.subtitle}</p> */}
      <div className="read-more">
        <a
          href="https://pro.hukumonline.com/home?utm_source=website&utm_medium=home&utm_campaign=home_legal_analysis"
          className="btn btn-outline-primary btn-block btn-more d-md-none my-md-2 my-1"
          onClick={() =>
            trackerMain({
              snowplow: [
                props.location,
                'click link - blok legal - selengkapnya',
                '',
                '',
                '',
              ],
              ga4: [
                'eventCoreV1BasicData',
                props.location,
                'section_legal_analysis',
                'link_click',
                `button lihat selengkapnya legal analysis`,
                '',
                '',
                '',
              ],
            })
          }
        >
          <span>Lihat Selengkapnya </span>
        </a>
      </div>
    </Media>
  </Media>
);
export const PremiumStories = (props) => {
  const ItemPremiumStories = ({ data, index = 0 }) => {
    const published_at = (
      <Moment locale="id" format="DD MMM YYYY" className="__time">
        {data.published_at}
      </Moment>
    );
    const list = (
      <Media className="d-flex flex-row premium-news-list">
        <Media
          left
          href={linkGen(data, 'stories')}
          onClick={() =>
            trackerMain({
              snowplow: [
                props.location,
                'click image artikel - blok premium stories',
                `${data.guid}`,
                '',
                index,
              ],
              ga4: [
                'eventCoreV1Stories',
                props.location,
                'section_premium_stories',
                'image_click',
                `index premium stories`,
                index + 1,
                `${data.title}`,
                `${data.guid}`,
              ],
            })
          }
          className="mr-2 d-md-block d-none"
        >
          <LazyLoadImage
            title={props.data.title}
            alt={props.data.title}
            effect="opacity"
            src={imageSelector(data.image, 'small')}
            className="img-fluid thumbnail rounded media-object"
          />
        </Media>
        <Media body className="ml-md-3">
          <span className="info-news">
            {published_at} • Bacaan {data.reading_time} menit
          </span>
          <a
            href={linkGen(data, 'stories')}
            onClick={() =>
              trackerMain({
                snowplow: [
                  props.location,
                  'click link artikel - blok premium stories',
                  `${data.guid}`,
                  '',
                  index,
                ],
                ga4: [
                  'eventCoreV1Stories',
                  props.location,
                  'section_premium_stories',
                  'link_click',
                  'index premium stories',
                  index + 1,
                  `${data.title}`,
                  `${data.guid}`,
                ],
              })
            }
          >
            <h4
              className="my-md-2 my-1 premium-news media-heading limit-2"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
          </a>
          <div className="media-author">
            <LazyLoadImage
              title={data.author}
              alt={data.author}
              effect="opacity"
              src={imageSelector(data.author_image, 'thumbnail')}
              className="author-picture"
            />
            <Link
              href={`/authors/${data.author_guid}/${data.author}?type=stories`}
            >
              <a
                className="ml-2"
                onClick={(e) =>
                  trackerMain({
                    snowplow: [
                      'PS-Home',
                      'klik author - artikel highlight',
                      `${data.author_guid}`,
                      '',
                      '',
                      e,
                    ],
                    ga4: [
                      'eventCoreV1StoriesAuthor',
                      'PS-Home',
                      'section_premium_stories',
                      'button_click',
                      'button author premium stories',
                      index + 1,
                      `${data.author}`,
                      `${data.author_guid}`,
                    ],
                  })
                }
              >
                <span className="ml-2">{data.author}</span>
              </a>
            </Link>
          </div>
        </Media>
      </Media>
    );
    return list;
  };

  const itemList = [];
  for (let i = 0; i < props.data.length; i++) {
    itemList.push(
      <ItemPremiumStories
        key={`${props.data[i].guid}-premium-list`}
        data={props.data[i]}
        index={i}
      />,
    );
    if (i != props.data.length - 1) {
      itemList.push(
        <div className="hr-line my-4" key={`${i}-divider-premium-list`} />,
      );
    }
  }
  return itemList;
};
export const MediaList = (props) => {
  const item = (data, index) => (
    <Col md={4} className="" key={`${data.guid}-medialist`}>
      <Media className="pb-2 position-absolute">
        <Media body>
          <LabelPro data={data.category} color="muted" />
          <a
            href={linkGen(data, 'pro')}
            onClick={() =>
              trackerMain({
                snowplow: [
                  props.location,
                  'click link artikel - blok legal',
                  `${data.guid}`,
                  '',
                  `${index}`,
                ],
                ga4: [
                  'eventCoreV1Analysis',
                  props.location,
                  'section_legal_analysis',
                  'link_click',
                  'index legal analysis',
                  index + 1,
                  `${data.title}`,
                  `${data.guid}`,
                ],
              })
            }
          >
            <h4
              heading="true"
              className="m-md-0 my-2 list media-heading"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
          </a>
          <span className="category">{data.content_preference}</span>
          <p
            className="m-0"
            dangerouslySetInnerHTML={{ __html: data.subtitle }}
          />
        </Media>
      </Media>
    </Col>
  );
  const listItem = props.data
    .slice(0, 3)
    .map((dataItem, index) => item(dataItem, index));
  return listItem;
};

export const PremiumNewsList = (props) => {
  const published_at = (
    <Moment locale="id" format="dddd, D MMMM YYYY" className="__time">
      {props.data.published_at}
    </Moment>
  );
  return (
    <>
      <Media className="py-2 d-md-none ">
        <Media body>
          <LazyLoadImage
            title={props.data.title}
            alt={props.data.title}
            effect="opacity"
            src={imageSelector(props.data.image, 'medium')}
            className="img-fluid media-object"
          />
          <div className="my-md-2 my-1">
            <span className="info-news">
              {published_at} • Bacaan {props.data.reading_time} menit
            </span>
          </div>
          <a
            href={linkGen(props.data, 'stories')}
            onClick={() =>
              trackerMain({
                snowplow: [
                  props.location,
                  'click link artikel - blok premium stories highlighted',
                  `${props.data.guid}`,
                  '',
                  '',
                ],
                ga4: [
                  'eventCoreV1Stories',
                  props.location,
                  'section_premium_stories',
                  'link_click',
                  'index premium stories',
                  0,
                  `${props.data.title}`,
                  `${props.data.guid}`,
                ],
              })
            }
          >
            <Media heading className="m-0 list">
              {props.data.title}
            </Media>
          </a>
          <div className="media-author my-md-2 my-1">
            <LazyLoadImage
              title={props.data.author}
              alt={props.data.author}
              effect="opacity"
              src={imageSelector(props.data.author_image, 'thumbnail')}
              className="author-picture"
            />
            <Link
              href={`/authors/${props.data.author_guid}/${props.data.author}?type=stories`}
            >
              <a
                className="ml-2"
                onClick={(e) =>
                  trackerMain({
                    snowplow: [
                      'PS-Home',
                      'klik author - artikel highlight',
                      '${props.data.author_guid}',
                      '',
                      '',
                      e,
                    ],
                    ga4: [
                      'eventCoreV1StoriesAuthor',
                      'PS-Home',
                      'section_premium_stories',
                      'button_click',
                      'button author premium stories',
                      0,
                      `${props.data.author}`,
                      `${props.data.author_guid}`,
                    ],
                  })
                }
              >
                <span className="ml-2">{props.data.author}</span>
              </a>
            </Link>
          </div>
        </Media>
      </Media>
      <div className="d-md-block d-none">
        <div className="position-relative media-with-caption">
          <LazyLoadImage
            title={props.data.title}
            alt={props.data.title}
            effect="opacity"
            src={imageSelector(props.data.image, 'large')}
            className="img-fluid media-object"
          />
          <div className="img-caption">
            <span className="info-news">
              {published_at} • Bacaan {props.data.reading_time} menit
            </span>
            <a
              href={linkGen(props.data, 'stories')}
              onClick={() =>
                trackerMain({
                  snowplow: [
                    props.location,
                    'click link artikel - blok premium stories highlighted',
                    `${props.data.guid}`,
                    '',
                    '',
                  ],
                  ga4: [
                    'eventCoreV1Stories',
                    props.location,
                    'section_premium_stories',
                    'link_click',
                    'index premium stories',
                    0,
                    `${props.data.title}`,
                    `${props.data.guid}`,
                  ],
                })
              }
            >
              <h3> {props.data.title}</h3>
            </a>
            <div className="media-author_ my-2">
              <LazyLoadImage
                title={props.data.author}
                alt={props.data.author}
                effect="opacity"
                src={imageSelector(props.data.author_image, 'thumbnail')}
                className="author-picture"
              />
              <Link
                href={`/authors/${props.data.author_guid}/${props.data.author}?type=stories`}
              >
                <a
                  className="ml-2"
                  onClick={(e) =>
                    trackerMain({
                      snowplow: [
                        'PS-Home',
                        'klik author - artikel highlight',
                        '${props.data.author_guid}',
                        '',
                        '',
                        e,
                      ],
                      ga4: [
                        'eventCoreV1StoriesAuthor',
                        'PS-Home',
                        'section_premium_stories',
                        'button_click',
                        'button author premium stories',
                        0,
                        `${props.data.author}`,
                        `${props.data.author_guid}`,
                      ],
                    })
                  }
                >
                  <span className="ml-2 media-author">{props.data.author}</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const KlinikHighlight = (props) => {
  const data = props.data.highlight;
  const published_at = (
    <Moment locale="id" format="dddd, D MMMM YYYY" className="__time">
      {props.published_at}
    </Moment>
  );
  return (
    <>
      <div className="pr-md-2 pt-md-2">
        <div className="position-relative media-with-caption">
          <LazyLoadImage
            alt={data.category.title}
            effect="blur"
            src={data.image} // use normal <img> attributes as props
            className="img-fluid media-object"
          />
          {/* <Media object src={data.image} className="img-fluid" alt="Generic" /> */}
          <div className="img-caption">
            <span className="info-news">{data.category.title}</span>
            <a
              href={linkGen(data, 'klinik')}
              onClick={() =>
                trackerMain({
                  snowplow: [
                    props.location,
                    'click link artikel - blok klinik hukum highlighted',
                    `${data.guid}`,
                    '',
                    '',
                  ],
                  ga4: [
                    'eventCoreV1Clinic',
                    props.location,
                    'section_klinik_hukum',
                    'link_click',
                    'index klinik hukum',
                    0,
                    `${data.title}`,
                    `${data.guid}`,
                  ],
                })
              }
            >
              <h3 dangerouslySetInnerHTML={{ __html: data.title }} />
            </a>
            <div className="media-author my-2 d-md-block d-none">
              <LazyLoadImage
                title={data.author.name}
                alt={data.author.name}
                effect="opacity"
                src={imageSelector(data.author.avatar.thumbnail)}
                className="author-picture"
              />
              <span className="ml-2">{data.author.name}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const KlinikList = (props) => {
  const numOfCols = 3;
  let rowCounts = 0;
  let itemList = [];
  const itemsCol = [];
  let colCount = 1;
  const ItemKlinik = ({ data, index = 0 }) => (
    <div className="content d-flex flex-column border-bottom py-3">
      <span className="cat-reg">{data.category && data.category.title}</span>
      <a
        href={linkGen(data, 'klinik')}
        onClick={() =>
          trackerMain({
            snowplow: [
              props.location,
              'click link artikel - blok klinik hukum',
              `${data.guid}`,
              '',
              index,
            ],
            ga4: [
              'eventCoreV1Clinic',
              props.location,
              'section_klinik_hukum',
              'link_click',
              'index klinik hukum',
              index + 1,
              `${data.title}`,
              `${data.guid}`,
            ],
          })
        }
      >
        <span
          className="limit-3-clinic paragraph"
          dangerouslySetInnerHTML={{ __html: data.title }}
        />
      </a>
    </div>
  );
  for (let i = 0; i < props.data.length; i++) {
    itemList.push(
      <ItemKlinik
        key={`${props.data[i].guid}-KlinikItem`}
        data={props.data[i]}
        index={i}
      />,
    );
    rowCounts++;
    if (rowCounts % numOfCols == 0) {
      const itemCol = (
        <Col
          md={6}
          key={`wrapperKlinik-${i}`}
          className={`${colCount > 1 && 'd-md-block d-none'}`}
        >
          <div className="section-content">{itemList}</div>
        </Col>
      );
      itemsCol.push(itemCol);
      itemList = [];
      rowCounts = 0;
      colCount++;
    }
  }
  return itemsCol;
};

export const EventList = (data) => (
  <Col md={3}>
    <Media className="py-2">
      <Media body>
        <Media
          object
          src="https://imgholder.ru/271x180/3d4d65/eceff4"
          alt="Generic placeholder image"
        />
        <div className="d-flex flex-column my-1">
          <span className="category">Manufacturing & Industry</span>
          <span>Tim In-house Training Hukumonline Siap Hadirkan Nara...</span>
        </div>

        <div className="my-1">
          <span className="info-news">14 Jun 2020</span>
        </div>
      </Media>
    </Media>
  </Col>
);

export const NewsList = (data) => (
  <Col md={4}>
    <Media className="py-2">
      <Media body>
        <Media
          object
          src="https://imgholder.ru/271x180/3d4d65/eceff4"
          alt="Generic placeholder image"
          className="img-fluid"
        />
        <div className="d-flex flex-column my-2">
          <div className="my-2">
            <span className="info-news">14 Jun 2020</span>
          </div>
          <span>
            STH Indonesia Jentera Selenggarakan Perkuliahan Blended Learning
          </span>
        </div>
      </Media>
    </Media>
  </Col>
);

export const PojokHighlight = (props) => (
  <div className="pr-2 pt-2 d-md-block d-none">
    <div className="position-relative media-with-caption">
      <LazyLoadImage
        alt={props.data.title}
        effect="blur"
        src={props.data.image}
        className="img-fluid media-object"
      />

      <div className="img-caption">
        <a
          href={linkGen(props.data, 'berita')}
          onClick={() =>
            trackerMain({
              snowplow: [
                props.location,
                `click link artikel - blok ${props.title.toLowerCase()} - highlighted`,
                `${props.data.guid}`,
                '',
                '',
              ],
              ga4: [
                'eventCoreV1Article',
                props.location,
                'section_pojok',
                'link_click',
                `index ${props.title.toLowerCase()}`,
                0,
                `${props.data.title}`,
                `${props.data.guid}`,
              ],
            })
          }
        >
          <h3>{props.data.title}</h3>
        </a>
      </div>
    </div>
  </div>
);
export const PojokList = (props) => {
  const ItemHighlight = ({ data, index = 0 }) => (
    <div className="content border-bottom d-md-none">
      <a
        href={linkGen(data, 'berita')}
        onClick={() =>
          trackerMain({
            snowplow: [
              props.location,
              `click link artikel - blok ${props.title.toLowerCase()}`,
              `${data.guid}`,
              '',
              index,
            ],
            ga4: [
              'eventCoreV1Article',
              props.location,
              'section_pojok',
              'link_click',
              `index ${props.title.toLowerCase()}`,
              index,
              `${data.title}`,
              `${data.guid}`,
            ],
          })
        }
      >
        <span className="limit-2">{data.title}</span>
      </a>
    </div>
  );

  const listHighlight = (
    <ItemHighlight
      key={`${props.dataHighlight.guid}-pojok`}
      data={props.dataHighlight}
    />
  );

  const ItemPojok = ({ data, index = 0 }) => (
    <div className="content border-bottom">
      <a
        href={linkGen(data, 'berita')}
        onClick={() =>
          trackerMain({
            snowplow: [
              props.location,
              `click link artikel - blok pojok ${props.title.toLowerCase()}`,
              `${data.guid}`,
              '',
              index + 1,
            ],
            ga4: [
              'eventCoreV1Article',
              props.location,
              'section_pojok',
              'link_click',
              `index pojok ${props.title.toLowerCase()}`,
              index + 1,
              `${data.title}`,
              `${data.guid}`,
            ],
          })
        }
      >
        <span className="limit-2">{data.title}</span>
      </a>
    </div>
  );
  const listItem = props.data.map((dataItem, index) => (
    <ItemPojok key={`${dataItem.guid}-pojok`} data={dataItem} index={index} />
  ));

  const listItem1 = [];
  listItem1.push(listHighlight);
  listItem.forEach((el) => {
    listItem1.push(el);
  });
  return listItem1;
};
export const HolProduct = (props) => {
  const [isOpen, setIsOpen] = useState([true, false, false]);
  const [imageFocus, setImage] = useState(props.data[0].image);
  const toggle = (index) => {
    setImage(props.data[index].image);
    let newArr = [...isOpen];
    newArr = newArr.map((x) => false);
    newArr[index] = !isOpen[index];
    setIsOpen(newArr);
  };

  const productItem = (itemData) => (
    <div className="accordion-body border-bottom py-3">
      <div
        className="d-flex justify-content-between"
        onClick={() => {
          toggle(itemData.id - 1);
        }}
      >
        <span>{itemData.title}</span>
        <i
          className={`fa ${isOpen[itemData.id - 1] ? 'fa-caret-up' : 'fa-caret-down'
            } mr-2`}
        />
      </div>
      <Collapse isOpen={isOpen[itemData.id - 1]}>
        <div className="accordion-content section-content">
          <p className="mb-2">{itemData.description}</p>
          <div className="read-more">
            <a
              href={itemData.link}
              target="_blank"
              rel="noreferrer"
              className="d-md-block d-none text-uppercase"
            >
              Selengkapnya <i className="fa fa-arrow-right ml-2" />
            </a>
          </div>
        </div>
      </Collapse>
    </div>
  );
  const listItem = props.data.map((dataItem) => {
    if (isOpen[dataItem.id - 1]) {
      // setImage(dataItem.image);
    }

    return productItem(dataItem);
  });
  return (
    <div className="section-main-other-product my-4">
      <Row>
        <Col md="6" className="d-flex align-items-center">
          <img src={imageFocus} className="img-fluid" />
        </Col>
        <Col md="6">
          <div className="accordion">{listItem}</div>
        </Col>
      </Row>
    </div>
  );
};
export const KonsolidasiInfo = (props) => (
  <div
    className={`section-konsolidasi position-relative align-items-center ${props.className}`}
  >
    <div className="p-4 ">
      <div>
        <div className="d-flex align-items-center mb-3">
          <FontAwesomeIcon
            width="16"
            className="mr-2 title-with-icon"
            icon={faLayerGroup}
          />
          <h3 className="title-with-icon m-0">Peraturan Konsolidasi</h3>
        </div>
        <div className="body-section text-white">
          <p className="text-white">
            Peraturan Konsolidasi adalah naskah penggabungan dari sebuah
            peraturan perundang-undangan beserta setiap perubahannya, dan
            peraturan lain yang berdampak pada peraturan tersebut.
          </p>
          <a
            onClick={() => {
              trackerMain({
                snowplow: [
                  props.location,
                  'click button - peraturan konsolidasi - cta',
                  '',
                  '',
                  '',
                ],
                ga4: [
                  'eventCoreV1BasicData',
                  props.location,
                  'section_pusat_data',
                  'button_click',
                  'button contoh peraturan konsolidasi',
                  '',
                  '',
                  '',
                ],
              });
              props.downloadSample();
            }}
            className="btn  btn-secondary text-primary text-capitalize"
          >
            <i className="fa fa-file mr-2" /> Contoh Peraturan Konsolidasi
          </a>
        </div>
      </div>
    </div>
  </div>
);
export const OnlineCourses = (props) => {
  const { data } = props;
  const CardItemOnlineCourse = ({ data, index }) => {
    const { title, link, image, speakers, regularPrice, salePrice, id } = data;
    const price = numeral(salePrice).format('0,0');
    const regPrice = numeral(regularPrice).format('0,0');
    const mainPrice = price !== '0' ? price : regPrice;

    const priceSection = (
      <>
        <span className="paragraph font-weight-bold  mr-2 text-main">
          Rp {mainPrice}
        </span>
        {price !== '0' && (
          <div className="paragraph text-disabled font-weight-bold">
            <del>Rp {regPrice}</del>
          </div>
        )}
      </>
    );
    const priceFree = (
      <>
        <span className="paragraph font-weight-bold  mr-2 text-main">
          Gratis
        </span>
      </>
    );
    return (
      <Col md="4" className="card-container" key={index}>
        <div className="hero-section my-1">
          <Link
            href={`${link}?utm_source=website&utm_medium=homepage&utm_campaign=OC_section_kelas`}
          >
            <a
              className="text-main"
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                trackerMain({
                  snowplow: [
                    'hol - new homepage',
                    'click link kelas  - online course section ',
                    '',
                    '',
                    '',
                  ],
                  ga4: [
                    'eventCoreV1Course',
                    'hol - new homepage',
                    'section_online_course',
                    'image_click',
                    'image online course',
                    index,
                    `${title}`,
                    `${id}`,
                  ],
                })
              }
            >
              <img src={image} alt={title} className="img-fluid rounded" />
            </a>
          </Link>
        </div>
        <div className="d-flex flex-column card-detail my-1">
          <Link
            href={`${link}?utm_source=website&utm_medium=homepage&utm_campaign=OC_section_kelas`}
          >
            <a
              className="text-main"
              target="_blank"
              rel="noreferrer"
              onClick={() =>
                trackerMain({
                  snowplow: [
                    'hol - new homepage',
                    'click link kelas  - online course section ',
                    '',
                    '',
                    '',
                  ],
                  ga4: [
                    'eventCoreV1Course',
                    'hol - new homepage',
                    'section_online_course',
                    'link_click',
                    'index online course',
                    index,
                    `${title}`,
                    `${id}`,
                  ],
                })
              }
            >
              <span className="body font-weight-bold text-black">{title}</span>
            </a>
          </Link>
          <div className="d-flex flex-row ">
            {mainPrice !== '0' ? priceSection : priceFree}
          </div>
        </div>
        {index !== 2 && <hr className="d-md-none" />}
      </Col>
    );
  };

  const cardList =
    Array.isArray(data) &&
    data.length > 0 &&
    data?.map((item, index) => (
      <CardItemOnlineCourse key={`oc-${index}`} data={item} index={index} />
    ));
  return isEmpty(data) ? null : (
    <div className="online-course-section py-md-2 mb-md-5 mb-4">
      <div className="section-head d-md-flex d-none flex-column justify-content-center align-items-center">
        <h3>Online Course</h3>
        <span>
          Tingkatkan kecakapan atau pengetahuan Anda di bidang hukum dan
          advokasi!
        </span>
      </div>
      <div className="section-head d-flex d-md-none justify-content-between align-items-center border-bottom">
        <h3>Online Course</h3>
      </div>
      <div className="section-main-online-course my-md-4 my-2">
        <Row>{cardList}</Row>
      </div>
      <div className="read-more d-flex justify-content-center">
        <Button
          color="primary"
          href="https://learning.hukumonline.com?utm_source=website&utm_medium=homepage&utm_campaign=OC_section_button_lihatsemua"
          target="_blank"
          rel="noreferrer"
          className="btn btn-primary text-capitalize d-md-block d-none mt-3"
          onClick={() =>
            trackerMain({
              snowplow: [
                'HOL - New Homepage',
                'click button lihat semua - online course section',
                '',
                '',
                '',
              ],
              ga4: [
                'eventCoreV1BasicData',
                'New - New Homepage',
                'section_online_course',
                'button_click',
                'button lihat semua online course',
                '',
                '',
                '',
              ],
            })
          }
        >
          Lihat Semua
        </Button>
        <a
          href="https://learning.hukumonline.com?utm_source=website&utm_medium=homepage&utm_campaign=OC_section_button_lihatsemua"
          target="_blank"
          rel="noreferrer"
          className="btn btn-outline-primary btn-block btn-more d-md-none py-2 my-md-2 my-1"
          onClick={() =>
            trackerMain({
              snowplow: [
                'HOL - New Homepage',
                'click button lihat semua - online course section',
                '',
                '',
                '',
              ],
              ga4: [
                'eventCoreV1BasicData',
                'New - New Homepage',
                'section_online_course',
                'button_click',
                'button lihat semua online course',
                '',
                '',
                '',
              ],
            })
          }
        >
          <span>Lihat Semua Online Course </span>
        </a>
      </div>
    </div>
  );
};
export const Pojok = ({
  title = '',
  location = '',
  items = [],
  highlight = {},
  slug = '',
}) => (
  <div id="section-ihkapi" className="section mb-3">
    <div className="section-head d-flex justify-content-between align-items-center border-bottom">
      <h3>{title}</h3>

      <div className="read-more">
        <a
          href={`/berita/${slug == 'pojokmprri' ? 'pojok-mprri' : slug}`}
          className="d-md-block d-none text-uppercase"
          onClick={() =>
            trackerMain({
              snowplow: [
                location,
                `click link - blok ${title.toLowerCase()} - lihat semua`,
                '',
                '',
                '',
              ],
              ga4: [
                'eventCoreV1BasicData',
                location,
                'section_pojok',
                'button_click',
                `button lihat semua ${title.toLowerCase()}`,
                '',
                '',
                '',
              ],
            })
          }
        >
          <span>
            Lihat Semua <i className="fa fa-arrow-right ml-2" />
          </span>
        </a>
      </div>
    </div>

    <div className="section-content my-2 d-flex flex-row">
      <Row>
        <Col md={6}>
          <PojokHighlight data={highlight} title={title} location={location} />
        </Col>
        <Col md={6}>
          <div className="">
            <PojokList
              data={items}
              title={title}
              location={location}
              dataHighlight={highlight}
            />
          </div>
        </Col>
      </Row>
    </div>

    <div className="read-more d-flex justify-content-center">
      <a
        href={`/berita/${slug}`}
        className="btn btn-outline-primary btn-block btn-more d-md-none my-md-2 my-1"
        onClick={() =>
          trackerMain({
            snowplow: [
              location,
              `click link - blok pojok ${title.toLowerCase()} - lihat semua`,
              '',
              '',
              '',
            ],
            ga4: [
              'eventCoreV1BasicData',
              location,
              'section_pojok',
              'button_click',
              `button lihat semua pojok ${title.toLowerCase()}`,
              '',
              '',
              '',
            ],
          })
        }
      >
        <span>Lihat Semua</span>
      </a>
    </div>
  </div>
);

export const LegalIntelligence = ({ data }) => {
  return (
    <div id="" className="section-legal-intelligence pt-md-3 mb-2">
      <img src={data[0].image} className='img-fluid legal-intelligence-image' alt={data[0].title} />
      <Badge
        pill
        className={`d-inline-flex flex-row justify-content-center align-items-center badge-legal-intelligence`}
      >
        Legal Intelligence Updates
      </Badge>
      <Badge
        className={`d-inline-flex flex-row justify-content-center align-items-center badge-new-product`}
      >
        New
      </Badge>
      <a
        href={`${PRO_URL}/legal-intelligence/a/${data[0].slug}-${data[0].guid}/`}
        className="hol-homepage liu"
        onClick={() => trackerMain({
          snowplow: [
            'section_LIU',
            `link_click - ${data[0].title}`,
            '',
            '',
            '',
          ],
        })}
      >
        <p className='title'>{data[0].title}</p>
      </a>
    </div>
  )
}
